import '../../styles/common.css';
import React from "react";
import ReactApexChart from 'react-apexcharts';
import { HepadomEntity } from "utils/services/hepadom_entity";
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const graphKeys = ["Spirit","Walking","Relationships","Work","Mood","Activity", "Numbness", "Vomiting", "Sadness", "Dry Mouth", "Drowsiness", "Appetite",
    "Memory", "Breath", "Worry", "Sleep", "Nausea", "Fatigue", "Pain" ];

const getYaxisColorArray = () => {
    const colorArr = [];
    for (let i=0; i < graphKeys.length; i++) {
        // add the color only to main questionarie , for computed questionaire leave it to black
        if(i > 5){colorArr.push('#0066cc'); }
        else{
            colorArr.push('#000000') ;
        }
    }
    return colorArr;
};

const localData = {
    seriesDates: [],
    reg_date: null,
    hepatectomy_date:null,
    chartSeries: [],
    defaultColorArray: [],
    colorArray: [],
    yaxisColorArray: getYaxisColorArray()
};

function generateData(records, name) {
    const series = localData.seriesDates.map(item => ({ x: item }));
    for (let j=0; j<records.length; j++) {
        const createdDate = moment(records[j].created_at).format("DD-MM-YYYY");
        const seriesItem = series.find(item => item.x === createdDate);
        if (seriesItem)
        {
        for (let k = 0; k < records[j].answers.length; k++) {
            const answer = records[j].answers[k];
            if (answer.name === name) {
                const createdDate = moment(records[j].created_at).format("DD-MM-YYYY");
                // find matching date in series and assiciate data
                const seriesItem = series.find(item => item.x === createdDate);
                if (seriesItem) {seriesItem.y = Number(answer.value);}
                break;
            }
        }
        }
    }
    const formattedSeries = getFormattedSeries(series);
    return formattedSeries;
}


function getFormattedSeries(series) {
    let formattedSeries = series.map(item => {
        item.y = item.y !== undefined ? item.y : -1;
        return item;
    });
    return formattedSeries;
}

function setSeriesDates() {
    localData.seriesDates = [];
    localData.defaultColorArray.splice(0, localData.defaultColorArray.length);
    if (!localData.reg_date||!localData.hepatectomy_date) {
        return;
    }
    const reg_date = new Date(localData.reg_date);
    const formattedDate = moment(reg_date).format("DD-MM-YYYY");
    localData.seriesDates.push(formattedDate);
    const date = new Date(localData.hepatectomy_date);
    date.setDate(date.getDate() - 1);
    for (let i=0; i<8; i++) {
        date.setDate(date.getDate() + 1);
        const formattedDate = moment(date).format("DD-MM-YYYY");
        const formattedhepatectomy_date=moment(new Date(localData.hepatectomy_date)).format("DD-MM-YYYY");
        if ( formattedhepatectomy_date === formattedDate) {
            localData.defaultColorArray[i+1] = 'red';
        }
        localData.seriesDates.push(formattedDate);
    }
    
}

function setHepatectomyDate(patient_details) {
    if (!patient_details) {
        return;
    }
    localData.reg_date = patient_details.Patient_Registration_Date;
    if (patient_details.Hepactectomy_Date) {
        localData.hepatectomy_date = patient_details.Hepactectomy_Date;
    }
    setSeriesDates();
}

class SurveyMDASIHEP extends React.Component {
    
    state = {
        noOfDays: 10,
        options: {},
        series: [],
        language:this.props.i18n.language
    };
    getQuestionnaires(patient_id) {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: "questionnaire",
                data: {
                    patient_id,
                    name: "MDASI"
                }
            };
            HepadomEntity.call("getQuestionnaire", options)
                .then(response=> {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch(err=> {
                    reject(err);
                });
        });
    }
    renderGraph(props){
        const {t}=props;
        const options= {
            chart: {
                toolbar: {
                  show: false
                }
            },
            legend:{
                show: false,
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale: {
                        ranges: [{
                            from: 0,
                            to: 1,
                            name: '0',
                            color: '#0066CC'
                        },
                            {
                                from: 1,
                                to: 2,
                                name: '1',
                                color: '#0080FF'
                            },
                            {
                                from: 2,
                                to: 3,
                                name: '2',
                                color: '#3399FF'
                            },
                            {
                                from: 3,
                                to: 4,
                                name: '3',
                                color: '#66B2FF'
                            },
                            {
                                from: 4,
                                to: 5,
                                name: '4',
                                color: '#99CCFF'
                            },
                            {
                                from: 5,
                                to: 6,
                                name: '5',
                                color: '#CCE5FF'
                            },
                            {
                                from: 6,
                                to: 7,
                                name: '6',
                                color: '#FFCCE5'
                            },
                            {
                                from: 7,
                                to: 8,
                                name: '7',
                                color: '#FF99CC'
                            },
                            {
                                from: 8,
                                to: 9,
                                name: '8',
                                color: '#FF66B2'
                            },
                            {
                                from: 9,
                                to: 10,
                                name: '9',
                                color: '#FF3399'
                            },
                            {
                                from: 10,
                                to: 11,
                                name: '10',
                                color: '#FF007F'
                            },
                            {
                                from: '-1',
                                to: '-1',
                                name: `<span><span class="graph-legend"></span>${t('Not specified -1')}</span>`,
                                color: '#FFFFFF'
                            },
                        ]
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                title:{
                    text: t("Questionnaire submission dates")
                },
                labels: {
                    style: {
                        colors: localData.defaultColorArray
                    }
                }
            },
            yaxis: {
                labels: {
                    maxWidth: 160,
                    style: {
                        colors: localData.yaxisColorArray
                    }
                }
            }
        };
        setHepatectomyDate(props.patient_details);
        this.getQuestionnaires(props.patient_details._id)
            .then((data)=> {
                const records = data.sort((a, b)=> new Date(a.created_at) - new Date(b.created_at));
                const series = graphKeys.map(key => {
                    return {
                        name: t(key),
                        data: generateData(records, key)
                    };
                });
                
                this.setState({ ...this.state, series, loading: false,options });
            })
            .catch(()=> {
                this.setState({ ...this.state, loading: false });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.i18n.language!==this.state.language)
        {
            this.setState({ ...this.state, language: prevProps.i18n.language });
            this.renderGraph(this.props);
        }
    }
    constructor(props) {
        super(props);
        this.renderGraph(this.props);
    }
    render() {
        const {t}=this.props;
        let items=['#0066CC','#0080FF','#3399FF','#66B2FF','#99CCFF','#CCE5FF','#FFCCE5','#FF99CC','#FF66B2','#FF3399','#FF007F','#FFFFFF'];
        if (!this.state.series.length && this.state.loading) {return <div className="loader"/>;}
        const chartSeries =this.state.series;
        if (!chartSeries.length) {
            return null;
        }
        return (<div>
            <div>
            <h2 style={{
                    align: 'center',
                    fontSize: '20px',
                    fontWeight: 900,
                    fontFamily: `"Helvetica Neue", "Arial", "sans-serif"`,
                }}>{t('MDASI questionnaire score')} </h2>
           {items.map((item,index)=>{
            if(index===11) {return (<span ><span className="legend" style={{backgroundColor: item,border: '1px solid #373d3f'}}></span ><span className="legend-text">{t('Not specified -1')}</span></span>);}
            else {return (<span><span className="legend" style={{backgroundColor: item}}></span><span className="legend-text">{index}</span></span>);}
            })}
            </div>
            <div id="chart"
            style={{ padding: '10px 0 20px' }}>
            <ReactApexChart options={this.state.options} series={chartSeries} type="heatmap" height="660"/>
             </div>
            </div>
        );
    }

}

export default (withTranslation('common')(SurveyMDASIHEP));
