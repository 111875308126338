import { Grid, withStyles, } from "@material-ui/core";
import Weight from "components/Weight";
import WeightVariationVisualization from "components/WeightVariationVisualization";
import React from "react";
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import {HepadomEntity} from "../../../utils/services/hepadom_entity"
import { withTranslation } from 'react-i18next';
import SystolicVisualization from "components/SystolicVisualization";
import DiastolicVisualization from "components/DiastolicVisualization";
import OximetryVisualization from "components/OximetryVisualization";
import EarTemperatureVisualization from "components/EarTemperatureVisualization";
import HeartRateVisualization from "components/HeartRateVisualization";


const useStyles = (theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#3f51b5',
            outline: '1px solid slategrey'
        }
    },
    gridcontainer : {
       height: 'calc(100% - 10%)',
      },
      
      gridcolumn: {
        height: '400px',
        overflow: 'scroll',
        width : '100%'
      }
});
class MedicalData extends React.Component {
    state = {  dates: "", buttonName: "",patient_details:{} }
    constructor(props) {
        super(props);
        
        const options = {
            entity: "patients",
            entity_id: this.props.patient_details._id
        };
        HepadomEntity.get(options)
            .then(response=> {
                if (response.status !== 200) {
                    return this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator"});
                }
                const data = response.data;
                this.setState({ ...this.state, patient_details: data });
            
            })
            .catch(err=> {
                this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator"})
            })
        }

    buttonNameLabel = (item) => {
        const {t}=this.props;
        if (item === 8) {
            const buttonName = t("Reference week");
            return buttonName;
        } else {
            const buttonName = t("Current week");
            return buttonName;
        }
    }

    hepatectomyDate = (props) => {
      const {t}=this.props;
        if (!this.state.patient_details) {
            return;
        }
        let hepatectomy_date, Date_of_hepatectomy;
        if (this.state.patient_details.Hepactectomy_Date) {
            hepatectomy_date=new Date(this.state.patient_details.Hepactectomy_Date).getTime();
        }

        //logic not to include the hepadectomy date if it is greater than current date 
        const currentDate = new Date().getTime();
        if(hepatectomy_date <= currentDate){
            Date_of_hepatectomy=moment(hepatectomy_date).format('DD-MM-YYYY');
        }  
        
        return (
            <div>
                <Typography variant="subtitle1" gutterBottom color ="error" fontWeight = 'bold'>
                {t("Hepatectomy Date")} : [ {Date_of_hepatectomy} ]
             </Typography>
                 
            </div>
        );

    }

    render() {
        const { patient_details,classes, } = this.props;
        const inclusion_id = patient_details.inclusion_id;
        const patient_id = patient_details._id;
        const todayDate = moment().format("YYYY-MM-DD");
        const graphDate = todayDate;
      


        return (
           <React.Fragment>
           <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
               {this.hepatectomyDate(this.props)}
               </AppBar>
               <Divider style={{"margin": "3%"}}/>
               <Grid container  className = {classes.gridcolumn}>
               <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Weight inclusion_id={inclusion_id} graphDate={(graphDate)} />
                        </Grid>
                </Grid>
                <Divider style={{"margin": "3%"}}/>
                <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <WeightVariationVisualization inclusion_id={inclusion_id} patient_id={patient_id} graphDate={graphDate} />
                        </Grid>
                </Grid>
                <Divider style={{"margin": "3%"}}/>
                <Grid container spacing={3} >
                </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                        <SystolicVisualization inclusion_id={inclusion_id} graphDate={(graphDate)} patient_id={patient_id}/>
                        </Grid>
                <Divider style={{"margin": "3%"}}/>
                <Grid container spacing={3} >        
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DiastolicVisualization inclusion_id={inclusion_id} graphDate={(graphDate)} patient_id={patient_id}/>
                        </Grid>
                </Grid>  
                <Divider style={{"margin": "3%"}}/>
                <Grid container spacing={3} >        
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <OximetryVisualization inclusion_id={inclusion_id} graphDate={(graphDate)} patient_id={patient_id}/>
                        </Grid>
                </Grid>   
                <Divider style={{"margin": "3%"}}/>
                <Grid container spacing={3} >        
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <EarTemperatureVisualization inclusion_id={inclusion_id} graphDate={(graphDate)} patient_id={patient_id}/>
                        </Grid>
                </Grid>        
                <Divider style={{"margin": "3%"}}/>
                <Grid container spacing={3} >        
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <HeartRateVisualization inclusion_id={inclusion_id} graphDate={(graphDate)} patient_id={patient_id}/>
                        </Grid>
                </Grid>              
                </Grid>
           </React.Fragment>
            
        )
    }

}

export default withStyles(useStyles)(withTranslation('common')(MedicalData));
