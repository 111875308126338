import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import { graphColor } from '../../utils/common'
import moment from 'moment';

function getData(props) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "sensors",
            query: {
                name: "temperature",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        Entity.call("graph", options)
            .then(res => {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err => {
                reject();
            })
    })
}

class Temperature extends React.Component {

    state = { data: [], options: {} };
    constructor(props) {
        super(props);

        getData(props)
            .then(records => {
                const options = {
                    title: 'Température cutanée agrégée sur 1 heure',
                    vAxis: { title: '°C' },
                    hAxis: {
                        type: 'category'
                    },
                    series: {
                        0: { color: graphColor.temperature }
                    },
                    legend: { position: 'top', alignment: 'end' },
                    interpolateNulls: false
                };

                records = records.map((record) => {
                    record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm')
                    // console.log(record[0])
                    return record;
                });

                // records = getSeries(records);

                records.unshift(["Time", "Température cutanée agrégée sur 1 heure"]);

                this.setState({ ...this.state, data: records, options });
            })
            .catch(err => {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    },]
                ];
                const options = {
                    title: 'Température cutanée agrégée sur 1 heure (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({ ...this.state, data: records, options });
            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.graphDate !== this.props.graphDate) {
            getData(this.props)
                .then(records => {
                    const options = {
                        title: 'Température cutanée agrégée sur 1 heure',
                        vAxis: { title: '°C' },
                        hAxis: {
                            type: 'category'
                        },
                        series: {
                            0: { color: graphColor.temperature }
                        },
                        legend: { position: 'top', alignment: 'end' },
                        interpolateNulls: false
                    };

                    records = records.map((record) => {
                        record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm');
                        return record;
                    });

                    // records = getSeries(records);

                    records.unshift(["Time", "Température cutanée agrégée sur 1 heure"]);

                    this.setState({ ...this.state, data: records, options });
                })
                .catch(err => {
                    const records = [
                        [{
                            f: 'Date',
                            type: 'date'
                        }, {
                            f: 'Line',
                            type: 'number'
                        },]
                    ];
                    const options = {
                        title: 'Température cutanée agrégée sur 1 heure (Aucune donnée disponible)',
                        series: {
                            0: {
                                color: 'transparent'
                            }
                        }
                    };
                    this.setState({ ...this.state, data: records, options });
                })
        }
    }

    render() {
        if (!this.state.data.length) return <div className="loader" />;
        return (
            <Chart
                chartType="LineChart"
                loader={<div>Température cutanée agrégée sur 1 heure</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />
        )
    }

}


export default Temperature;
