import React from "react";
import RadioGroups from 'components/RadioGroup';
import {Entity} from 'utils/services/entity';
import {Grid, withStyles} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { withTranslation } from 'react-i18next';
import MUIDataTable from "mui-datatables";
//import moment from 'moment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from "moment-timezone";
import { timezone } from "../../../utils/common";


// const resolutionOptions = [{
//         key: "Aucune action effectuée",
//         value: "Aucune action effectuée"
//     },{
//         key: "Appel au patient sans suite",
//         value: "Appel au patient sans suite"
//     },{
//         key: "Appel au patient avec prescription d’un traitement de support",
//         value: "Appel au patient avec prescription d’un traitement de support"
//     },{
//         key: "Appel au patient avec convocation",
//         value: "Appel au patient avec convocation"
//     },{
//         key: "Appel au médecin",
//         value: "Appel au médecin"
//     },
// ];

const resolutionOptions = [{
    key: " Aucune action nécessaire.",
    value: " Aucune action nécessaire.",
    isChecked:false

}, {
    key: " Aucune action nécessaire après appel du patient.",
    value: " Aucune action nécessaire après appel du patient.",
    isChecked:false
}, {
    key: " Prescription de bilan complémentaire après appel du patient.",
    value: " Prescription de bilan complémentaire après appel du patient.",
    isChecked:false
}, {
    key: " Prescription de traitement après appel du patient.",
    value: " Prescription de traitement après appel du patient.",
    isChecked:false
}, {
    key: " Convocation dans le service après appel du patient.",
    value: " Convocation dans le service après appel du patient.",
    isChecked:false
}, {
    key: " Remplacement du matériel pour problème technique.",
    value: " Remplacement du matériel pour problème technique.",
    isChecked:false
}, {
    key: " Résolution à distance d’un problème techniquet.",
    value: " Résolution à distance d’un problème technique.",
    isChecked:false
}, {
    key: " Demande d’avis médical spécialisé.",
    value: " Demande d’avis médical spécialisé.",
    isChecked:false
}, {
    key: " Demande d’avis au médecin investigateur ou co-investigateur.",
    value: " Demande d’avis au médecin investigateur ou co-investigateur.",
    isChecked:false
}, {
    key: " Demande d’avis à l'ingénieur biomédical.",
    value: " Demande d’avis à l'ingénieur biomédical.",
    isChecked:false
},
];


const resolutionOptions1 = [{
    key: " Aucune action nécessaire.",
    value: " Aucune action nécessaire.",
    isChecked:false

}, {
    key: " Aucune action nécessaire après appel du patient.",
    value: " Aucune action nécessaire après appel du patient.",
    isChecked:false
}, {
    key: " Prescription de bilan complémentaire après appel du patient.",
    value: " Prescription de bilan complémentaire après appel du patient.",
    isChecked:false
}, {
    key: " Prescription de traitement après appel du patient.",
    value: " Prescription de traitement après appel du patient.",
    isChecked:false
}, {
    key: " Convocation dans le service après appel du patient.",
    value: " Convocation dans le service après appel du patient.",
    isChecked:false
}, {
    key: " Remplacement du matériel pour problème technique.",
    value: " Remplacement du matériel pour problème technique.",
    isChecked:false
}, {
    key: " Résolution à distance d’un problème techniquet.",
    value: " Résolution à distance d’un problème technique.",
    isChecked:false
}, {
    key: " Demande d’avis médical spécialisé.",
    value: " Demande d’avis médical spécialisé.",
    isChecked:false
}, {
    key: " Demande d’avis au médecin investigateur ou co-investigateur.",
    value: " Demande d’avis au médecin investigateur ou co-investigateur.",
    isChecked:false
}, {
    key: " Demande d’avis à l'ingénieur biomédical.",
    value: " Demande d’avis à l'ingénieur biomédical.",
    isChecked:false
},
];


const alertHistoryFields = [
    { key: "name", value: "Alert Name" },
    { key: "resolution", value: "Action" },
    { key: "created_at", value: "Alert Time" },
    { key: "read_at", value: "Read Time" }
];

const getAlertHistoryOptions = (t) => ({
    textLabels: {
        body: {
            noMatch: t("Sorry, no matching records found"),
        },
    },
    pagination: false,
    search: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    sort: true,
    responsive: "scroll",
    selectableRows: false,
});

const getTimezone = timezone();

const processData = (data, t) => {
    // console.log("data",data)

    const processedData = data.map(item => {
        const rowData = alertHistoryFields.map(f => {
            let cellValue = item[f.key] || '';
            // print the alert creation and response time (only if the alert has been responded to)
            if (f.key === 'created_at' || (f.key === 'read_at' && item[f.key] !== null)) {
                //   cellValue = moment(cellValue).format('llll')
                cellValue = moment(cellValue).tz(getTimezone).format('llll')

            } else if (f.key === 'name') {
                cellValue = t(cellValue);
            }

            return cellValue;
        });
        return rowData;
    });
    return processedData;
}

class AlertFollowUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resolution: null,
            loading: false,
            error: null,
            resolutionOptions,
            resolutionOptionsInitial:resolutionOptions1,
            checkedItems: new Map(),
            initialLoading:false
        };
        this.resolutionField = {
            name: "resolution",
            // label: "Alert Follow Up",
            type: "checkbox",
            align: "left",
            disabled: false,
            fontSize: "14",
            options: resolutionOptions
        };
        this.handleChange = this.handleChange.bind(this);
    }

   // this will be called when onclick of checkbos is called
    handleChange(event) {
        var isChecked = event.target.checked;
        var item = event.target.value;
        this.setState({ resolution: event.target.value });
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        
        const filteredResolutionOption1 = resolutionOptions.filter((index)=> index.value === item )
        const itemIndex = resolutionOptions.indexOf(filteredResolutionOption1[0])
        let filteredResolutionOption = resolutionOptions;
        filteredResolutionOption[itemIndex] = {key:filteredResolutionOption1[0].key, value: filteredResolutionOption1[0].value, isChecked : isChecked}
        if(this.setState.initialLoading) this.setState({resolutionOptions: this.state.resolutionOptionsInitial })
        else this.setState({resolutionOptions: filteredResolutionOption})
        
    }

    componentDidMount() {
        this.getAlertHistory(false);
    }

    getAlertHistory = (isReloaded) => {
        const patient_id = this.props.patient_details._id;
        const options = {
            entity: 'alert_logs',
            method: 'get',
            command: 'listingAlertLogs'
        };
        const queryString = `patient_id=${patient_id}`;
        const customUrl = `/api/1/entity/${options.entity}/_/${options.command}?${queryString}`;
        this.setState({ loading: true, error: null });
        fetch(customUrl, { method: options.method })
            .then(response => response.json())
            .then((response) => {
              let alertHistory = response.data.records
             
                // find first item which is not resolved
                this.unresolvedAlert = alertHistory.find(item => ((!item.resolution) ||(item.resolution == null)|| (item.resolution.length==0)));
                const alertHistoryData = processData(alertHistory, this.props.t);
                this.setState({ alertHistoryData, resolution: null, loading: false });
                // initialize the map
               if(isReloaded){ this.setState({checkedItems: new Map()})}
            }).catch((err) => {
                this.setState({ loading: false, error: err.error });
            });
    }
    _onSubmit = () => {
        const newArr = []
        this.state.checkedItems.forEach((value, key) => {
        if (value) newArr.push(key)
        })
        this.state.resolution = newArr;
        // creating the data
        const options = {
            entity: "alert_logs",
            data: {
                alert_log_ids: [this.unresolvedAlert._id],
                resolution: this.state.resolution
            }
        };
        this.setState({ loading: true, error: null});
        this.state.resolutionOptions.forEach((value, key) => {
           value.isChecked =false
        })
        this.setState({resolutionOptions: this.state.resolutionOptionsInitial})
        // making the api call
        Entity.call("saveResolution", options)
            .then(() => {
             
                this.getAlertHistory(true);
               
            }).catch((err) => {
                this.setState({ loading: false, error: err.error });
            });
    }
   // this func is resposible for creating the table 
    renderAlertHistory = () => {
        const { classes, t } = this.props;
        const columns = alertHistoryFields.map(f => t(f.value));
        const alertHistoryData = this.state.alertHistoryData;
        return (
            <div style={{ position: 'relative', paddingTop: '50px' }}>
                <div className={classes.alertHistoryTitle}>{t('Alert History')}</div>
                <MUIDataTable
                    data={alertHistoryData}
                    columns={columns}
                    options={getAlertHistoryOptions(t)}
                />
            </div>

        );
    }

    renderTitle = () => {
        const title = this.props.t('Alert Follow Up');
        return (
            <Typography
                variant="h5"
                component="h5"
                style={{ paddingBottom: '30px', textShadow: '2px 2px 4px blue' }}
            >
                {title}
            </Typography>
        );
    }

    renderUnresolvedAlert = () => {
        const { t } = this.props;
        const unresolvedAlert = this.unresolvedAlert;
        if (!unresolvedAlert) return (
            <div>
                {this.renderTitle()}
                <div>{t('No alert available')}</div>
            </div>
        );
        const field = this.resolutionField;
        const alertStatement = t('Please choose an action for');
        const alertDetails = unresolvedAlert.created_at
            ? `${t(unresolvedAlert.name)} ${t('created at')} ${moment(unresolvedAlert.created_at).tz(getTimezone).format('llll')}`
            : `${t(unresolvedAlert.name)}`;
        return (
            <div>
                {this.renderTitle()}
                <div style={{ fontWeight: '600', paddingBottom: '20px' }}>
                    {alertStatement} {alertDetails}
                </div>
                <Grid container spacing={3}>
                    <Grid xs={12}>
                        {/* <RadioGroups
                            field={field}
                            onChange={(e) => {
                                this.setState({resolution: e.target.value});
                            }}
                            value={this.state.resolution}
                            disabled={this.state.loading}
                            align={field.align}>
                        </RadioGroups> */}{
                            this.state.resolutionOptions.map(item => (
                               
                                   <div style = {{flexDirection: "row",display:"flex"}}>
                                        <label style = {{paddingLeft: 0 ,fontSize:20}}>
                                            <input 
                                                type="checkbox"
                                                value={item.value}
                                                onChange={this.handleChange}
                                                disabled={this.state.loading}
                                                align={field.align}
                                                checked={item.isChecked}
                                            /> {item.value}
                                        </label>
                                     </div>
                             
                            ))
                        }

                        < div style={{ padding: '30px 0' }}>
                            <Button
                                style={{ width: 'auto' }}
                                onClick={this._onSubmit}
                                variant="contained"
                                color="primary"
                                disabled={this.state.loading || !this.state.resolution}
                            >
                                {t('Save Alert Follow Up')}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div >
        );
    }

    render() {
        const { loading, error } = this.state;
        const { classes } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                {loading && <div className={`loader ${classes.loader}`} />}
                {this.renderUnresolvedAlert()}
                {error && <Alert severity="error">{error}</Alert>}
                {this.renderAlertHistory()}
            </div>
        );
    }
}

const useStyles = (theme) => ({
    divider: {
        background: "#000000"
    },
    alertHistoryTitle: {
        padding: '20px',
        position: 'absolute',
        fontSize: '20px',
        textAlign: 'center',
        width: '100%',
    },
    loader: {
        position: 'absolute',
        zIndex: 999,
        left: '50%',
        marginLeft: '-40px'
    }
});

export default withStyles(useStyles)(withTranslation('common')(AlertFollowUp));

