import PrivateRoute from 'components/PrivateRoute';

//Here common routes are created
import SignIn from "./common/pages/SignIn";
import ChangePassword from "./common/pages/ChangePassword";
import ForgotPassword from "./common/pages/ForgotPassword";

//Here Multidom specific routes are created
import Account from './multidom/pages/Account';
import Admin from "./multidom/pages/Admin";
import AdminAdd from "./multidom/pages/AdminAdd";
import ContactUs from "./multidom/pages/ContactUs";
import PatientView from './multidom/pages/DoctorDashboard';
import PatientEdit from "./multidom/pages/PatientEdit";
import DoctorProfile from "./multidom/pages/DoctorProfile";
import DoctorRegistration from "./multidom/pages/DoctorRegistration";
import Doctors from './multidom/pages/Doctors';
import DoctorsPatientList from "./multidom/pages/DoctorsPatientList";
import NursePatientList from "./multidom/pages/NursePatientList";
import NurseProfile from "./multidom/pages/NurseProfile";
import NurseRegistration from './multidom/pages/NurseRegistration';
import Nurses from './multidom/pages/Nurses';
import PatientRegistration from "./multidom/pages/PatientRegistration";
import Patients from './multidom/pages/Patients';
import QnASurvey from './multidom/pages/QnASurvey';
import Questionnaire from "./multidom/pages/Questionnaire";

//Here Hepadom specific routes are created
import Hepadom_Admin from "./hepadom/pages/Admin";
import Hepadom_AdminAdd from "./hepadom/pages/AdminAdd";
import Hepadom_Doctor from "./hepadom/pages/Doctors";
import HepDoctorRegistration from "./hepadom/pages/DoctorRegistration";
import HepadomPatientRegistration from "./hepadom/pages/PatientRegistration";
import HepadomPatients from "./hepadom/pages/Patients";
import HepadomNurseRegistration from "./hepadom/pages/NurseRegistration";
import HepadomNurses from "./hepadom/pages/Nurses";
import HepadomPatientDelete from "./hepadom/pages/PatientDelete";
import HepadomAccount from './hepadom/pages/Account';
import HepadomDoctorsPatientList from "./hepadom/pages/DoctorsPatientList";
import HepadomCalender from "./hepadom/pages/Calendar";
import HepadomDoctorProfile from "./hepadom/pages/DoctorProfile";
import HepadomNursePatientList from "./hepadom/pages/NursePatientList";
import HepadomNurseProfile from "./hepadom/pages/NurseProfile";
import HepadomQuestionnaire from "./hepadom/pages/Questionnaire";
import HepadomPatientView from "./hepadom/pages/HepadomPatientView";
import HepadomPatientEdit from "./hepadom/pages/PatientEdit";

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Routing from './utils/routing';
import Calender from "./multidom/pages/Calendar";
import PatientDelete from "./multidom/pages/PatientDelete";


const {Switch, Route, Redirect} = Routing;

const RoleRoutes = {
    "public": [
        {
            path: "/sign-in",
            component: SignIn,
            default: true
        },
        {
            path: "/forgot-password",
            component: ForgotPassword
        },
        {
            path: "/change-password",
            component: ChangePassword
        }
    ],
    "admin": [
        {
            path: "/patients",
            component: Patients,
            default: true
        },
        {
            path: "/doctors",
            component: Doctors
        },
        {
            path: "/account",
            component: Account
        },
        {
            path: "/patients/_add",
            component: PatientRegistration
        },
        {
            path: "/doctors/_add",
            component: DoctorRegistration
        },
        {
            path: "/patients/:id/view",
            component: PatientView
        },
        {
            path: "/patients/:id/_edit",
            component: PatientEdit
        },
        {
            path: "/nurses",
            component: Nurses
        },
        {
            path: "/nurses/_add",
            component: NurseRegistration
        },
        {
            path: "/delete_patient",
            component: PatientDelete
        },
    ],
    "patient": [
        {
            path: "/questionnaires",
            component: Questionnaire,
            default: true
        },
        {
            path: "/calender",
            component:Calender
        }
    ],
    "doctor": [
        {
            path: "/patients",
            component: DoctorsPatientList,
            default: true
        },
        {
            path: "/patients/:id/view",
            component: PatientView
        },
        {
            path: "/patients/:id/_edit",
            component: PatientEdit
        },
        {
            path: "/calender",
            component:Calender
        },
        {
            path: "/profile",
            component: DoctorProfile
        },

    ],
    "nurse": [
        {
            path: "/patients",
            component: NursePatientList,
            default: true
        },
        {
            path: "/patients/:id/view",
            component: PatientView
        },
        {
            path: "/patients/:id/_edit",
            component: PatientEdit
        },
        {
            path: "/profile",
            component: NurseProfile
        }
    ],
    "superadmin": [
        {
            path: "/admin",
            component: Admin,
            default: true
        },
        {
            path: "/admin/_add",
            component: AdminAdd,
        }
    ],
    "hepadom_superadmin": [
        {
            path: "/hepadom/admin",
            component: Hepadom_Admin,
            default: true
        },
        {
            path: "/hepadom/admin/_add",
            component: Hepadom_AdminAdd,
        }
    ],
    "hepadom_admin": [


        {
            path: "/hepadom/patients",
            component: HepadomPatients,
            default: true
        },
        {
            path: "/hepadom/doctors",
            component: Hepadom_Doctor
            
        },
        {
            path: "/hepadom/patients/_add",
            component: HepadomPatientRegistration
        },
        {
            path: "/hepadom/doctors/_add",
            component: HepDoctorRegistration
        },

        {
            path: "/hepadom/nurses",
            component: HepadomNurses
        },
        {
            path: "/hepadom/nurses/_add",
            component: HepadomNurseRegistration
        },
        {
            path: "/hepadom/account",
            component: HepadomAccount
        },
        {
            path: "/hepadom/delete_patient",
            component: HepadomPatientDelete
        },
        {
            path: "/hepadom/patients/:id/view",
            component: HepadomPatientView
        },
        {
            path: "/hepadom/patients/:id/_edit",
            component: HepadomPatientEdit
        }
    ],
    "hepadom_patient": [
        {
            path: "/hepadom/questionnaires",
            component: HepadomQuestionnaire,
            default: true
           
        },
        {
            path: "/hepadom/calender",
            component:HepadomCalender,
            
        }
    ],
    "hepadom_doctor": [
        {
            path: "/hepadom/patients",
            component: HepadomDoctorsPatientList,
            default: true
        },
        {
            path: "/hepadom/calender",
            component:HepadomCalender
        },
        {
            path: "/hepadom/profile",
            component: HepadomDoctorProfile
        },
        {
            path: "/hepadom/patients/:id/view",
            component: HepadomPatientView
        },
        {
            path: "/hepadom/patients/:id/_edit",
            component: HepadomPatientEdit
        }

    ],
    "hepadom_nurse": [
        {
            path: "/hepadom/patients",
            component: HepadomNursePatientList,
            default: true
        },
        {
            path: "/hepadom/profile",
            component: HepadomNurseProfile
        },
        {
            path: "/hepadom/patients/:id/view",
            component: HepadomPatientView
        },
        {
            path: "/hepadom/patients/:id/_edit",
            component: HepadomPatientEdit
        }
    ],
};


class Routers extends Component {
    state = {loading: true, questionnaire: false};

    render() {
        const {user} = this.props;
        let Routes = RoleRoutes[user.role];
        let defaultPath = Routes.filter(Route => Route.default);
        defaultPath = defaultPath[0].path;
        if (this.state.questionnaire) {
            Routes = [{
                path: "/questionnaire",
                component: QnASurvey,
                default: true
            },];
            defaultPath = "/questionnaire";
        }


        Routes.push({
            path: "/contact-us",
            component: ContactUs
        });

        return (<Switch> {
                Routes.map((Route, index) =>
                    <PrivateRoute exact key={index}
                                  path={Route.path}
                                  component={Route.component}/>
                )
            }
                <Route path="*"
                       render={
                           () => (<Redirect to={defaultPath}/>)}/>
            </Switch>
        );
    }
}

export const mapStateToProps = state => () => {
    return ({
        user: state.user,
    });
};

export default connect(mapStateToProps)(Routers);