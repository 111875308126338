import React, {Fragment} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles, Typography, Grid} from "@material-ui/core";
import styles from './styles';
import { Entity } from "utils/services/entity";

class CheckBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }
    getData(options) {
        return new Promise((resolve, reject) => {
            Entity.list(options)
                .then((response) => {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch((err) => {
                    reject(err);
                })
        });
    }

    componentDidMount() {
        const { field } = this.props;
        if (field.resolve && typeof field.resolve === "object" && Object.keys(field.resolve).length) {
            const { entity, fields, filters } = field.resolve;
            const options = {
                entity,
                query: {
                    fields,
                    filters
                }
            };
            this.getData(options)
                .then((records) => {
                    const options = records.map((record) => { return { key: record[field.resolve.key], value: record[field.resolve.value] } });
                    this.setState({ ...this.state, options });
                })
                .catch((err) => {
                    // do nothing
                })

        } else if (field.options) {
            this.setState({ ...this.state, options: field.options });
        }
    }

    shouldDisableCheckbox(value, limit) {
        return value.length === limit;
    }

    render() {
        let { field, onChange, classes, value } = this.props;
        if (!value) value = [];
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg md sm={12}>
                        <Typography variant="subtitle2" color ="primary"
                                    className={classes.questions}>{field.label}</Typography>
                    </Grid>
                    <Grid item xs={12} lg md sm={12}>
                        <FormGroup className={classes.checkbox}
                                   aria-label="position"
                                   name={field.label}
                        >
                            {this.state.options.map((choice, index) => {
                                const selectionIndex = value.indexOf(choice.value);
                                const showSelectionIndex = field.showSelectionIndex && selectionIndex > -1;
                                return (
                                    <Fragment>
                                        <div style={{position: 'relative'}}>
                                            {showSelectionIndex && <span style={{position: 'absolute', marginLeft: '-15px',  top: '10px'}}>
                                                {selectionIndex + 1}
                                            </span>}
                                        </div>
                                        <FormControlLabel
                                            control={<Checkbox
                                                color="primary"
                                                value={choice.value}
                                                name={field.name}
                                            />}
                                            onChange={onChange}
                                            disabled={this.shouldDisableCheckbox(value, field.limit) && value.indexOf(choice.value) === -1}
                                            key={index}
                                            checked={selectionIndex > -1 || choice.checked === true}
                                            label={choice.label || choice.value}
                                        >
                                        </FormControlLabel>
                                    </Fragment>
                                );
                            })}
                        </FormGroup>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(CheckBox);
