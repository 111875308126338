import React from 'react';
import Dashboard from 'components/Dashboard';
import { Entity } from 'utils/services/entity';
import { HepadomEntity } from 'utils/services/hepadom_entity';
import Form from 'components/Form';
import { withTranslation } from 'react-i18next';

/*
method name : createForm
description : Creating the form object and setting it to state to create the Form Dynamically.
parameters : user {type:Object}
return : 
*/
function createForm(user) {
    const form = {
        onSubmit: this._onSubmit,
        submitButtonText: "Save Details",
        buttonWidth: '35%',
        fields: [
            {
                name: "first_name",
                label: "First Name",
                required: true,
                autofocus: true,
                validators: ["required"],
                width: "45%",
                errorMessages: ["First Name is required"],
                type: "text",
                value: user.first_name
            },
            {
                name: "last_name",
                label: "Last Name",
                required: true,
                autofocus: true,
                width: "45%",
                validators: ["required"],
                errorMessages: ["Last Name is required"],
                type: "text",
                value: user.last_name
            },
            {
                name: "email_id",
                label: "E-mail Address",
                required: true,
                autofocus: true,
                width: "45%",
                validate: [
                    {
                        type: "regex",
                        value: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
                        message: "Invalid Email ID"
                    }
                ],
                errorMessages: ["Email is required"],
                type: "text",
                value: user.email_id
            },
            {
                name: "phone_number",
                label: "Phone Number",
                required: true,
                autofocus: true,
                width: "45%",
                validate: [
                    {
                        type: "regex",
                        value: new RegExp(/^\+[1-9]{2}[0-9]{10,15}$/),
                        message: "Invalid mobile number"
                    }
                ],
                errorMessages: ["Phone Number is required"],
                type: "phone_number",
                value: user.phone_number
            }
        ]
    };

    this.setState({ ...this.state, form });
}

class Account extends React.Component {
    constructor(props) {
        super(props);

        const { user } = props;

        this.state = {};

        const options = {
            entity: "doctors",
            entity_id: user._id
        };

        HepadomEntity.get(options)
            .then((response) => {
                createForm.call(this, response.data);
            })
            .catch((err) => {
               // Do Nothing
            });

    }


/*
method name : _onSubmit
description : Handelling the form submit , Data prepation for Service Call
parameters : 
return : Promise
*/
    _onSubmit() {
        return new Promise((resolve, reject) => {
            const user = window.session.user;
            const data = this.state.data;
            const { t } = this.props;
            const options = {
                entity: "doctors",
                entity_id: user._id,
                data
            };
            HepadomEntity.edit(options)
                .then((response) => {
                    resolve({ message: t("Saved Successfully") });
                })
                .catch((err) => {
                    reject({ message: err.error });
                })
        });
    }

    render() {
        const { t } = this.props;
        return (
            <Dashboard title={t("Accounts")}>
                {this.state.form ? <Form options={this.state.form} title={t("Account Details")} /> : <div className="loader" />}
            </Dashboard>
        )
    }
}

export default withTranslation('common')(Account);
