import React from "react";
import { HepadomEntity } from "utils/services/hepadom_entity";
import { Grid, withStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment-timezone";
import { timezone } from "../../../utils/common";

const resolutionOptions = [
  {
    key: "No action required",
    value: "No action required",
    isChecked: false,
  },
  {
    key: "No action required after patient call",
    value: "No action required after patient call",
    isChecked: false,
  },
  {
    key: "Prescription of additional assessment after patient call",
    value: "Prescription of additional assessment after patient call",
    isChecked: false,
  },
  {
    key: "Treatment prescription after patient call",
    value: "Treatment prescription after patient call",
    isChecked: false,
  },
  {
    key: "Summons to the patient's after-call service",
    value: "Summons to the patient's after-call service",
    isChecked: false,
  },
  {
    key: "Replacement of equipment for technical problem",
    value: "Replacement of equipment for technical problem",
    isChecked: false,
  },
  {
    key: "Remote resolution of a technical problem",
    value: "Remote resolution of a technical problem",
    isChecked: false,
  },
  {
    key: "Request for specialist medical advice",
    value: "Request for specialist medical advice",
    isChecked: false,
  },
  {
    key: "Request for advice from the investigating doctor or non-investigator",
    value:
      "Request for advice from the investigating doctor or non-investigator",
    isChecked: false,
  },
  {
    key: "Request for advice from the biomedical engineer",
    value: "Request for advice from the biomedical engineer",
    isChecked: false,
  },
  {
    key: "Sending a SAMU/Firefighter team to the patient's home",
    value: "Sending a SAMU/Firefighter team to the patient's home",
    isChecked: false,
  },
];

const resolutionOptions1 = [
  {
    key: "No action required",
    value: "No action required",
    isChecked: false,
  },
  {
    key: "No action required after patient call",
    value: "No action required after patient call",
    isChecked: false,
  },
  {
    key: "Prescription of additional assessment after patient call",
    value: "Prescription of additional assessment after patient call",
    isChecked: false,
  },
  {
    key: "Treatment prescription after patient call",
    value: "Treatment prescription after patient call",
    isChecked: false,
  },
  {
    key: "Summons to the patient's after-call service",
    value: "Summons to the patient's after-call service",
    isChecked: false,
  },
  {
    key: "Replacement of equipment for technical problem",
    value: "Replacement of equipment for technical problem",
    isChecked: false,
  },
  {
    key: "Remote resolution of a technical problem",
    value: "Remote resolution of a technical problem",
    isChecked: false,
  },
  {
    key: "Request for specialist medical advice",
    value: "Request for specialist medical advice",
    isChecked: false,
  },
  {
    key: "Request for advice from the investigating doctor or non-investigator",
    value:
      "Request for advice from the investigating doctor or non-investigator",
    isChecked: false,
  },
  {
    key: "Request for advice from the biomedical engineer",
    value: "Request for advice from the biomedical engineer",
    isChecked: false,
  },
  {
    key: "Sending a SAMU/Firefighter team to the patient's home",
    value: "Sending a SAMU/Firefighter team to the patient's home",
    isChecked: false,
  },
];

const alertHistoryFields = [
  { key: "alert_type", value: "Alert Name" },
  { key: "resolution", value: "Alert Follow Up Actions" },
  { key: "created_at", value: "Alert Time" },
  { key: "acknowledged_at", value: "Read Time" }
];

const getAlertHistoryOptions = (t) => ({
  textLabels: {
    body: {
      noMatch: t("Sorry, no matching records found"),
    },
  },
  pagination: false,
  search: false,
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
  sort: false,
  responsive: "scroll",
  selectableRows: false,
});

const getTimezone = timezone();

const processData = (data, t) => {
  
  const processedData = data.map((item) => {
    const rowData = alertHistoryFields.map((f) => {
      let cellValue = item[f.key] || "";
      if (f.key === "created_at") {
        cellValue = item[f.key]
          ? moment(cellValue).tz(getTimezone).format("DD/MM/YYYY-HH:mm")
          : "";
      } else if (f.key === "acknowledged_at") {
        cellValue = item[f.key]
          ? moment(cellValue).tz(getTimezone).format("DD/MM/YYYY-HH:mm")
          : "";
      } else if (f.key === "alert_type") {
        const alertName = getAlertName(item[f.key]);
        if (item[f.key] === 17) {
          cellValue = getMissingData(item["alert_details"].email_message.email_message_en, t);
        } else {
          cellValue = t(alertName);
        }
      } else if (f.key === "resolution") {
        cellValue = item[f.key].map((resolution) => (
            <li>{t(resolution)}</li>
        ));
      }

      return cellValue;
    });
    return rowData;
  });
  return processedData;
};

const getMissingData = (details, t) => {
  let firstMissingData = true;
  let alertMissingData = t("Missing medical data") + " : ";
  if (details.includes("(Ear temperature)")) {
    if (!firstMissingData) alertMissingData = alertMissingData + ", ";
    alertMissingData = alertMissingData + t("Ear temperature");
    firstMissingData = false;
  }
  if (details.includes("(heart rate)")) {
    if (!firstMissingData) alertMissingData = alertMissingData + ", ";
    alertMissingData = alertMissingData + t("Heart rate");
    firstMissingData = false;
  }
  if (details.includes("(systolic blood pressure)")) {
    if (!firstMissingData) alertMissingData = alertMissingData + ", ";
    alertMissingData = alertMissingData + t("Systolic blood pressure");
    firstMissingData = false;
  }
  if (details.includes("(diastolic blood pressure)")) {
    if (!firstMissingData) alertMissingData = alertMissingData + ", ";
    alertMissingData = alertMissingData + t("Diastolic blood pressure");
    firstMissingData = false;
  }
  if (details.includes("(Oximetry)")) {
    if (!firstMissingData) alertMissingData = alertMissingData + ", ";
    alertMissingData = alertMissingData + t("Oximetry");
    firstMissingData = false;
  }
  if (details.includes("(Weight)")) {
    if (!firstMissingData) alertMissingData = alertMissingData + ", ";
    alertMissingData = alertMissingData + t("Weight");
    firstMissingData = false;
  }
  if (details.includes("(Pain)")) {
    if (!firstMissingData) alertMissingData = alertMissingData + ", ";
    alertMissingData = alertMissingData + t("Pain");
    firstMissingData = false;
  }
  return alertMissingData;
}

const getAlertName = (alertType) => {
  const alerts = [
    {
      type: 1,
      name: "No ear temperature data alert",
    },
    {
      type: 2,
      name: "No systolic blood pressure data alert",
    },
    {
      type: 3,
      name: "No diastolic blood pressure data alert",
    },
    {
      type: 4,
      name: "No oximetry data alert",
    },
    {
      type: 5,
      name: "No heart rate data alert",
    },
    {
      type: 6,
      name: "No weight data alert",
    },
    {
      type: 7,
      name: "No MDASI answers alert",
    },
    {
      type: 8,
      name: "No pain assessment alert",
    },
    {
      type: 9,
      name: "MDASI alert",
    },
    {
      type: 10,
      name: "Pain alert",
    },
    {
      type: 11,
      name: "Weight variation alert",
    },
    {
      type: 12,
      name: "Systolic blood pressure alert",
    },
    {
      type: 13,
      name: "Diastolic blood pressure alert",
    },
    {
      type: 14,
      name: "Heart rate alert",
    },
    {
      type: 15,
      name: "Ear temperature alert",
    },
    {
      type: 16,
      name: "Oximetry alert",
    },
    {
      type: 17,
      name: "Missing medical data alert",
    },
  ];

  const alert = alerts.filter((alert) => alert.type === alertType);
  if (alert.length > 0) {
    return alert[0].name;
  } else {
    return "Invalid Alert Name";
  }
};

class AlertFollowUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resolution: null,
      loading: false,
      error: null,
      resolutionOptions,
      resolutionOptionsInitial: resolutionOptions1,
      checkedItems: new Map(),
      initialLoading: false,
    };
    this.resolutionField = {
      name: "resolution",
      type: "checkbox",
      align: "left",
      disabled: false,
      fontSize: "14",
      options: resolutionOptions,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  // this will be called when onclick of checkbox is called
  handleChange(event) {
    var isChecked = event.target.checked;
    var item = event.target.value;
    this.setState({ resolution: event.target.value });
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));

    const filteredResolutionOption1 = resolutionOptions.filter(
      (index) => index.value === item
    );
    const itemIndex = resolutionOptions.indexOf(filteredResolutionOption1[0]);
    let filteredResolutionOption = resolutionOptions;
    filteredResolutionOption[itemIndex] = {
      key: filteredResolutionOption1[0].key,
      value: filteredResolutionOption1[0].value,
      isChecked: isChecked,
    };
    if (this.setState.initialLoading) {
      this.setState({ resolutionOptions: this.state.resolutionOptionsInitial });
    } else {
      this.setState({ resolutionOptions: filteredResolutionOption });
    }
  }

  componentDidMount() {
    this.getAlertHistory(false);
  }

  componentDidUpdate(prevProps) {
    const { t } = this.props;
    if (prevProps.i18n.language!==this.state.language) {
        this.setState({ ...this.state, language: prevProps.i18n.language });
        this.getAlertHistory(true);
        this.renderAlertHistory(this.props);
    }
}

  getAlertHistory = (isReloaded) => {
    const patient_id = this.props.patient_details._id;
    const options = {
      entity: "alert",
      method: "get",
      command: "listingAlertLogs",
    };
    const queryString = `patient_id=${patient_id}`;
    const customUrl = `/api/1/hepadom/entity/${options.entity}/_/${options.command}?${queryString}`;
    this.setState({ loading: true, error: null });
    fetch(customUrl, { method: options.method })
      .then((response) => response.json())
      .then((response) => {
        let alertHistory = response.data.alerts;
        // find first item which is not resolved

        this.unresolvedAlert = alertHistory.find(
          (item) =>
            !item.resolution ||
            item.resolution === null ||
            item.resolution.length === 0
        );
        const alertHistoryData = processData(alertHistory, this.props.t);
        this.setState({ alertHistoryData, resolution: null, loading: false });
        // initialize the map
        if (isReloaded) {
          this.setState({ checkedItems: new Map() });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, error: err.error });
      });
  };
  _onSubmit = () => {
    const newArr = [];
    this.state.checkedItems.forEach((value, key) => {
      if (value) {
        newArr.push(key);
      }
    });
    this.state.resolution = newArr;
    // creating the data
    const options = {
      entity: "alert",
      data: {
        patient_id: this.unresolvedAlert.patient_id,
        alert_type: this.unresolvedAlert.alert_type,
        alert_id: this.unresolvedAlert.alert_id,
        resolution: this.state.resolution,
      },
    };
    this.setState({ loading: true, error: null });
    this.state.resolutionOptions.forEach((value, key) => {
      value.isChecked = false;
    });
    this.setState({ resolutionOptions: this.state.resolutionOptionsInitial });
    // making the api call
    HepadomEntity.call("saveResolution", options)
      .then(() => {
        this.getAlertHistory(true);
      })
      .catch((err) => {
        this.setState({ loading: false, error: err.error });
      });
  };
  // this func is responsible for creating the table
  renderAlertHistory = (params) => {
    const { classes, t } = params;
    const columns = alertHistoryFields.map((f) => t(f.value));
    const alertHistoryData = this.state.alertHistoryData;
    return (
      <div style={{ position: "relative", paddingTop: "50px" }}>
        <div className={classes.alertHistoryTitle}>{t("Alert History")}</div>
        <div className="custom-data-tbl">
          <MUIDataTable
            data={alertHistoryData}
            columns={columns}
            options={getAlertHistoryOptions(t)}
          />
        </div>
      </div>
    );
  };

  renderTitle = () => {
    const title = this.props.t("Alert Follow Up");
    return (
      <Typography
        variant="h5"
        component="h5"
        style={{ paddingBottom: "30px", textShadow: "2px 2px 4px blue" }}
      >
        {title}
      </Typography>
    );
  };

  renderUnresolvedAlert = () => {
    const { t } = this.props;
    const unresolvedAlert = this.unresolvedAlert;
    if (!unresolvedAlert) {
      return (
        <div>
          {this.renderTitle()}
          <div>{t("No alert available")}</div>
        </div>
      );
    }
    const field = this.resolutionField;
    const alertStatement = t("Please choose an action for");
    const unresolvedAlertName = getAlertName(unresolvedAlert.alert_type);
    const alertDetails = unresolvedAlert.created_at
      ? `${t(unresolvedAlertName)} ${t("created at")}
         ${moment(unresolvedAlert.created_at).tz(getTimezone).format("DD/MM/YYYY-HH:mm")}`
      : `${t(unresolvedAlertName)}`;
    return (
      <div>
        {this.renderTitle()}
        <div style={{ fontWeight: "600", paddingBottom: "20px" }}>
          {alertStatement} {alertDetails}
        </div>
        <Grid container spacing={3}>
          <Grid xs={12}>
            {this.state.resolutionOptions.map((item, index) => (
              <div
                style={{ flexDirection: "row", display: "flex" }}
                key={index}
              >
                <label style={{ paddingLeft: 0, fontSize: 18 }}>
                  <input
                    type="checkbox"
                    value={item.value}
                    onChange={this.handleChange}
                    disabled={this.state.loading}
                    align={field.align}
                    checked={item.isChecked}
                  />{" "}
                  {t(item.value)}
                </label>
              </div>
            ))}

            <div style={{ padding: "30px 0" }}>
              <Button
                style={{ width: "auto" }}
                onClick={this._onSubmit}
                variant="contained"
                color="primary"
                disabled={this.state.loading || !this.state.resolution}
              >
                {t("Save Alert Follow Up")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  render() {
    const { loading, error } = this.state;
    const { classes } = this.props;
    return (
      <div style={{ position: "relative" }}>
        {loading && <div className={`loader ${classes.loader}`} />}
        {this.renderUnresolvedAlert()}
        {error && <Alert severity="error">{error}</Alert>}
        {this.renderAlertHistory(this.props)}
      </div>
    );
  }
}

const useStyles = () => ({
  divider: {
    background: "#000000",
  },
  alertHistoryTitle: {
    padding: "20px",
    position: "absolute",
    fontSize: "20px",
    textAlign: "center",
    width: "100%",
  },
  loader: {
    position: "absolute",
    zIndex: 999,
    left: "50%",
    marginLeft: "-40px",
  },
});

export default withStyles(useStyles)(withTranslation("common")(AlertFollowUp));
