import React from 'react';
import { TextField, TextareaAutosize } from '@material-ui/core';
import {HepadomEntity} from "utils/services/hepadom_entity"
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { Grid, withStyles,Button,Snackbar,CircularProgress,FormControl } from "@material-ui/core";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import { green } from "@material-ui/core/colors";
function getPatientDetails(_id) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "patients",
            entity_id: _id
        };
        HepadomEntity.get(options)
            .then((response) => {
                const data = response.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            })
    })
}

class Protocol extends React.Component {
    state = {
        data:{},variant: "success", disabled: false,validation_result:""
     };

    populateForm(patient_details) {
            const { t } = this.props;
            getPatientDetails(patient_details._id)
                    .then((patient_details) => {
                        let Patient_Registration_Date = patient_details.Patient_Registration_Date;
                        let Hepactectomy_Date = patient_details.Hepactectomy_Date;
                        let Return_Of_Material_Date = patient_details.Return_Of_Material_Date;
                        let End_Of_Study_Date=patient_details.End_Of_Study_Date;
                        const comments = patient_details.comments || '';
                        Patient_Registration_Date = moment(Patient_Registration_Date).format("YYYY-MM-DD");
                        if (Hepactectomy_Date) Hepactectomy_Date = moment(Hepactectomy_Date).format("YYYY-MM-DD");
                        if (Return_Of_Material_Date)Return_Of_Material_Date = moment(Return_Of_Material_Date).format("YYYY-MM-DD");
                        if (End_Of_Study_Date) End_Of_Study_Date = moment(End_Of_Study_Date).format("YYYY-MM-DD");    
                        let Hepactectomy_Date_Changed=patient_details.Hepactectomy_Date_Changed;
                        let data = { ...this.state.data };
                        data["Patient_Registration_Date"] = Patient_Registration_Date;
                        data["Hepactectomy_Date"] = Hepactectomy_Date;
                        data["Return_Of_Material_Date"] = Return_Of_Material_Date;
                        data["End_Of_Study_Date"] = End_Of_Study_Date;
                        data["comments"] = comments;
                        data["Hepactectomy_Date_Changed"] = Hepactectomy_Date_Changed;
                        this.setState({ ...this.state, data:data });
                        
                    })
                    .catch(err => {
                        this.setState({ ...this.state, error: err.error });
                    })
    }

    constructor(props) {
        super(props);
        const { patient_details } = props;
        this.populateForm(patient_details);  
    }
    componentDidMount() {
        
        
    }

    handleChange(event) {
        const { t } = this.props;
        let data = { ...this.state.data };
        data[event.target.name] = event.target.value;
        if(event.target.name==="Hepactectomy_Date")
        {
        data["Hepactectomy_Date_Changed"] = true;
        }
        this.setState({ ...this.state, data:data });

    }

    _onSubmit() {
        const { t,patient_details } = this.props;
        let validation_result="";
        let Patient_Registration_Date=moment(this.state.data.Patient_Registration_Date, "YYYY-MM-DD").add(3, 'days');
        Patient_Registration_Date=moment(Patient_Registration_Date).format("YYYY-MM-DD");
        let today=moment().format('YYYY-MM-DD');
        if(this.state.data.comments&&this.state.data.comments.length>180)
        validation_result="Comments cannot exceed 180 characters";
        
        if(validation_result==="")
        {
        const options = {
            entity: "patients",
            entity_id: patient_details._id,
            data:this.state.data
  
        };
            HepadomEntity.edit(options)
                .then((response) => {
                    this.setState({ ...this.state, open: true, variant: 'success', message: t("Register Successfully") , disabled: true, loading: false }); 
                })
                .catch((err) => {
                    this.setState({ ...this.state, open: true, variant: 'error', message: err.error, loading: false });
                })
        }
        else
        {
        this.setState({ ...this.state,open: true, variant: 'error', message: t(validation_result), loading: false });
        }
        
    }
    handleClose = () => {
        this.setState({ ...this.state, open: false });
    };

    render() {
        if (!this.state.data.Hepactectomy_Date) return <div className="loader" />;
        const { patient_details,t,classes } = this.props;
        return (
            <form className={classes.form}>
           <FormControl className={classes.formControl} style={{marginRight: 40,width:"45%"}}> 
            <TextField
            margin="normal"
            id="Patient_Registration_Date"
            type="date"
           label={t("Patient registration")}
            name="Patient_Registration_Date"
            disabled={false}
            value={this.state.data["Patient_Registration_Date"]}
            onChange={e => this.handleChange(e)}
            InputLabelProps={{
            shrink: true,
            }} />
           </FormControl>
           <FormControl className={classes.formControl} style={{marginRight: 40,width:"45%"}}> 
            <TextField
            margin="normal"
            id="Hepactectomy_Date"
            type="date"
            label={t("Hepatectomy")}
            name="Hepactectomy_Date"
            value={this.state.data["Hepactectomy_Date"]}
            onChange={e => this.handleChange(e)}
            InputLabelProps={{
            shrink: true,
            }} />
            </FormControl>
            <FormControl className={classes.formControl} style={{marginRight: 40,width:"45%"}}> 
            <TextField
            margin="normal"
            id="Return_Of_Material_Date"
            type="date"
           label={t("Return of material")}
            name="Return_Of_Material_Date"
            disabled={false}
            value={this.state.data["Return_Of_Material_Date"]}
            onChange={e => this.handleChange(e)}
            InputLabelProps={{
            shrink: true,
            }} />
            </FormControl>      
            <FormControl className={classes.formControl} style={{marginRight: 40,width:"45%"}}> 
            <TextField
            margin="normal"
            id="End_Of_Study_Date"
            type="date"
            label={t("End of study")}
            name="End_Of_Study_Date"
            disabled={false}
            value={this.state.data["End_Of_Study_Date"]}
            onChange={e => this.handleChange(e)}
            InputLabelProps={{
            shrink: true,
            }} />
            </FormControl>
            < FormControl className={classes.formControl}
              style={{width: '90%',margin: '20px 40px 20px -40px'}}>
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink">
              {t("Comments")}
                </label>
             <TextareaAutosize
            style={{fontSize: '14px',margin: '20px 0px',minHeight: '60px',marginRight : '-40px'}}
            variant="outlined"
            margin="normal"
            value={this.state.data["comments"]}
            id={"comments"}
            name={"comments"}
            onChange={e => this.handleChange(e)}
            />
           </FormControl>
       
            <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right"  }}
                    style={{ position: 'absolute', right: '100px' }}
                    key={`top,left`}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={this.state.variant}
                        message={this.state.message}
                    />
                </Snackbar>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "center" }}>
                        <Button variant="contained" color="primary" onClick={this._onSubmit.bind(this)} className={classes.button}>
                            {this.state.loading ? <CircularProgress
                                size={40}
                                className={classes.buttonProgress} /> : t("Save") }
                        </Button>
                    </Grid>
                </Grid>
          </form>
        );
    }

}
const useStyles = (theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        position: 'relative'
    },
    formControl: {
        minWidth: 120,
    },
    button: {
        width:'60%'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});
export default withStyles(useStyles)(withTranslation('common')(Protocol));
