import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';

import {
    AccountBoxOutlined as AccountBoxIcon,
    FormatListBulleted as Questionnaire,
    CalendarTodayOutlined as Calendar,
} from '@material-ui/icons';

import {
    Divider
} from '@material-ui/core';

import styles from './styles';

import Routing from 'utils/routing';
import { getEntity } from '../../../../utils/common';

const {Link} = Routing;

class Items extends React.Component {
    state = {listItems: []};
    /*
      Description : This will list items in navigation tab
                     based on the user role specific 
    */
    componentDidMount() {
        const {user} = this.props;

        let listItems = [];
        const pathName = window.location.pathname;
        switch (user.role) {
            case 'admin':
                listItems = [{
                    link: '/patients',
                    name: 'Patients',
                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/patient") > -1)
                },
                    {
                        link: '/doctors',
                        name: getEntity() === 'hepadom' ? 'On-Call Surgeon' : 'Doctor',
                        icon: <PeopleIcon/>,
                        selected: (pathName.indexOf("/doctor") > -1)
                    },
                    {
                        link: '/nurses',
                        name: getEntity() === 'hepadom' ? 'IPA' : 'Nurse',
                        icon: <PeopleIcon/>,
                        selected: (pathName.indexOf("/nurse") > -1)
                    },
                    {
                        link: '/delete_patient',
                        name: 'Complete Clinical Study',
                        icon: <PeopleIcon/>,
                        selected: (pathName.indexOf("/delete_patient") > -1)
                        
                    },
                    {
                        link: '/account',
                        name: 'Account',
                        icon: <AccountBoxIcon/>,
                        selected: (pathName.indexOf("/account") > -1)
                    },
                    
                ];
                break;
            case 'patient':
                listItems = [
                    {
                        link: '/questionnaires',
                        name: 'Questionnaires',
                        icon: <Questionnaire/>,
                        selected: (pathName.indexOf("/questionnaires") > -1)
                    },
                    {
                        link: '/calender',
                        name: 'Calendar',
                        icon: <Calendar/>,
                        selected:(pathName.indexOf("/calender") > -1)

                    },
                ];
                break;
            case 'doctor':
                listItems = [{
                    link: '/patients',
                    name: 'Patients',
                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/patients") > -1)
                },
                {
                    link: '/calender',
                    name: 'Calendar',
                    icon: <Calendar/>,
                    selected:(pathName.indexOf("/calender") > -1)

                },

                    {
                        link: '/profile',
                        name: 'Profile',
                        icon: <AccountBoxIcon/>,
                        selected: (pathName.indexOf("/profile") > -1)
                    },

                ];
                break;
            case 'nurse':
                listItems = [{
                    link: '/patients',
                    name: 'Patients',
                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/patients") > -1)
                },

                    {
                        link: '/profile',
                        name: 'Profile',
                        icon: <AccountBoxIcon/>,
                        selected: (pathName.indexOf("/profile") > -1)
                    }
                ];
                break;
            case 'superadmin':
                listItems = [{
                    link: '/admin',
                    name: getEntity() === 'hepadom' ? 'Investigating On-Call Surgeon' : 'Investigating Doctor',
                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/admin") > -1)
                }];
                break;
                case 'hepadom_superadmin':
                    listItems = [{
                        link: '/hepadom/admin',
                        name: getEntity() === 'hepadom' ? 'Investigating On-Call Surgeon' : 'Investigating Doctor',
                        icon: <PeopleIcon/>,
                        selected: (pathName.indexOf("/hepadom/admin") > -1)
                    }];
                    break;
            default:
                listItems = [];
        }

        this.setState({...this.state, listItems});
    }

    render() {
        const { t,classes} = this.props;
        return (
            <React.Fragment>
                <List
                    component="div"
                    disablePadding
                >
                    {this.state.listItems.map((item, index) =>
                        <ListItem
                            selected={item.selected}
                            activeclassname={classes.activeListItem}
                            className={classes.listItem}
                            component={Link}
                            to={item.link}
                            key={index}
                        >
                            <ListItemIcon activeclassname={classes.activeListItemIcon} className={classes.listItemIcon}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText activeclassname={classes.activeListItemText}
                                          classes={{primary: classes.listItemText}}
                                          primary={t(item.name)}
                            />
                        </ListItem>
                    )}
                </List>
                <Divider className={classes.listDivider}/>
                {/* <List
                    component="div"
                    disablePadding
                    subheader={
                        <ListSubheader className={classes.listSubheader}>
                            Support
                        </ListSubheader>
                    }
                >
                    <ListItem
                        className={classes.listItem}
                        component={Link}
                        to="/contact-us"
                        selected={window.location.pathname === "/contact-us"}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{primary: classes.listItemText}}
                            primary="Customer support"
                        />
                    </ListItem>
                </List> */}
            </React.Fragment>


        );
    }
}


ListItem.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object
};

export const mapStateToProps = state => () => {
    return ({
        user: state.user,
    });
};


export default connect(mapStateToProps)(withStyles(styles)(withTranslation('common')(Items)));
