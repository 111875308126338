import '../../styles/common.css';
import React from "react";
import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';
import { Entity } from "utils/services/entity";
import moment from 'moment';

const getYaxisColorArray = () => {
    const colorArray = [];
    for (let i = 0; i < 9; i++) {
        colorArray.push('#0066cc');
    }
    return colorArray;
}

const localData = {
    seriesDates: [],
    visitDates: [],
    chartSeries: [],
    chemotheraphy_dates: [],
    defaultColorArray: [],
    // colorArray: [],
    yaxisColorArray: getYaxisColorArray()
};

const graphKeys = [
    "Problèmes financiers",
    "Diarrhée",
    "Constipation",
    "Anorexie",
    "Insomnie",
    "Dyspnée",
    "Douleur",
    "Nausées et vomissements",
    "Fatigue",
    "Capacités relations sociales",
    "Capacité cognitive",
    "Etat émotionnel",
    "Capacité travail et loisirs",
    "Capacité physique",
    "Etat de santé global/QdV"
];

function getFormattedSeries(series) {
    series.map(s => {
        s.data.map(item => {
            item.y = item.y !== undefined ? item.y : -1;
            return item;
        });
    });
    return series;
}

function getChartSeries(records, t) {
    const baseSeries = localData.seriesDates.map(item => ({ x: item }));
    const series = [];
    graphKeys.map(item => {
        series.push({ name: item, label: item, data: JSON.parse(JSON.stringify(baseSeries)) });
    });
    for (let j = 0; j < records.length; j++) {
        const createdDate = moment(records[j].answered_at).format("DD-MM-YYYY");
        // find matching date in series and assiciate data
        const matchingIndex = series[0].data.findIndex(item => item.x === createdDate);
        if (matchingIndex < 0) continue;
        series.forEach(item => {
            item.data[matchingIndex].y = records[j].scores[item.label] ? Number(records[j].scores[item.label]) : undefined;
        });
    }
    const formattedSeries = getFormattedSeries(series);
    return formattedSeries;
}


function setSeriesDates(records, chemo) {
    localData.seriesDates = [];
    const formattedDate = []
    localData.defaultColorArray.splice(0, localData.defaultColorArray.length);
    if (!records) {
        return;
    }
    for (let i = 0; i < records.length; i++) {
        localData.seriesDates.push(moment(records[i].answered_at).format("DD-MM-YYYY"))
        if (chemo.find(item => item === localData.seriesDates[i])) {
            localData.defaultColorArray[0] = 'red';
        }
    }
}

function setChemotheraphyDate(patient_details) {

    if (!patient_details) {
        return;
    }
    // localData.visitDates = [
    //     patient_details.theoretical_date_of_visit_1,
    //     patient_details.theoretical_date_of_visit_3,
    //     patient_details.theoretical_date_of_visit_4,
    //     patient_details.theoretical_date_of_visit_5,
    // ];
    localData.chemotheraphy_dates = [];
    if (patient_details.theoretical_date_of_chemotheraphy_1) {
        localData.chemotheraphy_dates.push(moment(patient_details.theoretical_date_of_chemotheraphy_1).format("DD-MM-YYYY"));
    }
    if (patient_details.theoretical_date_of_chemotheraphy_2) {
        localData.chemotheraphy_dates.push(moment(patient_details.theoretical_date_of_chemotheraphy_2).format("DD-MM-YYYY"));
    }
    if (patient_details.theoretical_date_of_chemotheraphy_3) {
        localData.chemotheraphy_dates.push(moment(patient_details.theoretical_date_of_chemotheraphy_3).format("DD-MM-YYYY"));
    }
    return localData.chemotheraphy_dates

    // setSeriesDates();

}

function getQuestionnaires(patient_id) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "questionnaire",
            data: {
                patient_id,
                name: "EORTC-QLQ-C30"
            }
        };
        Entity.call("getQuestionnaire", options)
            .then(response => {
                const records = response.data.records;
                resolve(records);
            })
            .catch(err => {
                reject(err)
            })
    })
}

class SurveyEORTC extends React.Component {
    state = {
        options: {
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 10,
                                name: '0-10',
                                color: '#0066CC'
                            },
                            {
                                from: 10,
                                to: 20,
                                name: '10-20',
                                color: '#0080FF'
                            },
                            {
                                from: 20,
                                to: 30,
                                name: '20-30',
                                color: '#3399FF'
                            },
                            {
                                from: 30,
                                to: 40,
                                name: '30-40',
                                color: '#66B2FF'
                            },
                            {
                                from: 40,
                                to: 50,
                                name: '40-50',
                                color: '#99CCFF'
                            },
                            {
                                from: 50,
                                to: 60,
                                name: '50-60',
                                color: '#CCE5FF'
                            },
                            {
                                from: 60,
                                to: 70,
                                name: '60-70',
                                color: '#FFCCE5'
                            },
                            {
                                from: 70,
                                to: 80,
                                name: '70-80',
                                color: '#FF99CC'
                            },
                            {
                                from: 80,
                                to: 90,
                                name: '80-90',
                                color: '#FF66B2'
                            },
                            {
                                from: 90,
                                to: 100,
                                name: '90-100',
                                color: '#FF3399'
                            },
                            {
                                from: '-1',
                                to: '-1',
                                name: 'Non renseigné -1',
                                color: '#FFFFFF'
                            }
                        ]
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ["#008FFB"],

            title: {
                text: 'Scores questionnaire EORTC',
                align: 'center',
                style: {
                    fontSize: '20px'
                }
            },
            xaxis: {
                title: {
                    text: "Dates de réponse au questionnaire"
                },
                labels: {
                    style: {
                        colors: localData.defaultColorArray
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: localData.yaxisColorArray
                    }
                }
            }
        },
        series: [],
        loading: true,
        isDataAvailable :false
    };
    constructor(props) {
        super(props);
        const chemo = setChemotheraphyDate(props.patient_details);
        getQuestionnaires(props.patient_details._id)
            .then((data) => {
                if(data.length === 0){
                    this.state.isDataAvailable =false;
                }else{
                
                const records = data.sort((a, b) => new Date(a.answered_at) - new Date(b.answered_at));
                setSeriesDates(records, chemo);
                const series = getChartSeries(records, this.props.t);
                this.state.isDataAvailable = true;
                this.setState({ series, loading: false});
                }
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
    }

    render() {
           //     if (!this.state.series.length && this.state.loading) return <div className="loader" />;
       
        // if (!chartSeries.length) {
        //     return null;
        // }
        return (
          // (!this.state.series.length && this.state.loading) ? <div className="loader" /> : <div>
          <div>
                {(() => {
                 if (this.state.isDataAvailable) {
                        const chartSeries = this.state.series;
                        return (<div id="chart"
                            style={{ padding: '50px 0 20px' }}>
                            <ReactApexChart options={this.state.options}
                                series={chartSeries}
                                type="heatmap"
                                height="550" />
                        </div>

                        )
                     } 
                     else {
                        return (
                            <div>
                                <h2 style={{
                                    align: 'center',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                    fontFamily: `"Helvetica Neue", "Arial", "sans-serif"`,
                                }}>Scores questionnaire EORTC  </h2>
                                <div > Les questions n'ont pas reçu de réponse </div>

                            </div>


                        )
                    }
                })()}
            </div>


        );
        // if (!this.state.series.length && this.state.loading) return <div className="loader" />;
        // const chartSeries = this.state.series;
        // if (!chartSeries.length) {
        //     return null;
        // }
        // return (
        //     <div id="chart" style={{ padding: '50px 0 20px' }}>
        //         <ReactApexChart options={this.state.options} series={chartSeries} type="heatmap" height="550" />
        //     </div>
        // );
    }

}

export default ((SurveyEORTC));
