import React from "react";
import { Chart } from "react-google-charts";
import { HepadomEntity } from "utils/services/hepadom_entity";
import moment from 'moment';
import { getSeries_three_records, graphColor } from "../../utils/common";
import { withTranslation } from "react-i18next";

function getData(props) {
  return new Promise((resolve, reject) => {
    const options = {
      entity: "sensors",
      query: {
        name: "systolicVisualization",
        inclusion_id: props.inclusion_id,
        patient_id: props.patient_id,
        graphDate: props.graphDate,
      },
    };

    HepadomEntity.call("graph", options)
      .then((res) => {
        if (res.status !== 200) return reject();

        resolve(res.data.records);
      })
      .catch((err) => {
        console.log(err);
        reject();
      });
  });
}

class SystolicVisualization extends React.Component {
  state = { data: [], options: {},language:this.props.i18n.language };
  constructor(props) {
    super(props);
    const { t } = this.props;
    getData(props)
      .then((records) => {
        this.setState({ ...this.state, records });
        const options = {
          chartArea: {
            top: 55,
            height: '40%' 
         },
          title: t("Systolic blood pressure"),
          vAxis: {
            title: "mmHg",
            ticks: [30,40,50,60,70,80,90,100,110,120,130,140,150,160,170,180,190,200,210,220]

          },
          hAxis: {
            type: "category",
            viewWindowMode: 'pretty', 
            slantedText: true,
            slantedTextAngle:90
          },
          series: {
            0: { color: graphColor.activity },
            1: { lineDashStyle: [4, 4], pointSize: 0, color: graphColor.alert },
          },
          pointSize: 5,
          legend: { position: "top", alignment: "end" },
          interpolateNulls: true,
        };

        records = getSeries_three_records(records);
        records.unshift([
          "X",
          t("Systolic blood pressure"),
          t("Systolic blood pressure alert threshold"),
        ]);

        this.setState({ ...this.state, data: records, options });
      })
      .catch((err) => {
        const records = [
          [
            {
              f: "Date",
              type: "date",
            },
            {
              f: "Line",
              type: "number",
            },
          ],
        ];
        const options = {
          title: t("Systolic blood pressure (No data available)"),
          series: {
            0: {
              color: "transparent",
            },
          },
        };
        this.setState({ ...this.state, data: records, options });
      });
  }
  componentDidUpdate(prevProps) {
    const {t} = this.props;
    if ((prevProps.graphDate !== this.props.graphDate)||(prevProps.i18n.language!==this.state.language)) {
      this.setState({ ...this.state, language: prevProps.i18n.language });  
          getData(this.props)
        .then((records) => {
          this.setState({ ...this.state, records });
          const options = {
            chartArea: {
              top: 55,
              height: '40%' 
           },
            title: t("Systolic blood pressure"),
            vAxis: {
              title: "mmHg",
              ticks: [30,40,50,60,70,80,90,100,110,120,130,140,150,160,170,180,190,200,210,220]
            },
            hAxis: {
              type: "category",
              viewWindowMode: 'pretty', 
              slantedText: true,
              slantedTextAngle:90
            },
            series: {
              0: { color: graphColor.activity },
              1: {
                lineDashStyle: [4, 4],
                pointSize: 0,
                color: graphColor.alert,
              },
            },
            pointSize: 5,
            legend: { position: "top", alignment: "end" },
            interpolateNulls: true,
          };
          records = getSeries_three_records(records);

          records.unshift([
            "X",
            t("Systolic blood pressure"),
            t("Systolic blood pressure alert threshold"),
          ]);
          this.setState({ ...this.state, data: records, options });
        })
        .catch((err) => {
          const records = [
            [
              {
                f: "Date",
                type: "date",
              },
              {
                f: "Line",
                type: "number",
              },
            ],
          ];
          const options = {
            title: t("Systolic blood pressure (No data available)"),
            series: {
              0: {
                color: "transparent",
              },
            },
          };
          this.setState({ ...this.state, data: records, options });
        });
    }
  }

  render() {
    const {t} = this.props;
    if (!this.state.data.length) return <div className="loader" />;
    return (
      <Chart
       width={'1280px'}
       height={'400px'}
        chartType="LineChart"
        loader={<div>{t("Systolic blood pressure")}</div>}
        data={this.state.data}
        options={this.state.options}
        rootProps={{ "data-testid": "3" }}
      />
    );
  }
}


export default withTranslation("common")(SystolicVisualization);
