import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { Entity } from 'utils/services/entity';
import { HepadomEntity } from 'utils/services/hepadom_entity';
import {
    Divider,
    Button
} from '@material-ui/core';
import Form from 'components/Form';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThumbUpAltOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import {refreshPage} from '../../../../../utils/common'
import { withTranslation } from 'react-i18next';

var quecount=0;
class Satisfaction extends React.Component {
    state = { answers: [],variant: "success", disabled: false };
    onChange(options) {
       const { data } = options;
       const answers = JSON.parse(JSON.stringify(this.state.answers));
       const match = answers.find(item => item._id === data.name);
       if (match) match.value = data.value;
       else answers.push({ _id: data.name, value: data.value });
       this.setState({ ...this.state, answers });
    }

    save() {

        const { id,t } = this.props;
        const options = {
            entity: "questionnaire",
            entity_id: id,
            data: {
                answers: this.state.answers
            }
        };
        HepadomEntity.edit(options)
            .then((response) => {
                this.setState({ ...this.state, open: true, variant: 'success', message: t(response.message), disabled: true, loading: false });
                
                refreshPage()
                
            })
            .catch((err) => {
                this.setState({ ...this.state, open: true, variant: 'error', message: t(err.error), loading: false });
            })

    }

    handleClose = () => {
        this.setState({ ...this.state, open: false });
    };
    updateState(answers,quecount) {
        if(quecount==56)
        this.setState({ ...this.state, answers });
    }

    render() {
        const { questionnaires, classes, activated , t } = this.props;
        const answers = JSON.parse(JSON.stringify(this.state.answers));
        let form = [];
        questionnaires.forEach((questionnaire, index) => 
        {
            if (index === 0) return;
            let f = {
                addDivider: true,
                onChange: this.onChange.bind(this),
                fields: []
            };
            questionnaire.questionnaires.forEach((questionnaire) => {

                if (questionnaire.type === "radio") {
                    f.fields.push({
                        name: questionnaire._id,
                        label: <div dangerouslySetInnerHTML={{ __html: questionnaire.value }} />,
                        helpertext:<div dangerouslySetInnerHTML={{ __html: questionnaire.help }} />,
                        type: "radio",
                        align: "horizontally",
                        disabled: (this.state.disabled || activated === false),
                        options: questionnaire.options.map((value) => {
                            return { key: value, value }
                        })
                    });  
                    
                    
                } else if (questionnaire.type === "text") {
                    f.fields.push({
                        name: questionnaire._id,
                        label: questionnaire.value,
                        disabled: (this.state.disabled || activated === false),
                        type: questionnaire.type,
                        align: "horizontally",
                    });                   
                }
                answers.push({ _id: questionnaire._id, value: "" });
                quecount++;
            });           
            form.push(f);
            
        });
        if (activated === false) return (<Grid container spacing={3}>
            <Grid item xs>
                <ThumbUpAltOutlined variant="subtitle2" color="primary" /><Typography variant="subtitle2" color="primary">Déjà répondu</Typography>
            </Grid>
        </Grid>);
        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "left" }}>
                        <h4>{questionnaires[0]}</h4>
                    </Grid>
                </Grid>
                <br />
                <div className="custom-questire-styles">
                {questionnaires.map((questionnaire, index) => {
                    if (index === 0) return <React.Fragment key={index} />;
                    return <React.Fragment key={index}>
                        <Paper variant="outlined" style={{backgroundColor:"#e3f2fd"}}>
                        <div className={classes.description} dangerouslySetInnerHTML={{ __html: questionnaire.description }} />
                        </Paper>
                        {questionnaire.subheading &&
                        <Typography variant="subtitle2" color ="primary"
                            className={classes.subheading}>{questionnaire.subheading}</Typography>
                         }
                        <Form className={classes.formControl} options={form[index - 1]} />
                    </React.Fragment>
                })}
                </div>
                {this.updateState(answers,quecount)}
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    style={{ position: 'absolute', right: '100px' }}
                    key={`top,left`}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={this.state.variant}
                        message={this.state.message}
                    />
                </Snackbar>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.save.bind(this)} disabled={(this.state.disabled || activated === false)}>
                            {this.state.loading ? <CircularProgress
                                size={40}
                                className={classes.buttonProgress} /> : t("Register") }
                        </Button>
                    </Grid>
                </Grid>
                <br />
            </div>
        )
    }
}

const useStyles = (theme) => ({
    root: {
        position: 'relative',
    },
    description: {
        textAlign: "left",
    },
    subheading: {
        padding: theme.spacing(1.5),
        fontSize : '1.1rem',
        display : 'flex',
        flexGrow: 1,
        flexWrap: 'nowrap',
        float: "left"
    },
    formControl: {
        textAlign: "left"
    },
    button: {
        margin: theme.spacing(1),
    },
    divider: {
        marginBottom: "3%"
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
});

export default withStyles(useStyles)(withTranslation('common')(Satisfaction));