import '../../styles/common.css';
import React from "react";
import ReactApexChart from 'react-apexcharts';
import { Entity } from "utils/services/entity";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const graphKeys = ["Spirited","Walk","Relations","Job","Mood","Activity","Bloating", "Change In Taste", "Discomfort When Swallow", "Diarrhoea",
    "Constipation", "Numbness", "Vomiting", "Sadness", "Dry Mouth", "Drowsiness", "Appetite",
    "Memory", "Breath", "Distress", "Sleep", "Nausea", "Fatigue", "Pain" ];

const getYaxisColorArray = () => {
    const colorArr = [];
    for (let i=0; i < graphKeys.length; i++) {
        // add the color only to main questionarie , for computed questionaire leave it to black
        if(i > 5)colorArr.push('#0066cc') 
        else{
            colorArr.push('#000000') 
        };
    }
    return colorArr;
}

const localData = {
    seriesDates: [],
    visit_1: null,
    chartSeries: [],
    chemotheraphy_dates: [],
    defaultColorArray: [],
    colorArray: [],
    yaxisColorArray: getYaxisColorArray()
};

function generateData(records, name) {
    const series = localData.seriesDates.map(item => ({ x: item }));
    for (let j=0; j<records.length; j++) {
        for (let k = 0; k < records[j].answers.length; k++) {
            const answer = records[j].answers[k];
            if (answer.name === name) {
                const createdDate = moment(records[j].created_at).format("DD-MM-YYYY");
                // find matching date in series and assiciate data
                const seriesItem = series.find(item => item.x === createdDate);
                if (seriesItem) seriesItem.y = Number(answer.value);
                break;
            }
        }
    }
    const formattedSeries = getFormattedSeries(series);
    return formattedSeries;
}

function generateScore(records, name) {
    const series = localData.seriesDates.map(item => ({ x: item }));
    for (let j=0; j<records.length; j++) {
        if (!records[j].created_at) {
            continue;
        }
        const createdDate = moment(records[j].created_at).format("DD-MM-YYYY");
        const seriesItem = series.find(item => item.x === createdDate);
        if (seriesItem) {
            seriesItem.y = (records[j].scores ? records[j].scores[name] : undefined);
        }
    }

    const formattedSeries = getFormattedSeries(series);
    return formattedSeries;
}

function getFormattedSeries(series) {
    let formattedSeries = series.map(item => {
        item.y = item.y !== undefined ? item.y : -1;
        return item;
    });
    // if (formattedSeries.length > 21) {
    //     return formattedSeries.slice(formattedSeries.length - 21);
    // }
    return formattedSeries;
}

function setSeriesDates() {
    localData.seriesDates = [];
    localData.defaultColorArray.splice(0, localData.defaultColorArray.length);
    if (!localData.visit_1) {
        return;
    }
    const date = new Date(localData.visit_1);
    date.setDate(date.getDate() - 1);
    for (let i=0; i<77; i++) {
        date.setDate(date.getDate() + 1);
        const formattedDate = moment(date).format("DD-MM-YYYY");
        if (localData.chemotheraphy_dates.find(item => item === formattedDate)) {
            localData.defaultColorArray[i] = 'red';
        }
        localData.seriesDates.push(formattedDate);
    }
}

function setChemotheraphyDate(patient_details) {
    if (!patient_details) {
        return;
    }
    localData.visit_1 = patient_details.theoretical_date_of_visit_1;
    localData.chemotheraphy_dates = [];
    if (patient_details.theoretical_date_of_chemotheraphy_1) {
        localData.chemotheraphy_dates.push(moment(patient_details.theoretical_date_of_chemotheraphy_1).format("DD-MM-YYYY"));
    }
    if (patient_details.theoretical_date_of_chemotheraphy_2) {
        localData.chemotheraphy_dates.push(moment(patient_details.theoretical_date_of_chemotheraphy_2).format("DD-MM-YYYY"));
    }
    if (patient_details.theoretical_date_of_chemotheraphy_3) {
        localData.chemotheraphy_dates.push(moment(patient_details.theoretical_date_of_chemotheraphy_3).format("DD-MM-YYYY"));
    }
    setSeriesDates();
}

class SurveyMDASI extends React.Component {
    
    state = {
        noOfDays: 21,
        options: {
            plotOptions: {
                heatmap: {
                    // shadeIntensity: 0.5,
                    enableShades: false,
                    colorScale: {
                        ranges: [{
                            from: 0,
                            to: 1,
                            name: '0',
                            color: '#0066CC'
                        },
                            {
                                from: 1,
                                to: 2,
                                name: '1',
                                color: '#0080FF'
                            },
                            {
                                from: 2,
                                to: 3,
                                name: '2',
                                color: '#3399FF'
                            },
                            {
                                from: 3,
                                to: 4,
                                name: '3',
                                color: '#66B2FF'
                            },
                            {
                                from: 4,
                                to: 5,
                                name: '4',
                                color: '#99CCFF'
                            },
                            {
                                from: 5,
                                to: 6,
                                name: '5',
                                color: '#CCE5FF'
                            },
                            {
                                from: 6,
                                to: 7,
                                name: '6',
                                color: '#FFCCE5'
                            },
                            {
                                from: 7,
                                to: 8,
                                name: '7',
                                color: '#FF99CC'
                            },
                            {
                                from: 8,
                                to: 9,
                                name: '8',
                                color: '#FF66B2'
                            },
                            {
                                from: 9,
                                to: 10,
                                name: '9',
                                color: '#FF3399'
                            },
                            {
                                from: 10,
                                to: 11,
                                name: '10',
                                color: '#FF007F'
                            },
                            {
                                from: '-1',
                                to: '-1',
                                name: 'Non renseigné -1',
                                color: '#FFFFFF'
                            },
                        ]
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            // colors: ["#FF0000"],

            title: {
                text: 'Scores questionnaire MDASI',
                align: 'center',
                style: {
                    fontSize: '20px'
                }
            },
            xaxis: {
                title:{
                    text: "Dates de présentation du questionnaire"
                },
                labels: {
                    style: {
                        colors: localData.colorArray
                    }
                }
            },
            yaxis: {
                labels: {
                    maxWidth: 160,
                    style: {
                        colors: localData.yaxisColorArray
                    }
                }
            }
        },
        series: []
    };

    getQuestionnaires(patient_id) {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: "questionnaire",
                data: {
                    patient_id,
                    name: "MDASI"
                }
            };
            Entity.call("getQuestionnaire", options)
                .then(response=> {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch(err=> {
                    reject(err)
                })
        })
    }

    constructor(props) {
        super(props);
        const {t} = this.props;

        setChemotheraphyDate(props.patient_details);
        this.getQuestionnaires(props.patient_details._id)
            .then((data)=> {
                const records = data.sort((a, b)=> new Date(a.created_at) - new Date(b.created_at));
                const series = graphKeys.map(key => {
                    return {
                        name: t(key),
                        data: generateData(records, key)
                    }
                });
                series.push({
                    name: t("Activity Interference"),
                    data: generateScore(records, 'Interférence avec activité physique')
                });
                series.push({
                    name: t("Affective Interference"),
                    data: generateScore(records, 'Interférence avec vie affective')
                });
                
                this.setState({ ...this.state, series, loading: false });
            })
            .catch((err)=> {
                this.setState({ ...this.state, loading: false });
            });
    }

    updateChartSeries(series) {
        const noOfDays = this.state.noOfDays;
        const chartSeries = [];
        if (series && series.length) {
            const todayDate = moment().format('DD-MM-YYYY');
            const todayIndex = localData.seriesDates.findIndex(item => item === todayDate);
            let startIndex = 0;
            let endIndex = noOfDays;
            if (noOfDays === 21) {
                if (todayIndex < 21) { // return first 21 results
                    endIndex = 21;
                } else { // return last 21 results
                    startIndex = todayIndex - 20;
                    endIndex = todayIndex + 1;
                }
            }
            for (let i=0; i<series.length; i++) {
                chartSeries.push({
                    name: series[i].name,
                    data: series[i].data.slice(startIndex, endIndex)
                });
            }
            // update color array based on range
            localData.colorArray.splice(0, localData.colorArray.length, ...localData.defaultColorArray.slice(startIndex, endIndex));
        }
        return chartSeries;
    }

    updateChartRange = (days) => {
        if (days !== this.state.noOfDays) {
            this.setState({ noOfDays: days });
        }
    }

    renderRangeOptions = () => {
        const options = [21, 52,77];
        return (
            <ButtonGroup style={{ paddingBottom: '10px' }} color="primary" aria-label="outlined primary button group">
                {options.map(item => {
                    const customStyle = item === this.state.noOfDays ? { fontWeight: 'bold' } : null;
                    return (
                        <Button key={item} style={customStyle} onClick={() => this.updateChartRange(item)}>Scores sur {item} jours</Button>
                    )
                })}
            </ButtonGroup>
        );
    }

    render() {
        if (!this.state.series.length && this.state.loading) return <div className="loader"/>;
        const chartSeries = this.updateChartSeries(this.state.series);
        if (!chartSeries.length) {
            return null;
        }
        return (
            <div id="chart">
                {this.renderRangeOptions()}
                <ReactApexChart options={this.state.options} series={chartSeries} type="heatmap" height="660"/>
            </div>


        );
    }

}

export default (withTranslation('common')(SurveyMDASI));
