import React from "react";
import {MenuItem,Tooltip,FormControl,Button,Menu} from "@material-ui/core";
import {HepadomEntity} from "utils/services/hepadom_entity"
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core";
import LanguageIcon from '@material-ui/icons/Language';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: 120
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    focused: {
        color: "#fff"
      },
});

class LanguageSelector extends React.Component {
    getLanguage(user_id) {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: "users",
                data: {
                    _id:user_id
                }
            };
            HepadomEntity.call("get_user_language", options)
                .then(response=> {
                    if (response.status !== 200) return this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator", loading: false });
                    const data = response.data;
                    return resolve(data);
                })
                .catch(err=> {
                    this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator", loading: false })
                })
        })
    }

    
    handleClick = event => this.setState({ anchorEl: event.currentTarget })
    handleClose = () => this.setState({ anchorEl: null })


    constructor(props) {
        super(props);
        this.state = {
            language:((this.props.i18n.language==='en'||this.props.i18n.language==='fr') ? this.props.i18n.language :'en'),
        }
        this.getLanguage(this.props.user._id)
        .then((user)=> {
            if(!user.language)
            user.language='en';
            this.props.i18n.changeLanguage(user.language)
            this.setState({language:user.language });
        })
    }

    componentDidMount() {

    }
    edit_language(newLang){
        return new Promise((resolve, reject) => {
            const { t,user } = this.props;   
            const options = {
                entity: "users",
                data: {
                    language: newLang,
                    _id:user._id
                }
            };
            HepadomEntity.call("change_language", options)
            .then((response) => {
                resolve({message: t("Saved Successfully")});
            })
            .catch((err) => {
                reject({message: t(err.error)});
               })
           });
    }

    onLanguageHandle = (value) => {
        this.handleClose();
        const { t } = this.props;
        var msg = t("Are you sure you want to change language?");
        var x = window.confirm(msg);
        if (!x) return false;    
        let newLang = value;
        this.setState({language:newLang });
        this.props.i18n.changeLanguage(newLang);
        this.edit_language(newLang);
      }
    render() {
        const {t,classes,user} = this.props;
        const { anchorEl } = this.state;

        return(
            <FormControl className={classes.formControl} > 
            <Tooltip title= {t("Select language")} aria-label="change language">   
                <Button  className={classes.focused}  aria-controls="customized-menu" aria-haspopup="true" onClick={this.handleClick} startIcon={<LanguageIcon/>} endIcon={<ArrowDropDownIcon/>}>       
               {this.state.language=="en"?"English":"Français"}
               </Button>
               </Tooltip>
               <StyledMenu
                   id="select_language"
                   anchorEl={anchorEl}
                   keepMounted
                   open={Boolean(anchorEl)}
                   onClose={this.handleClose}
                   
               >
               <MenuItem  onClick={value => this.onLanguageHandle('en')} >{"English"}</MenuItem>
               <MenuItem  onClick={value => this.onLanguageHandle('fr')} >{"Français"}</MenuItem>
               </StyledMenu>
            </FormControl>
        )
    }
}
export const mapStateToProps = state => () => {
    return ({
        user: state.user,
    });
};

export default connect(mapStateToProps)(withStyles(useStyles)(withTranslation('common')(LanguageSelector)));
