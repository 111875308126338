import React from "react";
import Dashboard from "components/Dashboard";
import Table from "../../../components/Table/index.web";
import { withTranslation } from 'react-i18next';
function filter(t) {
    return (column)=> {
        if (column === "referent") return t("Referent Nurse");
        else return t("Co-Referent Nurse");
    }
}

class Doctors extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <Dashboard title={t("IPA")} disablePaper={ true }>
                <Table entity="nurses"
                       fields={[
                           { key: "first_name", value: "First Name"},
                           { key: "last_name", value: "Last Name"},
                           { key: "user_name", value: "User Name"},
                           { key: "phone_number", value: "Phone Number"},
                           
                       ]}
                       addShow={true}
                       clearShow={true}
                       //actions={["delete"]}
                       addLink={"/hepadom/nurses/_add"}
                />
            </Dashboard>
        )
    }
}

export default withTranslation('common')(Doctors);
