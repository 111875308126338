import React, { Component } from 'react';
import multidom_logo from '../../../../images/multidom_logo.jpg';
import hepadom_logo from '../../../../images/hepadom_logo.jpg';
import configData from "../../../../common.json";

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

import {connect} from 'react-redux';

// Material components
import {
  Divider,
  Typography
} from '@material-ui/core';

import ListItem from "./listItem";
import HepadomListItem from "./hepadom_listItem";

// Component styles
import styles from './styles';
import { withTranslation } from 'react-i18next';

function jsUcfirst(string) {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function jsUcfirstandSplit(string) {
 let words = string.split('_');
 if(window.location.pathname.includes(configData.clinical_study.HEPADOM_CLINICAL_STUDY)){
  return words[0].charAt(0).toUpperCase() + words[0].slice(1) + ' ' + words[1].charAt(0).toUpperCase() + words[1].slice(1)
 }else{
   return string.charAt(0).toUpperCase() + string.substr(1,string.length);
 }
}

class Sidebar extends Component {
  render() {
    const { t, classes, className, user } = this.props;
    const rootClassName = classNames(classes.root, className);
    const logoContainerStyles = {padding: '10px 30% 0', height: '48px'};
    const multidomLogoContainerStyles = {padding: '0px 20% 10px', position: 'absolute', bottom: 0};
    const logo_name=window.location.pathname.includes(configData.clinical_study.HEPADOM_CLINICAL_STUDY) ? hepadom_logo  :multidom_logo;
    return (
      <nav className={rootClassName}>
        <div style={logoContainerStyles}>
          <img src="https://www.altran.com/as-content/themes/altran/images/logo.svg" alt="altran-logo" />
        </div>
        <div style={multidomLogoContainerStyles}>
          <img style={{width: '100%'}} src={logo_name} alt="multidom-logo" />
        </div>
        <Divider style={{marign: 0}} />
        <div className={classes.profile}>
          <Typography
            className={classes.nameText}
            variant="h6"
          >
            {jsUcfirst(user.first_name)} {jsUcfirst(user.last_name)}
          </Typography>
          <Typography
            className={classes.bioText}
            variant="caption"
          >
          { user.role === "hepadom_admin"? t("Investigating On-Call Surgeon") : user.role === "admin" ? t("Investigating Doctor") : user.role === "hepadom_nurse" ? t("Hepadom IPA") : user.role === "hepadom_doctor" ? t("Hepadom On-Call Surgeon"): user.role === "nurse" ? t("Nurse"): user.role === "doctor" ? t("Doctor")  : t(jsUcfirstandSplit(user.role))}
          </Typography>
        </div>
        <Divider className={classes.profileDivider} />
        {/* <ListItem/> */}
        {user.role.includes("hepadom") ? <HepadomListItem /> :<ListItem />}
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export const mapStateToProps = state => () => {
    return ({
        user: state.user,
    });
};

export default connect(mapStateToProps)(withStyles(styles)(withTranslation('common')(Sidebar)));
