import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import { getSeries,graphColor } from '../../utils/common'
function getData(props) {
    return new Promise((resolve, reject)=> {
        const options = {
            entity: "sensors",
            query: {
                name: "mmmtemperature",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        Entity.call("graph", options)
            .then(res=> {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err=> {
                reject();
            })
    })
}

class MMMTemperature extends React.Component {
    state = {data: [], options: {}};
    constructor(props) {
        super(props);
        getData(props)
            .then(records=> {
                const options = {
                    title: 'Température thoracique quotidienne',
                    vAxis: {
                        title: '°C'
                    },
                    hAxis: {
                        type: "category"
                    },
                    series: {
                        0: { color: graphColor.temperature },
                        1: { color: graphColor.maxtemp },
                        2: { color: graphColor.mintemp},
                        3: {color: graphColor.temperature, lineDashStyle: [4, 4], pointSize: 0 }
                    },
                    pointSize: 5,
                    legend: { position: 'top', alignment: 'end' },
                  //  colors: [graphColor.temperature,graphColor.temperature,graphColor.temperature,graphColor.temperature],
                    interpolateNulls:false
                };
                /*records = records.map((record)=> {
                    record[0] = new Date(record[0]);
                    return record;
                });*/
                records = getSeries(records);
                records.unshift(["X", "médiane", "maximale", "minimale", "Température de référence"]);
                this.setState({...this.state, data: records, options });
            })
            .catch(err=> {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title: 'Température thoracique quotidienne (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
    }
     componentDidUpdate(prevProps){
         if(prevProps.graphDate !== this.props.graphDate){
            getData(this.props)
            .then(records=> {
                const options = {
                    title: 'Température thoracique quotidienne',
                    vAxis: {
                        title: '°C'
                    },
                    hAxis: {
                        type: "category"
                    },
                    series: {
                        0: { color: graphColor.temperature },
                        1: { color: graphColor.maxtemp },
                        2: { color: graphColor.mintemp},
                        3: {color: graphColor.temperature, lineDashStyle: [4, 4], pointSize: 0 }
                    },
                    pointSize: 5,
                    legend: { position: 'top', alignment: 'end' },
                  //  colors: [graphColor.temperature,graphColor.temperature,graphColor.temperature,graphColor.temperature],
                    interpolateNulls:false
                };
                /*records = records.map((record)=> {
                    record[0] = new Date(record[0]);
                    return record;
                });*/
                records = getSeries(records);
                records.unshift(["X", "médiane", "maximale", "minimale", "Température de référence"]);
                this.setState({...this.state, data: records, options });
            })
            .catch(err=> {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title: 'Température thoracique quotidienne (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
         }

     }
        
    render() {
        if (!this.state.data.length) return <div className="loader"/>;
        return(
            <Chart
                chartType="LineChart"
                loader={<div>Température thoracique quotidienne</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />
        )
    }
}

export  default MMMTemperature;
