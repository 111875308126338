import React from "react";
import Cam from "react-webcam";
import { Button } from "@material-ui/core";
import { HepadomEntity } from "utils/services/hepadom_entity";
import { refreshPage } from "../../../../../utils/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";

const ScarPictures = ({ user, questionnaires, id, activated, i18n }) => {
  //init Pixels for Desktop
  const desktopPixel = {
    height: "480px",
    width: "720px",
  };

  //init Pixels for Tablet Users
  const tabletPixel = {
    height: "400px",
    width: "100%",
  };

  //initial state reference
  const webcamRef = React.useRef(null);

  //state that holds the image data
  const [open, setOpen] = React.useState(false);
  const [variant, setVariant] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  //state that holds the camera init mode
  const [videoConstraints, setVideoConstraints] = React.useState({
    facingMode: "user",
  });

  //function to capture the Screenshot of the image
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  //function to change the camera mode
  const switchCamera = () => {
    const newConstraint =
      videoConstraints && videoConstraints.facingMode === "user"
        ? { exact: "environment" }
        : "user";
    setVideoConstraints({
      facingMode: newConstraint,
    });
  };

  //Screensize Listner
  function useWindowSize() {
    const [size, setSize] = React.useState([0, 0]);
    React.useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  //holding Screensize into a ref.
  const [width] = useWindowSize();

  //converts base64 into blob
  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  //upload Function
  const upload = () => {
    setLoading(true);

    //Convertion of The Base64 Stream
    const contentType = 'image/jpeg';
    const img = imgSrc.split(',')[1];
    const blob = b64toBlob(img, contentType);

    //formdata creation
    let formData = new FormData();
    formData.append("scar_image", blob, `${user._id}.jpg`);
    const URL = `/api/1/hepadom/entity/upload/scar_pictures/_/uploadScarPicture`;
    const options = {
      method: "POST",
    };
    options.body = formData;

    //API Call
    fetch(URL, options)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          const questionnaireOptions = {
            entity: "questionnaire",
            entity_id: id,
            data: {
              answers: [{ value: response.data.blobName }],
            },
          };

          HepadomEntity.edit(questionnaireOptions)
            .then(() => {
              setOpen(true);
              setVariant("success");
              setMessage("Uploaded Successfully");
              setLoading(false);

              refreshPage();
            })
            .catch((err) => {
              setOpen(true);
              setVariant("error");
              setMessage("Error while Saving");
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        setOpen(true);
        setVariant("error");
        setMessage("Error while Uploading");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return loading ? (
    <CircularProgress
      size={40}
      style={{
        color: "green",
      }}
    />
  ) : questionnaires.length > 0 ? (
    questionnaires.map((item,index) => {
      return (
        <div className="webcam-body" key={index}>
          <div className="header">
            <div className="headeing"> {item.name} </div>
            <div className="sub-headeing">{item.description}. </div>
          </div>

          <div className="cam-body">
            {imgSrc ? (
              <>
                <img src={imgSrc} alt="" />
                <div className="captuire-btn">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => upload()}
                  >
                    {item.upload}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setImgSrc(null)}
                  >
                    {item.recapture}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Cam
                  height={
                    width <= 868 ? tabletPixel.height : desktopPixel.height
                  }
                  width={width <= 868 ? tabletPixel.width : desktopPixel.width}
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
                <div className="captuire-btn">
                  <Button variant="contained" color="primary" onClick={capture}>
                    {item.button}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => switchCamera()}
                  >
                    {item.switch}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    })
  ) : (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      style={{ position: "absolute", right: "100px" }}
      key={`top,left`}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContentWrapper
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
};

export default ScarPictures;
