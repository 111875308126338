import '../../styles/common.css';
import React from "react";
import { Chart } from "react-google-charts";
import ReactApexChart from 'react-apexcharts'
import { withTranslation } from 'react-i18next';
import { Entity } from "utils/services/entity";
import moment from 'moment';
import { any } from 'nconf';


function getQuestionnaires(patient_id) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "questionnaire",
            data: {
                patient_id,
                name: "Pittsburgh"
            }
        };
        Entity.call("getQuestionnaire", options)
            .then(response => {
                const records = response.data.records;
                resolve(records);
            })
            .catch(err => {
                reject(err)
            })
    })
}

class SurveyPITTSBURGH extends React.Component {

    state = { data: [], options: {}, isDataAvailable: false, loader: true };


    constructor(props) {
        super(props);
        getQuestionnaires(props.patient_details._id)
            .then((data) => {
                let series = {
                    name: "  0 : aucune difficulté " + ' / ' +
                        "21 : difficultés majeures",
                    data: []
                }
                let options = {
                    chart: {
                        height: 250,
                        type: 'bar',
                    },
                    title: {
                        text: 'Score questionnaire Pittsburgh ',
                        align: 'center',
                        style: {
                            fontSize: '20px'
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                        },
                    },

                    legend: {
                        show: true,
                        showForSingleSeries: true,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: 'top',
                        horizontalAlign: 'center',
                        floating: false,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial',
                        fontWeight: 400,
                        formatter: undefined,
                        inverseOrder: false,
                        width: undefined,
                        height: undefined,
                        tooltipHoverFormatter: undefined,
                        offsetX: 0,
                        offsetY: 0,
                        labels: {
                            colors: undefined,
                            useSeriesColors: false
                        },
                        markers: {
                            width: 12,
                            height: 12,
                            strokeWidth: 0,
                            strokeColor: '#fff',
                            fillColors: undefined,
                            radius: 12,
                            customHTML: undefined,
                            onClick: undefined,
                            offsetX: 0,
                            offsetY: 0
                        },
                        itemMargin: {
                            horizontal: 5,
                            vertical: 0
                        },


                    },
                    xaxis: {
                        title:{
                            text: "Dates de réponse au questionnaire"
                        },
                        categories: [],
                    },
                    // chartArea: { width: '50%' },
                    yaxis: {
                        title: {
                            text: 'Score global',
                        },
                        min: 0,
                        max: 21,
                    },
                };

                const records = data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  
                for (let i = 0; i < records.length; i++) {
                    if (records[i].answers.length > 0) {
                        series.data.push(records[i].scores["Score global au PSQI"])
                        options.xaxis.categories.push(moment(records[i].answered_at).format("DD-MM-YYYY"));
                        this.state.isDataAvailable = true;
                    }

                }
                this.state.data.push(series);
                this.setState({ ...this.state, data: this.state.data, options: options });


            })
            .catch((err) => {
                this.setState({ loading: false });
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    },]
                ];
                const options = {
                    title: "some error occurred",
                };
                this.setState({ data: records, options });
            });
    }
    componentDidMount() {
        this.setState({ loader: false })
    }

    render() {
        return (
            this.state.loader ? <div className="loader" /> : <div>
                {(() => {
                    if (this.state.isDataAvailable) {
                        return (<div >

                            <div id="chart">

                                <ReactApexChart
                                    options={this.state.options}
                                    series={this.state.data}
                                    type="bar" />

                            </div>
                        </div>

                        )
                    } else {
                        return (
                            <div>
                                <h2 style={{
                                    align: 'center',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                    fontFamily: `"Helvetica Neue", "Arial", "sans-serif"`,
                                }}>Score questionnaire Pittsburgh  </h2>
                                <div > Les questions n'ont pas reçu de réponse </div>

                            </div>


                        )
                    }
                })()}
            </div>


        );

    }
};
export default (withTranslation('common')(SurveyPITTSBURGH));
