import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import { graphColor } from '../../utils/common';
import moment from 'moment';

function getData(props) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "sensors",
            query: {
                name: "activityPhase",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        Entity.call("graph", options)
            .then(res => {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err => {
                reject();
            })
    })
}

class ActivityPhase extends React.Component {

    state = { data: [], options: {} };
    constructor(props) {
        super(props);

        getData(props)
            .then(records => {
                const options = {
                    title: "Acrophase du rythme d'activité-repos",
                    vAxis: { title: 'heure (décimale)'},
                    hAxis: {
                        type: 'category'
                    },
                    legend: { position: 'top', alignment: 'end' },
                    series: {
                        0: { color: graphColor.activity},
                        1: {color:graphColor.alert, lineDashStyle: [5, 5,5],pointSize:0,lineWidth: 1},
                        2: {color:graphColor.alert, lineDashStyle: [5,5,5],pointSize:0,lineWidth: 1 },
                    },
                    pointSize: 5,
                    interpolateNulls: false
                };

                records = records.map((record) => {
                    record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm')
                    return record;
                });
                // records = getSeries(records);
             //   records.unshift(["Time", "Phase du rythme d'activité-repos"]);
             records.unshift(["Time", "Acrophase du rythme d'activité-repos","Intervalle normal",""]);

                this.setState({...this.state, data: records, options });
               
            })
            .catch(err => {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    },]
                ];
                const options = {
                    title: "Acrophase du rythme d'activité-repos (Aucune donnée disponible)",
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({ ...this.state, data: records, options });
            })
    }
 componentDidUpdate(prevProps){
     if(prevProps.graphDate !== this.props.graphDate){
        getData(this.props)
        .then(records=> {
            const options = {
                title: "Acrophase du rythme d'activité-repos",
                vAxis: { title: 'heure (décimale)' },
                hAxis: {
                    type: 'category'
                },
                legend: { position: 'top', alignment: 'end' },
                series: {
                    0: { color: graphColor.activity},
                    1: {color:graphColor.alert, lineDashStyle: [5, 5, 5],pointSize:0,lineWidth: 1},
                    2: {color:graphColor.alert, lineDashStyle: [5,5, 5],pointSize:0,lineWidth: 1 },
                },
                pointSize: 5,
                interpolateNulls:false
            };

            records = records.map((record)=> {
                record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm')
                return record;
            });
            // records = getSeries(records);
           // records.unshift(["Time", "Phase du rythme d'activité-repos"]);
           records.unshift(["Time", "Acrophase du rythme d'activité-repos","Intervalle normal",""]);

            this.setState({...this.state, data: records, options });
        })
        .catch(err=> {
            const records = [
                [{
                    f: 'Date',
                    type: 'date'
                }, {
                    f: 'Line',
                    type: 'number'
                }, ]
            ];
            const options = {
                title: "Acrophase du rythme d'activité-repos (Aucune donnée disponible)",
                series: {
                    0: {
                        color: 'transparent'
                    }
                }
            };
            this.setState({...this.state, data: records, options });
        })
     }

    }
    render() {
        if (!this.state.data.length) return <div className="loader" />;
        return (
            <Chart
                chartType="LineChart"
                loader={<div>Phase du rythme d'activité-repos</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />
        )
    }

}


export default ActivityPhase;
