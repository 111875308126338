export default theme => ({
    root: {
      width: '350px',
      maxWidth: '100%'
    },
    content: {},
    listItem: {
      cursor: 'pointer',
      color: "#000",
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: "#000",
      }
    },
    listItemIcon: {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      padding: '15px',
      borderRadius: '50%',
      marginRight: theme.spacing(2)
    },
    listItemTextSecondary: {
      marignTop: '4px',
      color: theme.palette.text.secondary
    },
    arrowForward: {
      color: theme.palette.text.secondary,
      height: '16px',
      width: '16px'
    },
    footer: {
      paddingBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center'
    },
    empty: {
      textAlign: 'center',
      padding: theme.spacing(3)
    },
    emptyImageWrapper: {
      marginBottom: theme.spacing(3)
    },
    emptyImage: {
      width: '240px',
      maxWidth: '100%',
      height: 'auto'
    }
  });