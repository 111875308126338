
import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import { fr } from "./locales";

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  
  resources: {
    fr: {
    common: fr['fr'],
  },
  en: {
    common: fr['en'],
  }
},

    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: "en",
    /* debugger For Development environment */
    ns: ["common"],
    defaultNS: "common",
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    interpolation: {
        escapeValue: false
    },
    react: {
        wait: true,
        useSuspense: true
    }
})

export default i18n;