import React, { useState, useEffect } from "react";
import AdvancedSelect from "react-select";
import { Entity } from "utils/services/entity";
import { HepadomEntity } from "utils/services/hepadom_entity";
import { withTranslation } from "react-i18next";

const AdvancedMultiSelect = ({
  t,
  field,
  onChange,
  value,
  disabled,
  error,
}) => {
  const [options, setOptions] = useState([]);
  const SelectedEntity = window.location.pathname.includes("hepadom")
    ? HepadomEntity
    : Entity;

  //get Data Method
  const getData = (options) => {
    return new Promise((resolve, reject) => {
      SelectedEntity.list(options)
        .then((response) => {
          const records = response.data.records;
          resolve(records);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //sorting
  function sortOn(property) {
    return function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1;
      } else if (a[property].toLowerCase()> b[property].toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    };
  }

  //when component loads
  useEffect(() => {
    if (
      field.resolve &&
      typeof field.resolve === "object" &&
      Object.keys(field.resolve).length
    ) {
      const { entity, fields, filters } = field.resolve;
      const options = {
        entity,
        query: {
          fields,
          filters,
        },
      };
      getData(options)
        .then((records) => {
          const sortedRecord = records.sort(sortOn(field.resolve.key));
          const dataFromDB = sortedRecord.map((record) => {
            return {
              key: record[field.resolve.key],
              value: record[field.resolve.value],
              label: record[field.resolve.key],
            };
          });
          setOptions(dataFromDB);
        })
        .catch((err) => {
          // do nothing
        });
    } else if (field && field.options) {
      setOptions(field.options);
    }
  }, []);

  if (!value) value = [];

  return (
    <AdvancedSelect
      isMulti
      name="colors"
      options={options}
      className={`basic-multi-select ${error ? " required" : ""}`}
      classNamePrefix="select"
      closeMenuOnSelect={false}
      placeholder={t(`Select ${field.label}`)}
      name={field.name}
      // value={value}
      onChange={(e) =>
        onChange({
          target: {
            name: field.name,
            value: e
          },
          type: field.type
        })
      }
      disabled={disabled}
      autoFocus={field.autoFocus}
    />
  );
};

export default withTranslation("common")(AdvancedMultiSelect);
