import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import { HepadomEntity } from 'utils/services/hepadom_entity';
import { getSeries,graphColor } from '../../utils/common'
import { withTranslation } from 'react-i18next';
import configData from "../../common.json";

//getting weight variation data by calling entity grpah and passing
//graph name,patient inclusion id,patient id and graph date
function getData(props,SelectedEntity) {
    return new Promise((resolve, reject)=> {
        const options = {
            entity: "sensors",
            query: {
                name: "weight_variation_visualization",
                inclusion_id: props.inclusion_id,
                patient_id: props.patient_id,
                graphDate: props.graphDate
            }
        };
        SelectedEntity.call("graph", options)
            .then(res=> {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err=> {
                reject();
            })
    })
}

class Weight extends React.Component {

    state = { 
        data: [],
        options: {},
        SelectedEntity:window.location.pathname.includes(configData.clinical_study.HEPADOM_CLINICAL_STUDY) ? HepadomEntity : Entity,
        language:this.props.i18n.language
    };
    constructor(props) {
        super(props);
        const {t}=this.props;
        getData(props,this.state.SelectedEntity)
        .then(records=> {
            this.setState({ ...this.state, records });
                const options = {
                    title: t('Weight Variation'),
                    vAxis: {
                        title: '%',
                    },
                    hAxis: {
                        type: 'category',
                        
                    },
                    series: {
                        0: { color: graphColor.weight },
                        1: { lineDashStyle: [4, 4], pointSize: 0,color:graphColor.alert },
                        2: { lineDashStyle: [4, 4], pointSize: 0,color:graphColor.alert  }
                    }, 
                    pointSize: 5,
                    legend: { position: 'top', alignment: 'end' },
                    interpolateNulls: true
                };
 
                records = getSeries(records);
               records.unshift(["X", t("Weight Variation"), t("Weight variation alert threshold"),`- `+t("Weight variation alert threshold")]);
                this.setState({...this.state, data: records, options });
            })
            .catch(err=> {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title: t('Weight Variation (No data available)'),
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
    }
    componentDidUpdate(prevProps){
        const {t}=this.props;
        if ((prevProps.graphDate !== this.props.graphDate)||(prevProps.i18n.language!==this.state.language)) {
            this.setState({ ...this.state, language: prevProps.i18n.language });
            getData(this.props,this.state.SelectedEntity)
            .then(records=> {

                this.setState({ ...this.state, records });
                const options = {
                    title: t('Weight Variation'),
                    vAxis: {
                        title: '%',
                    },
                    hAxis: {
                        type: 'category'
                    },
                    series: {
                        0: { color: graphColor.weight },
                        1: { lineDashStyle: [4, 4], pointSize: 0,color:graphColor.alert },
                        2: { lineDashStyle: [4, 4], pointSize: 0,color:graphColor.alert  }
                    }, 
                    pointSize: 5,
                    legend: { position: 'top', alignment: 'end' },
                    interpolateNulls: true
                };

                records = getSeries(records);
                records.unshift(["X", t("Weight Variation"), t("Weight variation alert threshold"),`- `+t("Weight variation alert threshold")]);
                this.setState({...this.state, data: records, options });
            })
            .catch(err=> {

                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title:  t('Weight Variation (No data available)'),
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
        }

    }

    render() {
        const {t}=this.props;
        if (!this.state.data.length) return <div className="loader"/>;
        return(

           this.state.SelectedEntity === configData.clinical_study.HEPADOM_CLINICAL_STUDY ?   <Chart
                width={'1280px'}
                chartType="LineChart"
                loader={<div>{t("Weight Variation")}</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />:   <Chart
            chartType="LineChart"
            loader={<div>{t("Weight Variation")}</div>}
            data={this.state.data}
            options={this.state.options}
            rootProps={{ 'data-testid': '3' }}
        />

          
        )
    }

}


export default (withTranslation('common')(Weight));
