import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import { getSeries,graphColor } from '../../utils/common'

function getData(props) {
    return new Promise((resolve, reject)=> {
        const options = {
            entity: "sensors",
            query: {
                name: "center_rest_clock",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        Entity.call("graph", options)
            .then(res=> {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err=> {
                reject();
            })
    })
}

class Weight extends React.Component {

    state = {data: [], options: {}};
    constructor(props) {
        super(props);

        getData(props)
            .then(records=> {
                this.setState({ ...this.state, records });
                const options = {
                    title: 'Horaire central de la phase de repos',
                    vAxis: {
                        title: 'heure (décimale)',
                    },
                    hAxis: {
                        type: 'category'
                    },
                    colors:[graphColor.activity],
                    pointSize: 5,
                    legend: "none",
                    interpolateNulls:false
                };

                /*records = records.map((record)=> {
                    record[0] = new Date(record[0]);
                    return record;
                });*/

                records = getSeries(records);

                records.unshift(["X", "Point central de la phase de repos"]);
                this.setState({...this.state, data: records, options });
            })
            .catch(err=> {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title: 'Horaire central de la phase de repos (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
    }

    componentDidUpdate(prevProps){
        if(prevProps.graphDate !== this.props.graphDate){
            getData(this.props)
            .then(records=> {
                this.setState({ ...this.state, records });
                const options = {
                    title: 'Horaire central de la phase de repos',
                    vAxis: {
                        title: 'heure (décimale)',
                    },
                    hAxis: {
                        type: 'category'
                    },
                    colors:[graphColor.activity],
                    pointSize: 5,
                    legend: "none",
                    interpolateNulls:false
                };

                /*records = records.map((record)=> {
                    record[0] = new Date(record[0]);
                    return record;
                });*/

                records = getSeries(records);

                records.unshift(["X", "Point central de la phase de repos"]);
                this.setState({...this.state, data: records, options });
            })
            .catch(err=> {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title: 'Horaire central de la phase de repos (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
        }

    }

    render() {
        if (!this.state.data.length) return <div className="loader"/>;
        return(
            <Chart
                chartType="LineChart"
                loader={<div>Point central de la phase de repos</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />
        )
    }

}


export default Weight;
