import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dashboard from 'components/Dashboard';
import MedicalData from '../MedicalData';
import Survey from "../Survey";
import Protocol from "../Protocol";
import AlertThreshold from "../AlertThreshold";
import ReferenceIndicator from "../ReferenceIndicator";
import Reports from "../Reports";
import AlertFollowUp from "../AlertFollowUp";
import { withStyles } from "@material-ui/core";
import {HepadomEntity} from "utils/services/hepadom_entity"
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import { withTranslation } from 'react-i18next';
import ScarPicturesPatientView from '../ScarPicturesPatientView/index.web';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = (theme)=> ({
    root: {
        backgroundColor: theme.palette.background.paper
    },
    button: {
        float: "right",
        marginBottom: "1%"
    }
});


class HepadomPatientView extends React.Component {

    constructor(props) {
        super(props);
        this.state = { value: 0, loader: false };
    }

    componentDidMount() {
        const options = {
            entity: "patients",
            entity_id: this.props.match.params.id
        };
        HepadomEntity.get(options)
            .then(response=> {
                if (response.status !== 200) {
                    
                    return this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator"});
                }
                const data = response.data;
                this.setState({ ...this.state, patient_details: data });
            })
            .catch(err=> {
                console.log("Error while get catch")
                this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator"})
            })
    }

    handleChange = (event, newValue)=> {
        this.setState({ ...this.state, value: newValue });
    };

    handleChangeIndex = (event, newValue)=> {
        this.setState({ ...this.state, value: newValue });
    };

    render() {
        const questab = {
            overflowY:"scroll",
            height:"80vh"
        };
        const {t, classes, user } = this.props;
        const inclusionId = (this.state.patient_details || {}).inclusion_id;
        if (this.state.error) {
            return (
                <Dashboard title={t("Patient Details (inclusion number ")} inclusionId = {inclusionId}>
                    <Snackbar 
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={true}
                        onClose={this.handleClose}
                    >
                        <SnackbarContentWrapper 
                            onClose={this.handleClose}
                            variant={"error"}
                            message={this.state.error}
                        />
                    </Snackbar>
                </Dashboard>
            );
        }

        if (!this.state.patient_details) {
            return (
                <Dashboard title={t("Patient Details (inclusion number ")} inclusionId = {inclusionId}>
                    <div className="loader"/>
                </Dashboard>
            );
        }
        const tabs = [
            {
                label: t("Medical"),
                component: <MedicalData patient_details={this.state.patient_details} i18n={this.props.i18n}/>
            },
            {
                label: t("Questionnaire"),
                component: <Survey patient_details={this.state.patient_details} i18n={this.props.i18n}/>
            },
            {
                label: t("Protocol Followup"),
                component: <Protocol patient_details={this.state.patient_details} i18n={this.props.i18n}/>
            },
            {
                label: t("Download"),
                 component: <Reports patient_details={this.state.patient_details}/>
            },
            {
                label: t("Alert Follow Up"),
                component: <AlertFollowUp patient_details={this.state.patient_details} i18n={this.props.i18n}/>
            }
        ];
        if (user.role === "hepadom_admin") {
            tabs.push({
                label: t("Alert Threshold"),
                component: <AlertThreshold user={user} patient_details={this.state.patient_details} i18n={this.props.i18n}/>
            })
        }

        if (user.role === "hepadom_admin" || user.role === "hepadom_doctor") {
            tabs.push({
                label: t("Reference Values"),
                component: <ReferenceIndicator user={user} patient_details={this.state.patient_details} i18n={this.props.i18n}/>
            })
        }
        if (user.role === "hepadom_doctor" || user.role === "hepadom_nurse" || user.role === "hepadom_admin") {
            tabs.push({
                label: t("Scar Pictures"),
                component: <ScarPicturesPatientView user={user} patient_details={this.state.patient_details} i18n={this.props.i18n}/>
            })
        }
        return(
            <Dashboard title={t("Patient Details (inclusion number ")} inclusionId = {inclusionId} >
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="full width tabs example"
                        >
                            {tabs.map((tab, index)=> {
                                return <Tab label={tab.label} key={index} {...a11yProps(0)}/>
                            })}
                        </Tabs>
                    </AppBar>
                    <SwipeableViews style={questab}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                    >
                        {tabs.map((tab, index)=> {
                            return <TabPanel value={this.state.value} key={index} index={index} >
                                {this.state.value === index ? tab.component : <React.Fragment/>}
                            </TabPanel>
                        })}
                    </SwipeableViews>
                </div>
            </Dashboard>
        )
    }
}

export default withStyles(useStyles)(withTranslation('common')(HepadomPatientView))
