import React, {Component} from 'react';
import {
    withStyles,
    FormControl,
    TextField,
    Button
} from '@material-ui/core';

import {
    Add as AddIcon
} from '@material-ui/icons';

class ArrayHandler extends Component {
    state = {
        numberOfTextFields: 1,
        data: []
    };

    handleChange(event) {
        const {onChange, field} = this.props;

        const data = JSON.parse(JSON.stringify(this.state.data));
        data[event.target.id] = event.target.value;
        //console.log("data**********", data);
        this.setState({...this.state, data});
        const event1 = {
            target: {
                name: field.name,
                value: data
            }
        }
        onChange(event1);
    }

    getTextFields(classes, field, disabled, noOfTextFields) {
        return <FormControl className={classes.formControl}
                            style={{width: '90%'}}>
            <TextField
                variant="outlined"
                margin="normal"
                key={noOfTextFields}
                type="text"
                value={this.state.data[noOfTextFields]}
                id={noOfTextFields}
                disabled={disabled}
                label={field.label}
                name={field.name}
                onChange={e => this.handleChange(e)}
                autoFocus={field.autofocus === true}
            >
            </TextField>
        </FormControl>
    }

    addTextField() {
        this.setState({...this.state, numberOfTextFields: this.state.numberOfTextFields + 1})
    }

    renderFields(classes, field, disabled) {
        const fields = [];
        let noOfTextFields = this.state.numberOfTextFields;
        let index = 0;
        while (noOfTextFields !== index) {
            fields.push(this.getTextFields(classes, field, disabled, index));
            index++;
        }

        return fields;
    }

    render() {
        //TODO: Implement error handling in this component
        const {classes, disabled, field} = this.props;
        return (
            <div className={classes.root}>
                {this.renderFields(classes, field, disabled)}
                <Button color="primary" className={classes.button} onClick={this.addTextField.bind(this)}>
                    <AddIcon/>
                </Button>
            </div>
        );
    }
}

const styles = (theme) => ({
    root: {
        textAlign: 'left',
        paddingLeft: "2.3%"
    },
    label: {
        marginTop: 16,
        marginRight: 10
    },
    formControl: {},
    button: {
        padding: 10,
        marginTop: 23
    },

});

export default withStyles(styles)(ArrayHandler);
