import React from "react";
import Dashboard from 'components/Dashboard';
import Form from 'components/Form';
import { Entity } from 'utils/services/entity';
import { withStyles, Paper } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
const useStyles = (theme) => ({
    root: {
        height: '150vh',
        flexgrow: 1
    },
    paper: {
        overflow: 'auto',
        margin: theme.spacing(4, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

class QnASurvey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    _onSubmit() {
        return new Promise((resolve, reject) => {
            const data = {...this.state.data};

            const options = {
                entity: "questionnaireanswer",
                data: Object.keys(data).map((key)=> {
                    return { question: key, answer: data[key] }
                })
            };
            Entity.call("addAnswer", options)
                .then((response)=> {
                    window.location.reload();
                })
                .catch(()=> {
                    // do nothing   
                })
        });
    }

    componentDidMount() {
        const options = {
            entity: "questionnaire",
        };

        Entity.call("getPatientQuestionnaire", options)
            .then((response) => {
                const records = response.data.records;
                const fields = [];
                records.forEach(record => {
                    const questionnaire = {
                        name: record.question,
                        label: record.question,
                        required: true,
                        width : "90%",
                        type: (record.type === 'multiselect') ? "checkbox" : record.type,
                        component: Paper
                    };
                    if (questionnaire.type === "radio" || questionnaire.type === "checkbox") {
                        questionnaire.options = record.options.map(option=>{ return { key: option, value: option } });
                    }
                    fields.push(questionnaire);
                });
                return Promise.resolve(fields);
            })
            .then((fields)=> {
                console.log("fields**********", fields);
                const form = {
                    onSubmit: this._onSubmit,
                    submitButtonText: "Save",
                    buttonWidth: "45%",
                    successRedirect: "/questionnaire",
                    addDivider: true,
                    fields
                };
                this.setState({ ...this.state, form });
            })
            .catch((err) => {

            })
    }

    render() {
        const { t } = this.props;
        return (
            <Dashboard title={t("Questionnaire")} disablePaper={true}>
                {this.state.form ? <Form options={this.state.form} />: null}
            </Dashboard>
        );
    }
}

export default withStyles(useStyles)(withTranslation('common')(QnASurvey));
