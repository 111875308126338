import React, {Component} from 'react';
import {Provider} from 'react-redux';
import { I18nextProvider } from "react-i18next";
import { Router } from 'utils/routing';
import store from 'utils/store';
import i18n from "./i18n";
import Routes from "./Routes";

class App extends Component {
    render() {
        return (
            <I18nextProvider i18n = {i18n}>
            <Provider store={store}>
                <Router>
                    <Routes/>
                </Router>
            </Provider>
            </I18nextProvider>
        )
    }
}

export default App;