import { Grid, withStyles, } from "@material-ui/core";
import AccelerationVisualization from "components/AccelerationVisualization";
import CenterRestClock from "components/CenterRestClock";
import IO from "components/IO";
import OutOfBedDailyMedianActivity from "components/OutOfBedDailyMedianActivity";
import RI from "components/RI";
import Temperature from "components/Temperature";
import MMMTemperature from "components/MMMTemperature";
import Weight from "components/Weight";
import WeightVariationVisualization from "components/WeightVariationVisualization";
import React from "react";
import ActivityAmplitude from "components/ActivityAmplitude";
import ActivityPhase from "components/ActivityPhase";
import TemperatureAmplitude from "components/TemperatureAmplitude";
import TemperaturePhase from "components/TemperaturePhase";
import Divider from '@material-ui/core/Divider';
import SensorWearingVisualization from "components/SensorWearingVisualization";
import ActivityPeriod from "components/ActivityPeriod";
import TemperaturePeriod from "components/TemperaturePeriod";
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import {Entity} from "utils/services/entity";


const useStyles = (theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#3f51b5',
            outline: '1px solid slategrey'
        }
    },
    gridcontainer : {
       height: 'calc(100% - 10%)',
      },
      
      gridcolumn: {
        height: '400px',
        overflow: 'scroll',
        width : '100%'
      }
});
class MedicalData extends React.Component {
    state = { noOfDays: 8, dates: "", buttonName: "",patient_details:{} }
    constructor(props) {
        super(props);
        
        const options = {
            entity: "patients",
            entity_id: this.props.patient_details._id
        };
        Entity.get(options)
            .then(response=> {
                if (response.status !== 200) {
                    return this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator"});
                }
                const data = response.data;
                this.setState({ ...this.state, patient_details: data });
            
            })
            .catch(err=> {
                this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator"})
            })
        }
  

    updateGraphRange = (days) => {
        if (days !== this.state.noOfDays) {
            this.setState({ noOfDays: days });
        }
    }
    buttonNameLabel = (item) => {
        if (item === 8) {
            const buttonName = "Semaine de référence";
            return buttonName;
        } else {
            const buttonName = "Semaine en cours";
            return buttonName;
        }
    }
    renderRangeOptions = () => {
        const options = [8, 7];
        return (
            <div>
            <ButtonGroup style={{ paddingBottom: '10px' }} color="primary" aria-label="outlined primary button group">
                {options.map(item => {
                    const customStyle = item === this.state.noOfDays ? { fontWeight: 'bold' } : null;

                    return (
                        <Button key={item} style={customStyle} onClick={() => this.updateGraphRange(item)}>{this.buttonNameLabel(item)}</Button>

                        // <Button key={item === 7} style={customStyle} onClick={() => this.updateGraphRange(item)}>Graph sur {item} jours</Button>

                        //<Button key={item} style={customStyle} onClick={() => this.updateGraphRange(item)}>Graph sur {item} jours</Button>
                        //<Button key={item} style={customStyle} onClick={() => this.updateGraphRange(item)}>Graph sur {item} jours</Button>
                    )
                })}
            </ButtonGroup>
            </div>
        );
    }
    chemotheraphyDate = () => {
      
        if (!this.state.patient_details) {
            return;
        }
        const chemotheraphy_dates = [];
        if (this.state.patient_details.theoretical_date_of_chemotheraphy_1) {
            chemotheraphy_dates.push(new Date(this.state.patient_details.theoretical_date_of_chemotheraphy_1).getTime());
        }
        if (this.state.patient_details.theoretical_date_of_chemotheraphy_2) {
            chemotheraphy_dates.push(new Date(this.state.patient_details.theoretical_date_of_chemotheraphy_2).getTime());
        }
        if (this.state.patient_details.theoretical_date_of_chemotheraphy_3) {
            chemotheraphy_dates.push(new Date(this.state.patient_details.theoretical_date_of_chemotheraphy_3).getTime());
        }
        const prevdates = [];
        const currentDate = new Date().getTime();
        //logic not to include the chemotheraphy date if it is greater than current date 
        for(let i = 0 ; i < chemotheraphy_dates.length;i++){
            if(chemotheraphy_dates[i] <= currentDate){
                 prevdates.push([moment(chemotheraphy_dates[i]).format('DD-MM-YYYY')])
            }
        }
        const allChemoDates = prevdates.join(", ");
        return (
            <div>
                <Typography variant="subtitle1" gutterBottom color ="error" fontWeight = 'bold'>
                Dates de chimiothérapies effectuées : [ {allChemoDates} ]
             </Typography>
                 
            </div>
        );

    }

    render() {
        const { patient_details,classes } = this.props;
        const inclusion_id = patient_details.inclusion_id;
        const patient_id = patient_details._id;
        const visit_1 = moment(patient_details.theoretical_date_of_visit_1).format("YYYY-MM-DD");
        const todayDate = moment().format("YYYY-MM-DD");
        const graphDate = (8 === this.state.noOfDays) ? visit_1 : todayDate;
      


        return (
           <React.Fragment>
           <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
               {this.renderRangeOptions()}
               {this.chemotheraphyDate()}
               </AppBar>
               <Divider style={{"margin": "3%"}}/>
               <Grid container  className = {classes.gridcolumn}>
               <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <Weight inclusion_id={inclusion_id} graphDate={(graphDate)} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <WeightVariationVisualization inclusion_id={inclusion_id} patient_id={patient_id} graphDate={graphDate} />
                        </Grid>
                    </Grid>
                    <Divider style={{"margin": "3%"}}/>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <Temperature inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MMMTemperature inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                    </Grid>
                    <Divider style={{"margin": "3%"}}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <AccelerationVisualization inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <IO patient_id={patient_id} inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                    </Grid>
                    <Divider style={{"margin": "3%"}}/>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <RI inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <CenterRestClock inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                    </Grid>
                    <Divider style={{"margin": "3%"}}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <OutOfBedDailyMedianActivity inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <SensorWearingVisualization inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                    </Grid>
                    <Divider style={{"margin": "3%"}}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <ActivityPhase inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <TemperaturePhase inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                    </Grid>
                    <Divider style={{"margin": "3%"}}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ActivityPeriod inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <TemperaturePeriod inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                    </Grid>
                    <Divider style={{"margin": "3%"}}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ActivityAmplitude inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TemperatureAmplitude inclusion_id={inclusion_id} graphDate={graphDate} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
                    </Grid>
                    </Grid>
           </React.Fragment>
            
        )
    }

}

export default withStyles(useStyles)(MedicalData);
