import React from "react";
import {MenuItem,Tooltip,FormControl,Button,Menu} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import {withStyles} from "@material-ui/core";
import LanguageIcon from '@material-ui/icons/Language';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      width: 120
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },

});

class LanguageSelectorCommon extends React.Component {

    handleClick = event => this.setState({ anchorEl: event.currentTarget })
    handleClose = () => this.setState({ anchorEl: null })

    constructor(props) {
        super(props);
        this.state = {
            language:((this.props.i18n.language==='en'||this.props.i18n.language==='fr') ? this.props.i18n.language :'en'),
        }
    }
    componentDidMount() {
       
    }
    onLanguageHandle = (value) => {
        this.handleClose();
        const { t } = this.props;
        var msg = t("Are you sure you want to change language?");
        var x = window.confirm(msg);
        if (!x) return false;    
        let newLang = value;
        this.setState({language:newLang });
        this.props.i18n.changeLanguage(newLang)
      }
    render() {
        const {t,classes} = this.props;
        const { anchorEl } = this.state;

        return(
            <FormControl className={classes.formControl} > 
            <Tooltip title= {t("Select language")} aria-label="change language">   
                <Button   aria-controls="customized-menu" aria-haspopup="true" onClick={this.handleClick} startIcon={<LanguageIcon/>} endIcon={<ArrowDropDownIcon/>}>       
               {this.state.language=="en"?"English":"Français"}
               </Button>
               </Tooltip>
               <StyledMenu
                   id="select_language"
                   anchorEl={anchorEl}
                   keepMounted
                   open={Boolean(anchorEl)}
                   onClose={this.handleClose}
                   
               >
               <MenuItem  onClick={value => this.onLanguageHandle('en')} >{"English"}</MenuItem>
               <MenuItem  onClick={value => this.onLanguageHandle('fr')} >{"Français"}</MenuItem>
               </StyledMenu>
            </FormControl>
        )
    }
}

export default (withStyles(useStyles)(withTranslation('common')(LanguageSelectorCommon)));
