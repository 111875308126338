import React, { useState, useEffect } from "react";
import { HepadomEntity } from "utils/services/hepadom_entity";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { CloudDownload as Download } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

const ScarPicturesPatientView = ({ user, patient_details, i18n, t }) => {
  //DB Call
  const getQuestionnaires = (patient_id) => {
    return new Promise((resolve, reject) => {
      const options = {
        entity: "questionnaire",
        data: {
          patient_id,
          name: "Scar Pictures",
        },
      };
      HepadomEntity.call("getQuestionnaire", options)
        .then((response) => {
          const records = response.data.records;
          resolve(records);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const [imageIndexes, setImageIndexes] = useState([]);

  const [records, setRecords] = useState({
    data: null,
    loading: true,
  });

  const { data } = records;

  useEffect(() => {
    getQuestionnaires(patient_details._id)
      .then((data) => {
        const sortedRecords = data.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
        setRecords({ ...records, data: sortedRecords, loading: false });
      })
      .catch(() => {
        setRecords({ ...records, loading: false });
      });
  }, []);

  const [modal, setModal] = useState({
    showModal: false,
    imageUrl: "",
  });

  const { showModal, imageUrl } = modal;

  //Download func goes here
  const handleDownload = (url) => {
    var link = document.createElement("a");
    link.href = url;
    link.download = "Download.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAll = () => {
    let links = [];
    if (imageIndexes.includes("All")) {
      data &&
        data.map((item) => {
          if(item.url){
            const imageUrl = item.url;
            links.push(imageUrl);
          }
        });
    } else {
      imageIndexes.map((item) => {
        const imageUrl = data[item].url;
        links.push(imageUrl);
      });
    }
    fileDownloadHandler(links);
  };

  const fileDownloadHandler = async (pictures) => {
    for (var i = 0; i < pictures.length; i++) {
      const response = await fetch(pictures[i]);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "picture.jpg";
        a.click();
      });
    }
  };

  //reverse cronological Order *Logic*
  function compare( a, b ) {
    if ( a.dayOfStudy > b.dayOfStudy ){
      return -1;
    }
    if ( a.dayOfStudy < b.dayOfStudy ){
      return 1;
    }
    return 0;
  }
  
  return (
    <div className="scar-pictures-body">
      <div className="header">
        <div className="headeing"> {t("Scar Pictures")} </div>
        <div className="sub-headeing">
          {t("These are the list of Pictures Captured By This Patient.")}
        </div>
      </div>
      {data && data.length > 0 ? (
        <div className="scar-pictures-items">
          <div className='downald'>
            <span>({imageIndexes.includes("All") ? data.filter(x=> x.url!==null).length : imageIndexes.length}) {t("Image (s) Selected")}.</span>
            <span><Button variant="contained" color="primary" className="download-btn-icn" onClick={() => downloadAll()}> {t("Download")} <Download /></Button></span>
          </div>
        
          <table>
            <thead>
              <tr>
                <th>
                <Tooltip title={t("Select All")} placement="right">
                  <input
                    type="checkbox"
                    onClick={() => {
                      imageIndexes.includes("All")
                        ? setImageIndexes([])
                        : setImageIndexes(["All"]);
                    }}
                  />
                  </Tooltip>
                </th>
                <th>{t("Image")}</th>
                <th> {t("Day Of Study")}</th>
                <th> {t("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {data && data.sort( compare ) &&
                data.map((item, index) => {
                  return (item && item.url ? (
                    <tr key={index}> 
                    <td>
                      <input
                        type="checkbox"
                        onClick={() => {
                          imageIndexes.includes(index)
                            ? setImageIndexes([
                                ...imageIndexes.filter((x) => x !== index),
                              ])
                            : setImageIndexes([...imageIndexes, index]);
                        }}
                        checked={
                          imageIndexes.includes("All") ||
                          imageIndexes.includes(index)
                        }
                        disabled={imageIndexes.includes("All")}
                      />
                    </td>
                    <td
                      onClick={() =>
                        setModal({ showModal: true, imageUrl: item.url })
                      }
                    >
                      <Tooltip title={t("View")} placement="right">
                      <img alt="" src={item.url} />
                      </Tooltip>
                    </td>
                    <td>{item.dayOfStudy}</td> 
                    <td>
                    <Tooltip title={t("Download")} placement="right">
                      <a className="enabled" onClick={() => handleDownload(item.url)}>
                      <Download />
                      </a>
                      </Tooltip>
                    </td>
                  </tr>
                  ) :(
                    <tr key={index}> 
                    <td>
                      <input
                        type="checkbox"
                        disabled={true}
                      />
                    </td>
                    <td>
                      {t("No Scar Picture")}
                    </td>
                    <td>{item.dayOfStudy}</td> 
                    <td>
                    <Tooltip title={t("Download")} placement="right">
                      <a className="disabled">
                      <Download />
                      </a>
                      </Tooltip>
                    </td>
                  </tr>
                  ));
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <div>{t("No Scar Pictures Found")}</div>
      )}

      {showModal && (
        <div className="scar-picture-modal">
          <div
            className="close-btn"
            onClick={() => setModal({ showModal: false, imageUrl: "" })}
          >
            X
          </div>
          <img alt="" src={imageUrl} className="scar-picture-modal-img" />
        </div>
      )}
    </div>
  );
};

export default withTranslation("common")(ScarPicturesPatientView);
