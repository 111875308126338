import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Form from 'components/Form';
import Layout from 'components/Layout';
import React from 'react';
import Routing from 'utils/routing';
import { Entity } from 'utils/services/entity';
import {HepadomEntity} from "utils/services/hepadom_entity"
import { withTranslation } from 'react-i18next';
import loginImage from '../../../images/login.jpg';
import configData from "../../../common.json";

const { Link } = Routing;

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        // backgroundImage: 'url(https://40.89.185.245/login.jpg)',
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class ChangePassword extends React.Component {
    state = {};
    constructor(props) {
        super(props);
        const search = window.location.search;
        const urlParams = new URLSearchParams(search);
        const token = urlParams.get("token");
        //get the clinical study parameter and from url params and assign the corresponding entity to SelectedEntity
        const clinical_study = urlParams.get("clinical_study");
        if (!token||!clinical_study) {
            this.state = {
                error: "Token and clinical study Required"
            };
        } else {
            this.state = {
                token:token,
                SelectedEntity : (clinical_study===configData.clinical_study.HEPADOM_CLINICAL_STUDY)? HepadomEntity : Entity
            }
        }
        this.form = {
            onSubmit: this._onSubmit,
            submitButtonText: "Change Password",
            fields: [
                {
                    name: "password",
                    label: "Password",
                    required: true,
                    autofocus: true,
                    width: "100%",
                    validate: [
                        {
                            type: "regex",
                            value: new RegExp("^(?!.*[\\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15}$)"),
                            message: "Password must contains one capital and lower case character, number, special character, minimum length of eight and maximum length of 15 without white space"
                        }
                    ],
                    type: "password"
                },
                {
                    name: "confirm_password",
                    label: "Confirm Password",
                    required: true,
                    width: "100%",
                    type: "password"
                },
            ]
        }
    }

    _onSubmit() {
        return new Promise((resolve, reject)=> {
            const { t } = this.props;
            const data = JSON.parse(JSON.stringify(this.state.data));
            if (data.password !== data.confirm_password) return reject({message: t("Password and Confirm Password should match")});
            const parameters = this.parameters;

            const options = {
                entity: "users",
                data: {
                    password: data.password,
                    token: parameters.token
                }
            };

            parameters.SelectedEntity.call("change_password", options)
                .then((response)=> {
                    resolve(response);
                    setTimeout(()=> {
                        window.location.href = window.location.origin + "/sign-in";
                    }, 500)
                })
                .catch((err)=> {
                    err.message = err.error;
                    reject(err);
                })
        })
    }

    render() {
        const { t,classes } = this.props;
        if (this.state.error) {
            return (
                <Layout classes={classes} text={t("Change Password")}>
                    <div style={{color: "red"}}>{t(this.state.error)}</div>
                </Layout>
            )
        } else {
            return (
                <Layout classes={classes} text={t("Change Password")}>
                    <Form options={this.form} parameters={{token: this.state.token,SelectedEntity: this.state.SelectedEntity}}/>
                    <Grid container style={{marginTop: "1%"}}>
                        <Grid item>
                            <Link component="button"
                                  variant="body2" to="/sign-in">
                                {t("Sign In")}
                            </Link>
                        </Grid>
                    </Grid>
                </Layout>
            )
        }
    }
}

export default withStyles(useStyles)(withTranslation('common')(ChangePassword))
