import { Platform } from 'react-native';
import moment from 'moment';

export const isWeb = Platform.OS === 'web';

export const isTabBrowser = () => {
    return window.innerWidth <= 1200;
};

export const isMobileBrowser = () => {
    return window.innerWidth <= 930;
};

export const getSeries = (records) => {
    let i = 0;
    let date = new Date(records[0][0]);
    const lastDate = new Date(records[records.length - 1][0]);
    const series = [];
    series.push([moment(date).format('DD-MM-YYYY')]);
    for (i = 1; moment(date).format('DD-MM-YYYY') !== moment(lastDate).format('DD-MM-YYYY'); i++) {
        date.setDate(date.getDate() + 1);
        series.push([moment(date).format('DD-MM-YYYY')]);
    }
    records.forEach((record) => {
        const match = series.find(item => item[0] === moment(new Date(record[0])).format('DD-MM-YYYY'));
        if (match) {
            for (let i = 1; i < record.length; i++) {
                match[i] = record[i];
            }
        }
    });
    series.forEach((item) => { // populate not available data with null
        for (let j = 1; j < series[0].length; j++) {
            item[j] = item[j] === undefined ? null : item[j];
        }
    });
    return series;
}

//Below function is to check  given time is between start and end time
export const  isTimeBetween =(startTime, endTime, Given_Time)=> {
        let start = moment(startTime, "H:mm")
        let end = moment(endTime, "H:mm")
        let GivenTime = moment(Given_Time, "H:mm")
        if (end < start) {
            return GivenTime >= start && GivenTime<= moment('23:59:59', "h:mm:ss") || GivenTime>= moment('0:00:00', "h:mm:ss") && GivenTime < end;
        }
        return GivenTime>= start && GivenTime< end
      }

 //Below function is used get only 3 records per day on given time slot also
 //To populate NULL values if data is not there     
 export const getSeries_three_records=(records)=>
 {        
     const getTimezone = timezone();
    let i = 0;
    let date = new Date(records[0][0]);
    const lastDate = new Date(records[records.length - 1][0]);
    const series = [];
    series.push([moment(date).tz(getTimezone).format('DD-MM-YYYY'),[],[],[]]);
    for (i = 1; moment(date).tz(getTimezone).format('DD-MM-YYYY') !== moment(lastDate).tz(getTimezone).format('DD-MM-YYYY'); i++) {
        date.setDate(date.getDate() + 1);
        series.push([moment(date).tz(getTimezone).format('DD-MM-YYYY'),[],[],[]]);
    }

    records.forEach((record) => {
        const match = series.find(item => item[0] === moment(new Date(record[0])).tz(getTimezone).format('DD-MM-YYYY'));
        if (match) {
            var time = moment(record[0]).tz(getTimezone).format('H:mm');
            if(isTimeBetween('06:00', '11:00', time))
            {
              for (let i = 1; i < record.length; i++)
               match[1][i] = record[i];
               match[1][0]=moment(record[0]).tz(getTimezone).format('DD-MM-YYYY HH:mm');  
            }
            else if(isTimeBetween('11:00', '18:00', time))
            {
              for (let i = 1; i < record.length; i++)
               match[2][i] = record[i];
               match[2][0]=moment(record[0]).tz(getTimezone).format('DD-MM-YYYY HH:mm');  
            }
            else if(isTimeBetween('18:00', '23:00', time))
            {
              for (let i = 1; i < record.length; i++)
               match[3][i] = record[i];
               match[3][0]=moment(record[0]).tz(getTimezone).format('DD-MM-YYYY HH:mm');  
            }
  
           }
        
        });
      
            // populate not available data with null
            const series_tmp = [];
        series.forEach((item) => { 
            for (let j = 1; j < item.length; j++) {
               let temp=item[0].toString().split("-");
              if(!item[j][0])
              {
               let no_data_time="";
               if(j==1)
               no_data_time=temp[0]+"-"+temp[1]+"-"+temp[2]+" 08:00";  
               else if(j==2)
               no_data_time=temp[0]+"-"+temp[1]+"-"+temp[2]+" 13:00";  
               else if(j==3)
               no_data_time=temp[0]+"-"+temp[1]+"-"+temp[2]+" 20:00";   
               (records[0].length===4)?series_tmp.push([no_data_time,parseFloat(null),parseFloat(null),parseFloat(null)]):series_tmp.push([no_data_time,parseFloat(null),parseFloat(null)]);
            }
            else
            series_tmp.push(item[j]);
         }
     });
   
     return series_tmp;
   }
export const graphColor = {
    weight: "#3b9438",
    temperature: "#ff7700",
    activity: "#2284e6",
    alert: "#e2431e",
    mintemp: "#ffc999",
    maxtemp: "#cc5f00"
};

export const graphXYValues = (records, colIndex) => {
    return records.map(value => value[colIndex]);
};
// this fucntion will reload the webpage
export const refreshPage = () => {
    return window.location.reload();
}
export const getEntity = () => {
    return window.location.href.indexOf('hepadom') !== -1 ? 'hepadom' : 'multidom';
}
//return the timezone based on the environment variable dev/prd
export const timezone = () => {
    let timezone
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev timezone
        //timezone = "Asia/Kolkata"
        timezone = "Europe/Paris"
    } else {
        // production timezone
        timezone = "Europe/Paris"
    }
    return timezone;
}