import React, { Component } from 'react';
import Dashboard from 'components/Dashboard'
import Form from 'components/Form';
import { HepadomEntity } from '../../../utils/services/hepadom_entity';
import { withTranslation } from 'react-i18next';


class DoctorRegistration extends Component {
    constructor(props) {
        super(props);
        this.form = {
            onSubmit: this._onSubmit,
            submitButtonText: "Register",
            buttonWidth: "50%",
            successRedirect: "/doctors",
            fields: [
                {
                    name: "first_name",
                    label: "First Name",
                    required: true,
                    autofocus: true,
                    width: "45%",
                    type: "text"
                },
                {
                    name: "last_name",
                    label: "Last Name",
                    required: true,
                    width: "45%",
                    type: "text"
                },
                {
                    name: "user_name",
                    label: "User Name",
                    required: true,
                    width: "45%",
                    type: "text"
                },
                {
                    name: "email_id",
                    label: "E-mail",
                    required: true,
                    width: "45%",
                    type: "text",
                    validate: [
                        {
                            type: "regex",
                            value: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
                            message: "Invalid Email ID"
                        }
                    ],
                },
                {
                    name: "phone_number",
                    label: "Phone Number",
                    required: true,
                    width: "45%",
                    type: "phone_number",
                    validate: [
                        {
                            type: "regex",
                            value: new RegExp(/^\+[1-9]{2}[0-9]{10,15}$/),
                            message: "Invalid mobile number"
                        }
                    ],
                },
                {
                    name: "language",
                    label: "Language Choice",
                    required: true,
                    width: "45%",
                    type: "select",
                    options: [
                        {
                            key: "English", value: "en"
                        },
                        {
                            key: "French", value: "fr"
                        }

                    ]
                }
            ]
        }
    }
    _onSubmit() {
        return new Promise((resolve, reject) => {
            const { t } = this.props;
            const data = this.state.data;
            const options = {
                entity: "admins",
                data
            };
            HepadomEntity.add(options)
                .then((response) => {
                    resolve({ message: t("Register Successfully") });
                })
                .catch((err) => {
                    reject({ message: err.error });
                })
        });
    }
    render() {
        const { t } = this.props;
        return (
            <Dashboard title={t("Investigating On-Call Surgeon")}>
                <Form options={this.form} title={t("INVESTIGATING ON-CALL SURGEON REGISTRATION")} />
            </Dashboard>
        );
    }
}

export default withTranslation('common')(DoctorRegistration);
