import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import moment from 'moment';
import { graphColor } from '../../utils/common'

function getData(props) {
    return new Promise((resolve, reject)=> {
        const options = {
            entity: "sensors",
            query: {
                name: "ri",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        Entity.call("graph", options)
            .then(res=> {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err=> {
                reject();
            })
    })
}

function getSeries(records) {
    let i = 0;
    let date = new Date(records[0][0]);
    const lastDate = new Date(records[records.length - 1][0]);
    const series = [];
    series.push([moment(date).format('DD-MM-YYYY')]);
    for (i=1; moment(date).format('DD-MM-YYYY') !== moment(lastDate).format('DD-MM-YYYY'); i++) {
        date.setDate(date.getDate() + 1);
        series.push([moment(date).format('DD-MM-YYYY')]);
    }
    records.forEach((record)=> {
        const match = series.find(item => item[0] === moment(new Date(record[0])).format('DD-MM-YYYY'));
        if (match) match[1] = record[1];
    });
    series.forEach((item)=> { // populate not available data with null
        item[1] = item[1] === undefined ? null : item[1];
    });
    return series;
}

class Weight extends React.Component {

    state = {data: [], options: {}};
    constructor(props) {
        super(props);

        getData(props)
            .then(records=> {
                this.setState({ ...this.state, records });
                const options = {
                    title: 'Indice de rythme (IR)',
                    interpolateNulls: false,
                    vAxis: {
                        title: 'IR',
                        ticks: [0,0.2,0.4,0.6,0.8,1.0]
                    },
                    hAxis: {
                        type: 'category'
                    },
                    colors:[graphColor.activity],
                    pointSize: 5,
                    legend: { position: 'top', alignment: 'end' },
                };

                // records = records.map((record)=> {
                //     record[0] = new Date(record[0]);
                //     return record;
                // });

                // records.splice(2, 2);
                records = getSeries(records);

                records.unshift(["X", "Indice de rythme (IR)"]);
                this.setState({ data: records, options });
            })
            .catch(err=> {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title: 'Indice de rythme (IR) (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
    }
     componentDidUpdate(prevProps){
         if(prevProps.graphDate !== this.props.graphDate){
            getData(this.props)
            .then(records=> {
                this.setState({ ...this.state, records });
                const options = {
                    title: 'Indice de rythme (IR)',
                    interpolateNulls: false,
                    vAxis: {
                        title: 'IR',
                        ticks: [0,0.2,0.4,0.6,0.8,1.0]
                    },
                    hAxis: {
                        type: 'category'
                    },
                    colors:[graphColor.activity],
                    pointSize: 5,
                    legend: { position: 'top', alignment: 'end' },
                };

                // records = records.map((record)=> {
                //     record[0] = new Date(record[0]);
                //     return record;
                // });

                // records.splice(2, 2);
                records = getSeries(records);

                records.unshift(["X", "Indice de rythme (IR)"]);
                this.setState({ data: records, options });
            })
            .catch(err=> {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    }, ]
                ];
                const options = {
                    title: 'Indice de rythme (IR) (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({...this.state, data: records, options });
            })
         }

     }

    render() {
        if (!this.state.data.length) return <div className="loader"/>;
        return(
            <Chart
                chartType="LineChart"
                loader={<div>Rhythm index (RI)</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />
        )
    }

}


export default Weight;
