import React from "react";
import Table from "components/Table";
import { withTranslation } from "react-i18next";
import { Entity } from "utils/services/entity";
import { saveAs } from "file-saver";
import Button from "@material-ui/core/Button";
import { CloudDownload as Download } from "@material-ui/icons";
import moment from "moment-timezone";
import { timezone } from "../../../utils/common";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      data: [["Loading Data..."]],
      isLoading: true,
      open: false,
      variant: "success",
      downloading: false,
      disabled: false,
      language: this.props.i18n.language,
      isClear: false,
      searchText: null,
      generateMedicalDataReportActive: false,
      downloadMedicalDataReportActive: false,
    };
  }

  exportPatientData = () => {
    const getTimezone = timezone();
    const { patient_details } = this.props;
    const file_name =
      patient_details.inclusion_id +
      "-" +
      moment(new Date()).tz(getTimezone).format("DD-MM-YYYY HH:mm");
    const { t } = this.props;
    const options = {
      entity: "patients",
      command: "questionnaire",
      payload: {
        entity_id: patient_details._id,
        questionnaireName: "MDASI",
      },
    };
    Entity.export(options)
      .then((response) => {
        var binaryString = window.atob(response.arrayBuffer);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
          var ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        var blob = new Blob([bytes], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `${file_name}.xlsx`);
      })
      .catch((err) => {
        const state = {
          ...this.state,
          open: true,
          variant: "error",
          message: t(err.error),
        };
        this.setState(state);
      });
  };

  generateMedicalDataReport = () => {
    const { patient_details, t } = this.props;

    const options = {
      entity: "reports",
      command: "generateMedicalDataReport",
      data: {
        InclusionId: patient_details.inclusion_id,
        clinicaltrialinfo: patient_details.clinical_study,
      },
    };
    Entity.call("generateMedicalDataReport", options)
      .then((response) => {
        window.alert(
          t(
            "Generation of Medical Data Report is in Progress. Please check after some time"
          )
        );
        const state = {
          ...this.state,
          generateMedicalDataReportActive: true,
          downloadMedicalDataReportActive: false,
        };
        this.setState(state);
        localStorage.setItem("generateMedicalDataReportActiveMultidom", true);
      })
      .catch((err) => {
        window.alert(
          t(
            "Generation of Medical Data Report is Failed. Please check after some time"
          )
        );
        const state = {
          ...this.state,
          open: true,
          variant: "error",
          message: t(err.error),
          generateMedicalDataReportActive: false,
        };
        this.setState(state);
        localStorage.setItem("generateMedicalDataReportActiveMultidom", false);
      });
  };

  checkMedicalDataReportStatus = () => {
    const { patient_details, t } = this.props;

    const options = {
      entity: "reports",
      command: "checkMedicalDataReportStatus",
      data: {
        InclusionId: patient_details.inclusion_id,
        clinicaltrialinfo: patient_details.clinical_study,
      },
    };
    Entity.call("checkMedicalDataReportStatus", options)
      .then((response) => {
        if (response.body.export === "export done") {
          const state = {
            ...this.state,
            generateMedicalDataReportActive: false,
            downloadMedicalDataReportActive: true,
          };
          this.setState(state);
        }
      })
      .catch((err) => {
        const state = {
          ...this.state,
          open: true,
          variant: "error",
          message: t(err.error),
          generateMedicalDataReportActive: false,
          downloadMedicalDataReportActive: false,
        };
        this.setState(state);
      });
  };

  showFile(filename, blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([blob], { type: "application/zip" });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.

    const data = window.URL.createObjectURL(newBlob);
    let link = document.createElement("a");
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  downloadFile(data) {
    const {
      access_token,
      accountName,
      dnsSuffix,
      medical_data_filesystem,
    } = data;
    const { patient_details } = this.props;
    fetch(
      `https://${accountName}.${dnsSuffix}/${medical_data_filesystem}/multidom/patient_export_${patient_details.inclusion_id}/export_${patient_details.inclusion_id}.zip`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((r) => r.blob())
      .then((blob) => {
        this.setState({
          ...this.state,
          downloading: false,
          disabled: false,
          generateMedicalDataReportActive: false,
          downloadMedicalDataReportActive: false,
        });
        this.showFile(`patient_export_${patient_details.inclusion_id}`, blob);
        localStorage.setItem("generateMedicalDataReportActiveMultidom", false);
      })
      .catch((err) => {
        localStorage.setItem("generateMedicalDataReportActiveMultidom", false);
        this.setState({
          ...this.state,
          generateMedicalDataReportActive: false,
          downloadMedicalDataReportActive: false,
        });
      });
  }

  getToken() {
    const options = {
      entity: "reports",
    };
    this.setState({
      ...this.state,
      downloading: "Downloading",
      disabled: true,
    });
    Entity.call("getToken", options)
      .then((response) => {
        this.downloadFile(response.data);
      })
      .catch((err) => {
        // do nothing
      });
  }

  render() {
    const { patient_details, t } = this.props;

    const callMedicalDataReportStatusAPI = window.setInterval(() => {
      if (this.state.generateMedicalDataReportActive) {
        this.checkMedicalDataReportStatus();
      }
    }, 180000);

    if (this.state.downloadMedicalDataReportActive) {
      clearInterval(callMedicalDataReportStatusAPI);
    }

    return (
      <div>
        <div
          style={{
            paddingBottom: "24px",
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Button
            style={{ width: "auto", textTransform: "none" }}
            onClick={this.generateMedicalDataReport}
            variant="contained"
            color="primary"
            disabled={this.state.generateMedicalDataReportActive}
          >
            <span style={{ paddingRight: "12px" }}>
              {t("Prepare medical data")}
            </span>
          </Button>
          <Button
            style={{ width: "auto", textTransform: "none" }}
            onClick={() => this.getToken(this)}
            variant="contained"
            color="primary"
            disabled={!this.state.downloadMedicalDataReportActive}
          >
            <span style={{ paddingRight: "12px" }}>
              {t("Export medical data")}
            </span>
            <Download />
          </Button>
          <Button
            style={{ width: "auto", textTransform: "none" }}
            onClick={this.exportPatientData}
            variant="contained"
            color="primary"
          >
            <span style={{ paddingRight: "12px" }}>
              {t("Export other data")}
            </span>
            <Download />
          </Button>
        </div>

        <div className="report-table">
          <h4>{t("Reports PDF")}</h4>
          <Table
            entity="reports"
            actions={["download"]}
            fileField={"name"}
            filters={[
              {
                field: "patient_id",
                value: patient_details._id,
                operator: "equal",
              },
            ]}
            fields={[
              { key: "name", value: "Name" },
              { key: "lastModified", value: "Date" },
              { key: "size", value: "Size" },
            ]}
            options={{
              search: false,
              pagination: false,
              processData: (data) => {
                return data && data.length ? data.reverse() : data;
              },
            }}
          ></Table>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Reports);
