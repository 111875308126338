
import '../../styles/common.css';
import React from "react";
import ReactApexChart from 'react-apexcharts';
import { HepadomEntity } from "utils/services/hepadom_entity";
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { isTimeBetween,timezone } from '../../utils/common';

const graphKeys = [{
id:"5dde0963e3fa485b974fb001",name:"Gas winds, flatulences"},{id:"5dde0963e3fa485b974fb002", name:"Bowel movement"},{id:"5dde0963e3fa485b974fb003",name:"Nausea, vomiting"},
{id:"5dde0963e3fa485b974fb004",name:"Pain relieved"},{id:"5dde0963e3fa485b974fb005",name:"Contact to adapt analgesic treatment"},{id:"5dde0963e3fa485b974fb006",name:"Water / Non-alcoholic drinks"},
{id:"5dde0963e3fa485b974fb007",name:"Yoghurt/compote"},{id:"5dde0963e3fa485b974fb008",name:"Light food"},
{id:"5dde0963e3fa485b974fb009",name:"Almost normal diet"},{id:"5dde0963e3fa485b974fb010",name:"Increased diet"},
{id:"5dde0963e3fa485b974fb011",name:"Contact needed to adapt diet"}];



const getYaxisColorArray = () => {
    const colorArr = [];
    for (let i=0; i < graphKeys.length; i++) {
        // add the color only to main questionarie , for computed questionaire leave it to black
        if(i <= 2){colorArr.push('#0000FF'); }
        else if(i > 2 && i <=4){colorArr.push('#FF0000'); }
        else{
            colorArr.push('#000000') ;
        }
    }
    return colorArr;
};


const localData = {
    seriesDates: [],
    reg_date: null,
    hepatectomy_date:null,
    chartSeries: [],
    defaultColorArray: [],
    colorArray: [],
    yaxisColorArray: getYaxisColorArray()
};


function generateData(records, name) {
    const getTimezone = timezone();
    const series = localData.seriesDates.map(item => ({ x: item }));
    for (let j=0; j<records.length; j++) {
        if(records[j].answered_at){
            const anseweredDate = moment(records[j].answered_at).format("DD-MM-YYYY");
            var time = moment(records[j].answered_at).tz(getTimezone).format('H:mm');
            if (isTimeBetween('06:00', '11:00', time)) {
                const seriesItem = series.find(item => item.x === anseweredDate+" 08:00");
                if (seriesItem) {
                    seriesItem.x = moment(records[j].answered_at).tz(getTimezone).format("DD-MM-YYYY HH:mm");
                    for (let k = 0; k < records[j].answers.length; k++) {
                        const answer = records[j].answers[k];
                        if (answer._id === name) {
                            seriesItem.y = Number(answer.value);
                            break;
                        }
                    }
                }
            } else if (isTimeBetween('11:00', '18:00', time)) {
                const seriesItem = series.find(item => item.x === anseweredDate+" 13:00");
                if (seriesItem) {
                    seriesItem.x = moment(records[j].answered_at).tz(getTimezone).format("DD-MM-YYYY HH:mm");
                    for (let k = 0; k < records[j].answers.length; k++) {
                        const answer = records[j].answers[k];
                        if (answer._id === name) {
                            seriesItem.y = Number(answer.value);
                            break;
                        }
                    }
                }
            } else if(isTimeBetween('18:00', '23:00', time)) {
                const seriesItem = series.find(item => item.x === anseweredDate+" 20:00");
                if (seriesItem) {
                    seriesItem.x = moment(records[j].answered_at).tz(getTimezone).format("DD-MM-YYYY HH:mm");
                    for (let k = 0; k < records[j].answers.length; k++) {
                        const answer = records[j].answers[k];
                        if (answer._id === name) {
                            seriesItem.y = Number(answer.value);
                            break;
                        }
                    }
                }
            }
        }
    }
    const formattedSeries = getFormattedSeries(series);
    return formattedSeries;
}


function getFormattedSeries(series) {
    let formattedSeries = series.map(item => {
        item.y = item.y !== undefined ? item.y : -1;
        return item;
    });
    return formattedSeries;
}

function setSeriesDates() {
    localData.seriesDates = [];
    localData.defaultColorArray.splice(0, localData.defaultColorArray.length);
    if (!localData.reg_date||!localData.hepatectomy_date) {
        return;
    }
    const formattedhepatectomy_date = moment(new Date(localData.hepatectomy_date)).format("DD-MM-YYYY");
    const date = new Date(localData.reg_date);
    for (let i = 0; i < 33; i += 3) {
        const formattedDate = moment(date).format("DD-MM-YYYY");
        if (formattedhepatectomy_date === formattedDate) {
            localData.defaultColorArray[i] = 'red';
            localData.defaultColorArray[i+1] = 'red';
            localData.defaultColorArray[i+2] = 'red';
        }
        localData.seriesDates.push(formattedDate+" 08:00");
        localData.seriesDates.push(formattedDate+" 13:00");
        localData.seriesDates.push(formattedDate+" 20:00");
        date.setDate(date.getDate() + 1);
    }   
}

function setHepatectomyDate(patient_details) {
    if (!patient_details) {
        return;
    }
    localData.reg_date = patient_details.Patient_Registration_Date;
    if (patient_details.Hepactectomy_Date) {
        localData.hepatectomy_date = patient_details.Hepactectomy_Date;
    }
    setSeriesDates();
}

class SurveyTRANSIT extends React.Component {
    
    state = {
        noOfDays: 10,
        options: {},
        series: [],
        language:this.props.i18n.language
    };
    getQuestionnaires(patient_id) {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: "questionnaire",
                data: {
                    patient_id,
                    name: "TRANSIT"
                }
            };
            HepadomEntity.call("getQuestionnaire", options)
                .then(response=> {
                    const records = response.data.records.map(x=>{return {...x,answers:x.answers.map(y=> {
                        var temp = Object.assign({}, y);
                        if ((temp.value === "Yes") || (temp.value === "oui")) {
                            temp.value = 1;
                        } else if ((temp.value === "No") || (temp.value === "non")) {
                            temp.value = 0;
                        }
                            return temp;
                                               
                    })}});
                    resolve(records);
                })
                .catch(err=> {
                    reject(err);
                });
        });
    }
    renderGraph(props){
        const {t}=props;
        const options= {
            chart: {
                toolbar: {
                  show: false
                }
            },
            legend:{
                show: false,
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 0,
                                color: '#808080',
                                name: 'No'
                            },
                              {
                                from: 1,
                                to: 1,
                                color: '#008000',
                                name: 'Yes'
                           },
                            {
                                
                                from: '-1',
                                to: '-1',
                                name: `<span><span class="graph-legend"></span>${t('Not specified -1')}</span>`,
                                color: '#FFFFFF'
                            },
                        ]
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                title:{
                    text: t("Questionnaire submission dates")
                },
                labels: {
                    style: {
                        colors: localData.defaultColorArray
                    }
                }
            },
            yaxis: {
                labels: {
                    maxWidth: 240,
                    style: {
                        colors: localData.yaxisColorArray
                    }
                }
            }
        };
        setHepatectomyDate(props.patient_details);
        this.getQuestionnaires(props.patient_details._id)
            .then((data)=> {
                const records = data.sort((a, b)=> new Date(a.created_at) - new Date(b.created_at));
                const series = graphKeys.map(key => {
                    return {
                        name: t(key.name),
                        data: generateData(records, key.id)
                    };
                });
                this.setState({ ...this.state, series, loading: false,options });
            })
            .catch(()=> {
                this.setState({ ...this.state, loading: false });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.i18n.language!==this.state.language)
        {
            this.setState({ ...this.state, language: prevProps.i18n.language });
            this.renderGraph(this.props);
        }
    }
    constructor(props) {
        super(props);
        this.renderGraph(this.props);
    }
    render() {
        const {t}=this.props;
        let items=['#cccccc','#008000','#FFFFFF'];
        if (!this.state.series.length && this.state.loading) {return <div className="loader"/>;}
        const chartSeries =this.state.series;
        if (!chartSeries.length) {
            return null;
        }
        return (<div>
            <div>
            <h2 style={{
                    align: 'center',
                    fontSize: '20px',
                    fontWeight: 900,
                    fontFamily: `"Helvetica Neue", "Arial", "sans-serif"`,
                }}>{t('Transit questionnaire score')} </h2>
           {items.map((item,index)=>{
            if(index===11) {return (<span ><span className="legend" style={{backgroundColor: item,border: '1px solid #373d3f'}}></span ><span className="legend-text">{t('Not specified -1')}</span></span>);}
            else {return (<span><span className="legend" style={{backgroundColor: item}}></span><span className="legend-text">{index === 0 ? t('No') : index === 1 ? t('Yes') : t('Not specified')}</span></span>);}
            })}
            </div>
            <div id="chart"
            style={{ padding: '10px 0 20px' }}>
            <ReactApexChart options={this.state.options} series={chartSeries} type="heatmap" height="660"/>
             </div>
           
            </div>
        );
    }

}

export default (withTranslation('common')(SurveyTRANSIT));