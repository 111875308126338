import React from "react";
import Form from "components/Form";
import {Grid, Box} from "@material-ui/core";
import {HepadomEntity} from "utils/services/hepadom_entity";
import { withTranslation } from 'react-i18next';
let timeout;

const getThresholdSettings = (t) => ({
    
    ear_temperature: {
        alert_name: `${t('Ear temperature')} (°C)`,

    },
    heart_rate: {
        alert_name: `${t('Heart rate')} (bpm)`,

    },
    weight_variation: {
        alert_name: `${t('Weight variation')} (%)`,

    },
    oximetry: {
        alert_name: `${t('Oximetry')} (%)`,

    },
    diastolicVisualization: {
        alert_name: `${t('Diastolic blood pressure')} (mmHg)`,

    },
    systolicVisualization: {
        alert_name: `${t('Systolic blood pressure')} (mmHg)`,

    },
    mdasi_alert: {
        alert_name: 'MDASI',
    },
    pain_alert: {
        alert_name: `${t('Pain')}`,
    },
});

class AlertThreshold extends React.Component {
    state = {
        error: "",
        language:this.props.i18n.language
    };


    getThreshold(patient_id) {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: 'alert_threshold',
                query: {
                    filters: [
                        { field: 'patient_id', value: patient_id, operator: 'equal' }
                    ],
                    fields: { alert_name: 1, threshold_parameter: 1 }
                }
            };
            HepadomEntity.list(options)
                .then((response) => {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    getThresholdParameter(_id) {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: 'alert_threshold',
                query: {
                    filters: [
                        { field: '_id', value: _id, operator: 'equal' }
                    ],
                    fields: { threshold_parameter: 1 }
                }
            };
            HepadomEntity.list(options)
                .then((response) => {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    onChange(options) {
        const { data } = options;
        this.getThresholdParameter(data.name)
        .then((records)=> {
            const threshold_parameter =records[0].threshold_parameter;

            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(()=> {
                if(data.value.length===2)
                {
                    if(data.value[0]>=threshold_parameter[0].value && data.value[0]<=threshold_parameter[1].value)
                    {threshold_parameter[5].value=data.value[0];}
                    if(data.value[1]>=threshold_parameter[2].value && data.value[1]<=threshold_parameter[3].value)
                    {threshold_parameter[6].value=data.value[1];}
                }
                else
                {threshold_parameter[3].value=data.value;}
                const options = {
                    entity: 'alert_threshold',
                    entity_id: data.name,
                    data: {threshold_parameter: threshold_parameter}
                };
                HepadomEntity.edit(options)
                .then(()=> {}) // do nothing
                .catch((err) => {
                    this.setState({ ...this.state, error: err.error });
                })
            }, 500)
        })
        .catch((err) => {
            console.log(err)
            this.setState({ ...this.state, error: err.error });
        })

    }
    renderThreshold(props) {
        this.thresholdSettings = getThresholdSettings(props.t);   
        this.getThreshold(props.patient_details._id)
            .then((records)=> {
                const threshold = {
                    fields: [],
                    onChange: this.onChange.bind(this),
                };

                records.forEach((record)=> {
                    const settings = this.thresholdSettings[record.alert_name];
                    if (!settings) {
                        return;
                    }
                   if(record.alert_name==="ear_temperature"||record.alert_name==="heart_rate")
                   {
                    record.alert_name = settings.alert_name;
                    threshold.fields.push({
                        name: record._id,
                        label:record.alert_name,
                        required: true,
                        type: "slider",
                        display_type:"on",
                        min:record.threshold_parameter[0].value,
                        max:record.threshold_parameter[3].value,
                        step:record.threshold_parameter[4].value,
                        value:[record.threshold_parameter[5].value,record.threshold_parameter[6].value]
                    });
                   }
                   else
                   {
                    record.alert_name = settings.alert_name;
                    threshold.fields.push({
                        name: record._id,
                        label:record.alert_name,
                        required: true,
                        type: "slider",
                        display_type:"on",
                        min:record.threshold_parameter[0].value,
                        max:record.threshold_parameter[1].value,
                        step:record.threshold_parameter[2].value,
                        value:record.threshold_parameter[3].value
                    });
                   }
                   
                });
                this.setState({ ...this.state, formThreshold: threshold });
            })
            .catch((err)=> {
                this.setState({ ...this.state, error: err.error });
            });
    }
    constructor(props) {
        super(props);
        this.renderThreshold(props)
    }
    componentDidUpdate(prevProps) {
        const { t } = this.props;
        if (prevProps.i18n.language!==this.state.language) {
            this.setState({ ...this.state, language: prevProps.i18n.language });
            this.renderThreshold(this.props)
    
        }
    }
    render() {
        const { t } = this.props;
        if (!this.state.formThreshold)
            return (
                <div className="loader"/>
            );
        if (this.state.error.length) {
            return (
                <Box bgcolor="error.main" color="error.contrastText" p={2} m={1}>
                    {this.state.error}
                </Box>
            )
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs>
                    <Form options={this.state.formThreshold} title={t('Alert Threshold')}/>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation('common')(AlertThreshold);
