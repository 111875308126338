import React from "react";
import Dashboard from 'components/Dashboard';
import Form from 'components/Form';

import {Entity} from "utils/services/entity";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import { withTranslation } from 'react-i18next';
class PatientEdit extends React.Component {
    state = { form: { fields: [] }, loading: true };
    
    
    getPatient(patient_id) {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: "patients",
                entity_id: patient_id,
                query: {
                    fields: { collector_number: 1 }
                }
            };
            Entity.get(options)
                .then(response=> {
                    if (response.status !== 200) return this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator", loading: false });

                    const data = response.data;
                    return resolve(data);
                })
                .catch(err=> {
                    this.setState({ ...this.state, error: "Something Went Wrong, Please contact with administrator", loading: false })
                })
        })
    }

    constructor(props) {
        super(props);
        const patient_id = props.match.params.id;
    

        this.getPatient(patient_id)
            .then((patient)=> {
                const form = {
                    onSubmit: this._onSubmit,
                    submitButtonText: "Modify",
                    buttonWidth: '95%',
                    successRedirect: "/patients",
                    fields: [
                        {
                            name: "collector_number",
                            label: "Collector Number",
                            required: true,
                            width: "90%",
                            type: "text",
                            value: patient.collector_number
                        }
                    ]
                };
                this.setState({ ...this.state, form, loading: false });
            })
    }


    _onSubmit() {
        return new Promise((resolve, reject)=> {
            const data = JSON.parse(JSON.stringify(this.state.data));
            const { t } = this.props;
            data["patient_id"] = this.parameters;
            data["collector_number"]=data["collector_number"].toLowerCase();

            const option = {
                entity: "patients",
                data
            };
            Entity.call("modifyPatientCollectorId", option)
                .then(response=> {
                    resolve({message: t("Modified Successfully")});
                })
                .catch(err=> {
                    reject({message: err.error});
                })
        })
    }

    render() {
        const { t } = this.props; 
        const inclusionId = (this.state.patient_details || {}).inclusion_id;
        if (this.state.loading) {
            return (
                <Dashboard title={t("Patients")} inclusionId = {inclusionId}>
                    <div className="loader"/>
                </Dashboard>
            );
        }

        if (this.state.error)
            return (
                <Dashboard title={t("Patients")} inclusionId = {inclusionId}>
                    <Snackbar anchorOrigin={
                        { vertical: "top", horizontal: "right" }
                    }
                              open={true}>
                        <SnackbarContentWrapper variant={"error"}
                                                message={this.state.error} />
                    </Snackbar>
                </Dashboard>
            );
        const patient_id = this.props.match.params.id;

        return (
            <Dashboard title={t("Patients")} inclusionId = {inclusionId}>
                <Form options={this.state.form} parameters={patient_id}/>
            </Dashboard>
        )
    }

}

export default (withTranslation('common')(PatientEdit));