import React from "react";
import Form from "components/Form";
import {Grid, Box, withStyles} from "@material-ui/core";
import {Entity} from "utils/services/entity";
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const getReferenceSettings = (t) => ({
    referencetemperature: {
        label: `${t('Reference Temperature')} (°C)`,
        min: 32.5,
        max: 37.5,
        step: .1,
    },
    referenceweight: {
        label: `${t('Reference Weight')} (Kg)`,
        min: 30,
        max: 120,
        step: .5,
    },
});

const getReferences = (id) => {
    const options = {
        entity: "patients",
        data: {
            "inclusion_id": id
        }
    };
    const weightPromise = Entity.call('ref_weight', options);
    const temperaturePromise = Entity.call('ref_temperature', options);

    return new Promise((resolve, reject) => {
        Promise.all([weightPromise, temperaturePromise])
            .then((res)=> {
                const references = [
                    { name: 'referenceweight', value: res[0].data.referenceweight },
                    { name: 'referencetemperature', value: res[1].data.referencetemperature },
                ];
                resolve(references);
            })
            .catch((err)=> {
                reject(err);
            });
        });
}

const setReferences = (id, referenceweight, referencetemperature) => {
    const promiseArray = [];
    if (referenceweight) {
        const options = {
            entity: 'patients',
            data: { InclusionId: id, referenceweight }
        };
        const weightPromise = Entity.call('ref_weight_update', options);
        promiseArray.push(weightPromise);
    }
    if (referencetemperature) {
        const options = {
            entity: 'patients',
            data: { InclusionId: id, referencetemperature }
        };
        const temperaturePromise = Entity.call('ref_temperature_update', options);
        promiseArray.push(temperaturePromise);
    }
    return new Promise((resolve, reject) => {
        Promise.all(promiseArray)
            .then(()=> {
                resolve();
            })
            .catch((err)=> {
                reject(err);
            });
        });
}

class ReferenceIndicator extends React.Component {
    constructor(props) {
        super(props);
        this.unsavedReferenceValues = {};
        this.state = {
            error: ''
        };
        getReferences(props.patient_details.inclusion_id)
            .then((references)=> {
                this.references = references;
                this.constructReferenceValues();
            })
            .catch((err)=> {
                this.setState({ ...this.state, error: err.error });
            });
    }

    constructReferenceValues = () => {
        const references = this.references;
        const referenceSettings = getReferenceSettings(this.props.t);
        const referenceValues = [];
        references.forEach((item)=> {
            const settings = referenceSettings[item.name];
            const label = settings.label;
            const value = item.value ? String(item.value) : this.props.t('No data available');
            referenceValues.push({
                name: item.name,
                label,
                value,
            });
        });
        this.setState({ referenceValues, edit: false });
    }

    constructFormObject = () => {
        const references = this.references;
        const referenceSettings = getReferenceSettings(this.props.t);
        const threshold = {
            fields: [],
            onChange: this.onChange,
            onSubmit: this.saveReference,
            submitButtonText: 'Save Reference Indicators',
            buttonWidth: 'auto',
        };
        references.forEach((item)=> {
            if (!item.value) return; 
            const settings = referenceSettings[item.name];
            const label = settings.label;
            const min = settings.min;
            const max = settings.max;
            const step = settings.step;
            const value = String(item.value);

            threshold.fields.push({
                name: item.name,
                label,
                value,
                type: 'slider',
                min,
                max,
                step,
                display_type:"on",
                marks: true
            })
        });
        this.setState({ formThreshold: threshold, edit: true });
    }

    editReference = () => {
        this.constructFormObject();
    }

    onChange = (change) => {
        const { data } = change;
        this.unsavedReferenceValues[data.name] = data.value;
    }

    saveReference = () => {
        const inclusion_id = this.props.patient_details.inclusion_id;
        let newWeight = null;
        let newTemperature = null;
        if (this.unsavedReferenceValues.referenceweight !== undefined) {
            newWeight = this.unsavedReferenceValues.referenceweight;
        }
        if (this.unsavedReferenceValues.referencetemperature !== undefined) {
            newTemperature = this.unsavedReferenceValues.referencetemperature;
        }

        setReferences(inclusion_id, newWeight, newTemperature)
            .then(() => {
                if (newWeight) {
                    this.references[0].value = newWeight;
                }
                if (newTemperature) {
                    this.references[1].value = newTemperature;
                }
                this.constructReferenceValues()
            })
            .catch((err)=> {
                this.setState({ error: err.error });
            });
    }

    renderReferenceValues = () => {
        const referenceValues = this.state.referenceValues;
        const { t } = this.props;
        return (
            <div>
                <Typography variant="h5" component="h5" style={{ paddingBottom: '30px', textShadow: '2px 2px 4px blue' }}>
                    {t("Reference Indicator")}
                </Typography>
                <Grid container justify="center">
                    <Grid item xs={12} sm={8} md={7} lg={6}>
                        {
                            referenceValues.map(item => (
                                <div key={item.label} style={{textAlign: 'left', padding: '20px 0', color: '#3f51b5'}}>
                                    <span>{item.label}</span>
                                    <span style={{float: 'right'}}>{item.value}</span>
                                </div>
                            ))
                        }
                        <div style={{padding: '30px 0'}}>
                            <Button style={{width: 'auto'}} onClick={this.editReference} variant="contained" color="primary">
                                {t("Edit Reference Indicators")}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        const { t } = this.props;
        if (this.state.error && this.state.error.length) {
            return (
                <Box bgcolor="error.main" color="error.contrastText" p={2} m={1}>
                    {this.state.error}
                </Box>
            )
        }
        if (!this.references)
            return (
                <div className="loader"/>
            );
        return (
            <Grid container spacing={3}>
                <Grid item xs>
                    {!this.state.edit && this.renderReferenceValues()}
                    {this.state.edit &&
                        <Form options={this.state.formThreshold} title={t('Reference Indicator')}/>
                    }
                </Grid>
            </Grid>
        )
    }
}

const useStyles = (theme) => ({
    title: {
        padding: theme.spacing(1.5),
        fontSize : '1rem',
        textAlign: 'justify',
        display : 'flex',
        flexGrow: 1,
        flexWrap: 'nowrap'
    }
});

export default withStyles(useStyles)(withTranslation('common')(ReferenceIndicator));
