import React from 'react';

import Dashboard from 'components/Dashboard'
import Form from 'components/Form';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                onSubmit: this._onSubmit,
                submitButtonText: "Contact Us",
                buttonWidth: "45%",
                fields: [
                    {
                        name: "first_name",
                        label: "First Name",
                        required: true,
                        autofocus: true,
                        width: "45%",
                        type: "text"
                    },
                    {
                        name: "last_name",
                        label: "Last Name",
                        required: true,
                        width: "45%",
                        type: "text"
                    },
                    {
                        name: "email_id",
                        label: "Email Address",
                        required: true,
                        width:"93%",
                        type: "text"
                    },
                    {
                        name: "message",
                        label: "Message",
                        required: true,
                        width: "93%",
                        type: "multiline"
                    },
                ]
            }
        }
    }

    _onSubmit() {
        return new Promise((resolve, reject)=> {
            console.log("in submit function");    
        
        });
    }

    render() {
        return (
            <Dashboard title="Contact Us">
                 <Form options={this.state.form} title="Contact Us"/>
            </Dashboard>
         );
    }
}

export default ContactUs;
