import React, {Component} from 'react';
import clsx from 'clsx';
import {
    CssBaseline,
    Drawer,
    AppBar,
    Popover,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Snackbar
} from "@material-ui/core"

import {withStyles} from '@material-ui/core/styles';

import {isMobileBrowser, isTabBrowser} from "utils/common";

import {
    Input as InputIcon,
    Menu as MenuIcon,
    ArrowBack,
} from "@material-ui/icons";
import LanguageSelector from '../LanguageSelector'
import Sidebar from "./components/Sidebar";
import NotificationList from './components/NotificationList';

import {Entity} from "utils/services/entity"
import {HepadomEntity} from "utils/services/hepadom_entity"
import configData from "../../common.json";

import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import Paper from '@material-ui/core/Paper';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';

const drawerWidth = 270;

const useStyles = (theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#3f51b5',
            outline: '1px solid slategrey'
        }
    },
    root: {
        display: 'flex',
        height: "100%",
        width: "100%",
        flexGrow: 1,
    },
    toolbar: {
        minHeight: 'auto',
        width: '100%'
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        //...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingLeft: "0 !important"
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: "#fff"
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        marginTop: '48px',
        backgroundColor: theme.palette.background.default,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: `100%`,
        overflowY: "scroll"
    },
    contentShiftBar: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    paperChildren: {
        padding: theme.spacing(2),
        margin: "auto",
        maxWidth: "95%",
        marginTop: "2%",
        textAlign: "center"
    },
    fixedHeight: {
        height: 240,
    },
    avatar: {
        width: '100px',
        height: '100px',
    },
    profile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content',
        fontWeight: 500,
    },
    signOutButton: {
        marginLeft: theme.spacing(1)
    },
    languageselector:{
        marginLeft: theme.spacing(3)
    },
    sidebar: {},
    drawerPaper: {
        zIndex: 1200,
        width: '271px'
    },
    notificationsButton: {
        marginLeft: 'auto',
        color: "#fff"
    },
});

class Dashboard extends Component {

    constructor(props) {
        super(props);
        const isTab = isTabBrowser();
        const isMobile = isMobileBrowser();
        let open = true;
        let drawer = "persistent";
        if (isTab || isMobile) {
            open = false;
            drawer = "temporary"
        }
        this.state = {
            SelectedEntity : window.location.pathname.includes(configData.clinical_study.HEPADOM_CLINICAL_STUDY) ? HepadomEntity : Entity,
            drawer,
            open,
            openSnackbar: false,
            notificationsEl: null,
            notifications: [
                {
                    id: 'DEV853890',
                    title: 'Tele-consultation with Dr.Barton',
                    when: "2 hours from now",
                    type: 'user'
                },
                {
                    id: 'DEV716627',
                    title: 'Elevated Temperature',
                    when: '2 hours ago',
                    type: 'order'
                },
                {
                    id: 'DEV897704',
                    title: 'Tele-consultation confirmed',
                    when: '1 day ago',
                    type: 'product'
                },
                {
                    id: 'DEV604714',
                    title: 'New feature has been added',
                    when: '2 day ago',
                    type: 'feature'
                }
            ],
            notificationsCount: 4
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
    }

    handleWindowSizeChange() {
        const isTab = isTabBrowser();
        const isMobile = isMobileBrowser();
        let open = true;
        let drawer = "persistent";
        if (isTab || isMobile) {
            open = false;
            drawer = "temporary"
        }
        this.setState({...this.state, open, drawer});
    }

    signOut() {
        const options = {
            entity: "users"
        };
        this.state.SelectedEntity.call("logout", options)
            .then(() => {
                this.setState({...this.state, openSnackbar: true});
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
    }


    onToggleSidebar() {
        const {open} = this.state;
        this.setState({...this.state, open: !open});
    }

    handleShowNotifications = event => {
        this.setState({
            notificationsEl: event.currentTarget
        });
    };

    handleCloseNotifications = () => {
        this.setState({
            notificationsEl: null
        });
    };

    render() {
        const {t, children, classes, title, disablePaper, user, inclusionId } = this.props;
        const formattedInclusionId = inclusionId ? ` ${inclusionId})` : '';


        const showNotifications = Boolean(this.state.notificationsEl);
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar position="fixed"
                        className={clsx(classes.appBar, this.state.open && this.state.drawer === "persistent" && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            className={classes.menuButton}
                            onClick={this.onToggleSidebar.bind(this)}
                            variant="text">
                            {this.state.open ? <ArrowBack/> : <MenuIcon/>}
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {title} {formattedInclusionId}
                        </Typography>
                        {user.role === "patient" || user.role === "doctor" ? <IconButton
                            className={classes.notificationsButton}
                            onClick={this.handleShowNotifications}
                        >
                            {/* <Badge
                                badgeContent={this.state.notificationsCount}
                                color="secondary"
                            >
                                <NotificationsIcon/>
                            </Badge> */}
                        </IconButton> : null}
                        {window.location.pathname.includes(configData.clinical_study.HEPADOM_CLINICAL_STUDY) ?  <LanguageSelector className={classes.languageselector}/> :<div />}
                        <Tooltip title= {t("Logout")} aria-label="Logout">
                            <IconButton color="inherit"
                                        className={classes.signOutButton}
                                        onClick={this.signOut.bind(this)}
                            >
                                <InputIcon/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.drawerPaper}}
                    open={this.state.open}
                    onClose={this.onToggleSidebar.bind(this)}
                    variant={this.state.drawer}>
                    <Sidebar className={classes.sidebar}/>
                </Drawer>
                <main
                    className={clsx(classes.content, this.state.open && this.state.drawer === "persistent" && classes.contentShiftBar)}>
                    {!disablePaper ? <Paper className={classes.paperChildren}>{children}</Paper> : <div className={classes.paperChildren}>{children}</div>}
                </main>
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                    open={this.state.openSnackbar}
                    autoHideDuration={5000}
                >
                    <SnackbarContentWrapper
                        variant= {"success"}
                        message= {t("Logout Successfully")}
                    />
                </Snackbar>
                <Popover
                    anchorEl={this.state.notificationsEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    onClose={this.handleCloseNotifications}
                    open={showNotifications}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <NotificationList
                        notifications={this.state.notifications}
                        onSelect={this.handleCloseNotifications}
                    />
                </Popover>
            </div>
        );
    }
}

export const mapStateToProps = state => () => {
    return ({
        user: state.user,
    });
};

export default connect(mapStateToProps)(withStyles(useStyles)(withTranslation('common')(Dashboard)));

