import React, {Component} from 'react';

import { withStyles, Grid } from '@material-ui/core';

import Layout from 'components/Layout';
import Form from 'components/Form';
import Routing from 'utils/routing';

import { Entity } from 'utils/services/entity';
import { withTranslation } from 'react-i18next';
import loginImage from '../../../images/login.jpg';
import configData from "../../../common.json";
const {Link} = Routing;

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        // backgroundImage: 'url(https://40.89.185.245/login.jpg)',
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
});

class SignIn extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.form = {
            onSubmit: this._onSubmit,
            submitButtonText: "Sign In",
            fields: [
                {
                    name: "user_name",
                    label: "User Name",
                    autofocus: true,
                    required: true,
                    type: "text",
                    width: "100%"
                },
                {
                    name: "password",
                    label: "Password",
                    required: true,
                    type: "password",
                    width: "100%"
                },
                {
                    name: "clinical_study",
                    label: "Name of the clinical study",
                    required: true,
                    width: "70%",
                    type: "select",
                    options: [
                        {
                            key: "Multidom", value: "multidom"
                        },
                        {
                            key: "Hepadom", value: "hepadom"
                        }

                    ]
                },
            ]
        }
    }

    _onSubmit() {
        return new Promise((resolve, reject)=> {
            const data = this.state.data;
            const { t } = this.props;
            const options = {
                entity: "users",
                data
            };
            Entity.call("login", options)
                .then((response)=> {
                    resolve(response);
                    setTimeout(()=> {
                        window.location.reload();
                        const language=(data.clinical_study===configData.clinical_study.HEPADOM_CLINICAL_STUDY ? response.data.language  :'fr');
                        this.props.i18n.changeLanguage(language)
                        window.location.reload();
                    }, 1000);

                })
                .catch((err)=> {
                    err.message = t(err.error);
                    reject(err);
                });
        });
    }

    render() {
        const {t, classes } = this.props;

        return (
            <Layout classes={classes} text={t("Sign In")}>
                <Form options={this.form}/>
                <Grid container style={{marginTop: "1%"}}>
                    <Grid item>
                        <Link
                              variant="body2" to="/forgot-password">
                            {t("Forgot password?")}
                        </Link>
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default withStyles(useStyles)(withTranslation('common')(SignIn));
