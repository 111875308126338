import React from 'react';
import { withStyles, Radio, Typography, Grid } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Entity } from "utils/services/entity";
import styles from './styles';


class RadioGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }
    getData(options) {
        return new Promise((resolve, reject)=> {
            Entity.list(options)
                .then((response)=> {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch((err)=> {
                    reject(err);
                })
        });
    }

    componentDidMount() {
        const { field } = this.props;
        if (field.resolve && typeof field.resolve === "object" && Object.keys(field.resolve).length) {
            const { entity, fields, filters } = field.resolve;
            const options = {
                entity,
                query: {
                    fields,
                    filters
                }
            };
            this.getData(options)
                .then((records)=> {
                    const options = records.map((record)=> { return { key: record[field.resolve.key], value: record[field.resolve.value] } });
                    this.setState({ ...this.state, options });
                })
                .catch((err)=> {
                    // do nothing
                })
        }
    }

    render() {
        let { field, onChange, classes, value, disabled, align } = this.props;
        const options = field.options || this.state.options;
        let row = false;
        if (align === "horizontally") row = true;
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    {field.label && <Grid item xs={12} lg md sm={12}>
                        <Typography variant="subtitle2" color ="primary"
                            className={classes.questions}>{field.label}</Typography>
                    </Grid>}
                    <Grid item xs={12} lg md sm={12}>
                        <RadioGroup className={classes.buttons}
                            aria-label="position"
                            name={field.name}
                            onChange={onChange} row={row}>
                            {options.map((choice, index) => (<FormControlLabel
                                value={choice.value}
                                control={<Radio
                                    color="primary"
                                    value={choice.value}
                                    label={choice.label}
                                    disabled={disabled}
                                />}
                                key={index}
                                checked={value === choice.value}
                                label={choice.label || choice.value}
                            />))}
                        </RadioGroup>                                        
                     {field.helpertext && 
                        <p style={{textAlign: "left",color:"#1976d2"}}
                            >{field.helpertext}</p>
                     }
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(RadioGroups);
