import React from "react";
import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import moment from "moment";
import { getSeries, graphColor } from '../../utils/common'

function getData(props) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "sensors",
            query: {
                name: "sensor_wearing",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        Entity.call("graph", options)
            .then(res => {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err => {
                reject();
            })
    })
}

class SensorWearingVisualization extends React.Component {
    state = { data: [], options: {} };
    constructor(props) {
        super(props);
        getData(props)
            .then(records => {

                const options = {
                    title: 'Port du capteur',
                    vAxis: {
                        title: '',
                        format: '0'
                    },
                    hAxis: {
                        type: "category"

                    },
                    legend: { position: 'top', alignment: 'end' },

                };


                // records = getSeries(records);
                // console.log("Record::::", records)
                records = records.map((record) => {
                    record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm')
                    return record;
                });
                records.unshift(["Time", "0 : Capteur non porté / 1 : Capteur porté"]);

                this.setState({ ...this.state, data: records, options });
            })
            .catch(err => {

                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    },]
                ];
                const options = {
                    title: 'Port du capteur (Aucune donnée disponible)',
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({ ...this.state, data: records, options });
            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.graphDate !== this.props.graphDate) {
            getData(this.props)
                .then(records => {

                    const options = {
                        title: 'Port du capteur',
                        vAxis: {
                            title: '',
                            format: '0'
                        },
                        hAxis: {
                            type: "category"
                            // title: moment(records[0][0]).format('LL')
                        },
                        legend: { position: 'top', alignment: 'end' },
                    };
                    // records = getSeries(records);
                    // console.log('Record', records)
                    records = records.map((record) => {
                        record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm')
                        return record;
                    });
                    records.unshift(["Time", "0 : Capteur non porté / 1 : Capteur porté"]);

                    this.setState({ ...this.state, data: records, options });
                })
                .catch(err => {

                    const records = [
                        [{
                            f: 'Date',
                            type: 'date'
                        }, {
                            f: 'Line',
                            type: 'number'
                        },]
                    ];
                    const options = {
                        title: 'Port du capteur (Aucune donnée disponible)',
                        series: {
                            0: {
                                color: 'transparent'
                            }
                        }
                    };
                    this.setState({ ...this.state, data: records, options });
                })
        }

    }

    render() {
        if (!this.state.data.length) return <div className="loader" />;
        return (
            <Chart
                chartType="LineChart"
                loader={<div>Poids</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '1' }}
            />
        )
    }
}

export default SensorWearingVisualization;

