import React from 'react';
import Form from 'components/Form';
import { Entity } from 'utils/services/entity';
import moment from "moment";
import { withTranslation } from 'react-i18next';
function getPatientDetails(_id) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "patients",
            entity_id: _id
        };
        Entity.get(options)
            .then((response) => {
                const data = response.data;
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            })
    })
}

class Protocol extends React.Component {
    state = {};
    constructor(props) {
        super(props);
        const { patient_details } = props;
        getPatientDetails(patient_details._id)
            .then((patient_details) => {
                let theoretical_date_of_visit_1 = patient_details.theoretical_date_of_visit_1;
                let theoretical_date_of_visit_2 = patient_details.theoretical_date_of_visit_2;
                let theoretical_date_of_visit_3 = patient_details.theoretical_date_of_visit_3;
                let theoretical_date_of_visit_4 = patient_details.theoretical_date_of_visit_4;
                let theoretical_date_of_visit_5 = patient_details.theoretical_date_of_visit_5;
                let theoretical_date_of_chemotheraphy_1 = patient_details.theoretical_date_of_chemotheraphy_1;
                let theoretical_date_of_chemotheraphy_2 = patient_details.theoretical_date_of_chemotheraphy_2;
                let theoretical_date_of_chemotheraphy_3 = patient_details.theoretical_date_of_chemotheraphy_3;
                const comments = patient_details.comments || '';
                theoretical_date_of_visit_1 = moment(theoretical_date_of_visit_1).format("YYYY-MM-DD");
                if (theoretical_date_of_visit_2) theoretical_date_of_visit_2 = moment(theoretical_date_of_visit_2).format("YYYY-MM-DD");
                if (theoretical_date_of_visit_3) theoretical_date_of_visit_3 = moment(theoretical_date_of_visit_3).format("YYYY-MM-DD");
                if (theoretical_date_of_visit_4) theoretical_date_of_visit_4 = moment(theoretical_date_of_visit_4).format("YYYY-MM-DD");
                if (theoretical_date_of_visit_5) theoretical_date_of_visit_5 = moment(theoretical_date_of_visit_5).format("YYYY-MM-DD");
                if (theoretical_date_of_chemotheraphy_1) theoretical_date_of_chemotheraphy_1 = moment(theoretical_date_of_chemotheraphy_1).format("YYYY-MM-DD");
                if (theoretical_date_of_chemotheraphy_2) theoretical_date_of_chemotheraphy_2 = moment(theoretical_date_of_chemotheraphy_2).format("YYYY-MM-DD");
                if (theoretical_date_of_chemotheraphy_3) theoretical_date_of_chemotheraphy_3 = moment(theoretical_date_of_chemotheraphy_3).format("YYYY-MM-DD");
                const form = {
                    onSubmit: this._onSubmit,
                    submitButtonText: "Save",
                    buttonWidth: "60%",
                    fields: [
                        {
                            name: "theoretical_date_of_visit_1",
                            label: "Visit 1",
                            required: true,
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_visit_1
                        },
                        {
                            name: "theoretical_date_of_visit_2",
                            label: "Visit 2",
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_visit_2

                        },
                        {
                            name: "theoretical_date_of_chemotheraphy_1",
                            label: "Chemotheraphy 1",
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_chemotheraphy_1
                        },
                        {
                            name: "theoretical_date_of_visit_3",
                            label: "Visit 3",
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_visit_3
                        },
                        {
                            name: "theoretical_date_of_chemotheraphy_2",
                            label: "Chemotheraphy 2",
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_chemotheraphy_2
                        },
                        {
                            name: "theoretical_date_of_visit_4",
                            label: "Visit 4",
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_visit_4
                        },
                        {
                            name: "theoretical_date_of_chemotheraphy_3",
                            label: "Chemotheraphy 3",
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_chemotheraphy_3
                        },
                        {
                            name: "theoretical_date_of_visit_5",
                            label: "Visit 5",
                            width: "45%",
                            type: "datepicker",
                            value: theoretical_date_of_visit_5
                        },
                        {
                            name: "comments",
                            label: "Comments",
                            type: "textarea",
                            value: comments
                        }
                    ]
                };
                this.setState({ ...this.state, form });
            })
            .catch(err => {
                this.setState({ ...this.state, error: err.error, form: {} });
            })
    }

    _onSubmit() {
        return new Promise((resolve, reject) => {
            const { t } = this.props;
            const parameters = this.parameters;
            const data = this.state.data;
            const options = {
                entity: "patients",
                entity_id: parameters._id,
                data
            };
            Entity.edit(options)
                .then((response) => {
                    resolve({ message: t("Enregistrement réussi") });
                })
                .catch((err) => {
                    reject({ message: err.error });
                })
        });
    }
    render() {
        if (!this.state.form) return <div className="loader" />;
        const { patient_details } = this.props;
        return (
            <Form options={this.state.form} title="" parameters={{ ...patient_details }} />
        );
    }

}

export default withTranslation('common')(Protocol);
