import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';

import {
    AccountBoxOutlined as AccountBoxIcon,
    FormatListBulleted as Questionnaire,
    CalendarTodayOutlined as Calendar,
} from '@material-ui/icons';

import {
    Divider
} from '@material-ui/core';

import styles from './styles';

import Routing from 'utils/routing';
import {getEntity} from '../../../../utils/common';

const {Link} = Routing;

class Items extends React.Component {
    state = {listItems: []};

    componentDidMount() {
        const {user} = this.props;
        
        let listItems = [];
        const pathName = window.location.pathname;
        switch (user.role) {
            case 'hepadom_admin':
                listItems = [{
                    link: '/hepadom/patients',
                    name: 'Patients',
                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/patient") > -1)
                },
                    {
                        link: '/hepadom/doctors',
                        name: getEntity() === 'hepadom' ? 'On-Call Surgeon' : 'Doctor',
                        icon: <PeopleIcon/>,
                        selected: (pathName.indexOf("/doctor") > -1)
                    },
                    {
                        link: '/hepadom/nurses',
                        name: getEntity() === 'hepadom' ? 'IPA' : 'Nurse',
                        icon: <PeopleIcon/>,
                        selected: (pathName.indexOf("/nurse") > -1)
                    },
                    {
                        link: '/hepadom/delete_patient',
                        //name: 'Complete Clinical Study',
                        name: 'Return of material',
                        icon: <PeopleIcon/>,
                        selected: (pathName.indexOf("/delete_patient") > -1)
                        
                    },
                    {
                        link: '/hepadom/account',
                        name: 'Account',
                        icon: <AccountBoxIcon/>,
                        selected: (pathName.indexOf("/account") > -1)
                    },
                    
                ];
                break;
            case 'hepadom_patient':
                listItems = [
                    {
                        link: '/hepadom/questionnaires',
                        name: 'Questionnaires',
                        icon: <Questionnaire/>,
                        selected: (pathName.indexOf("/questionnaires") > -1)
                    },
                    {
                        link: '/hepadom/calender',
                        name: 'Calendar',
                        icon: <Calendar/>,
                        selected:(pathName.indexOf("/calender") > -1)

                    },
                ];
                break;
            case 'hepadom_doctor':
                listItems = [{
                    link: '/hepadom/patients',
                    name: 'Patients',
                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/patients") > -1)
                },
                {
                    link: '/hepadom/calender',
                    name: 'Calendar',
                    icon: <Calendar/>,
                    selected:(pathName.indexOf("/calender") > -1)

                },

                {
                        link: '/hepadom/profile',
                        name: 'Account',
                        icon: <AccountBoxIcon/>,
                        selected: (pathName.indexOf("/profile") > -1)
                },

                ];
                break;
            case 'hepadom_nurse':
                listItems = [{
                    link: '/hepadom/patients',
                    name: 'Patients',
                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/patients") > -1)
                },

                    {
                        link: '/hepadom/profile',
                        name: 'Account',
                        icon: <AccountBoxIcon/>,
                        selected: (pathName.indexOf("/profile") > -1)
                    }
                ];
                break;
            case 'hepadom_superadmin':
                listItems = [{
                    link: '/hepadom/admin',
                    name: getEntity() === 'hepadom' ? 'Investigating On-Call Surgeon' : 'Investigating Doctor',
                    

                    icon: <PeopleIcon/>,
                    selected: (pathName.indexOf("/admin") > -1)
                }];
                break;
            default:
                listItems = [];
        }

        this.setState({...this.state, listItems});
    }

    render() {
        const { t,classes} = this.props;
        
        return (
            <React.Fragment>
                <List
                    component="div"
                    disablePadding
                >
                    {this.state.listItems.map((item, index) =>
                        <ListItem
                            selected={item.selected}
                            activeclassname={classes.activeListItem}
                            className={classes.listItem}
                            component={Link}
                            to={item.link}
                            key={index}
                        >
                            <ListItemIcon activeclassname={classes.activeListItemIcon} className={classes.listItemIcon}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText activeclassname={classes.activeListItemText}
                                          classes={{primary: classes.listItemText}}
                                          primary={t(item.name)}
                            />
                        </ListItem>
                    )}
                </List>
                <Divider className={classes.listDivider}/>
                
            </React.Fragment>


        );
    }
}


ListItem.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object
};

export const mapStateToProps = state => () => {
    return ({
        user: state.user,
    });
};


export default connect(mapStateToProps)(withStyles(styles)(withTranslation('common')(Items)));
