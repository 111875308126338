import { withStyles } from '@material-ui/core';
import Dashboard from 'components/Dashboard';
import Form from 'components/Form';
import React, { Component } from 'react';
import { Entity } from 'utils/services/entity';
import { withTranslation } from 'react-i18next';


const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        overflow: 'auto',
        margin: theme.spacing(4, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
});

class AddPatient extends Component {
    state = {};

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.form = {
            onSubmit: this._onSubmit,
            submitButtonText: "Register",
            buttonWidth: '93.5%',
            successRedirect: "/patients",
            fields: [
                {
                    name: "user_name",
                    label: "User Name",
                    required: true,
                    width: "45%",
                    type: "text"

                },
                {
                    name: "password",
                    label: "Password",
                    required: true,
                    width: "45%",
                    validate: [
                        {
                            type: "regex",
                            value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})",
                            message: "Password must contains a capital letter, number, special character and minimum length of eight"
                        }
                    ],
                    type: "password"
                },
                {
                    name: "confirm_password",
                    label: "Confirm Password",
                    required: true,
                    width: "45%",
                    type: "password"
                },
                {
                    name: "collector_number",
                    label: "Collector Number",
                    value: "0000000200xx0001",
                    required: true,
                    width: "45%",
                    type: "text",

                  
                },
                {
                    name: "inclusion_id",
                    label: "Inclusion Number",
                    required: true,
                    width: "45%",
                    type: "text",
                },
                {
                    name: "clinical_study",
                    label: "Name of the clinical study",
                    required: true,
                    width: "45%",
                    type: "select",
                    options: [
                        {
                            key: "Multidom", value: "multidom"
                        }

                    ]
                },
                {
                    name: "consulting_doctor_name",
                    label: 'Consulting Doctor Name',
                    required: true,
                    resolve: {
                        entity: "doctors",
                        fields: { first_name: 1, _id: 1, encrypted: 1 },
                        key: "first_name",
                        value: "_id",
                    },
                    width: "45%",
                    type: "multiselect",

                },
                {
                    name: "consulting_nurse_name",
                    label: 'Consulting Nurse Name',
                    required: true,
                    resolve: {
                        entity: "nurses",
                        fields: { first_name: 1, _id: 1, encrypted: 1 },
                        key: "first_name",
                        value: "_id",
                    },
                    width: "45%",
                    type: "multiselect",

                },
                {
                    name: "type_of_equipments",
                    label: "Type of equipments",
                    required: true,
                    width: "45%",
                    type: "multiselect",
                    options: [
                        {
                            key: t("Actimetry/skin temperature sensor"), value: t("Actimetry/skin temperature sensor")
                        },
                        {
                            key: t("Shirt wearing sensor"), value: t("Shirt wearing sensor")
                        },
                        {
                            key: t("bra wearing sensor"), value: t("bra wearing sensor")
                        },

                        {
                            key: t("Weight scale"), value: t("Weight scale")
                        },
                        {
                            key: t("Tensiometer"), value: t("Tensiometer")
                        },
                        {
                            key: t("Pulse Oximeter"), value: t("Pulse Oximeter")
                        },
                        {
                            key: t("Collector box"), value: t("Collector box")
                        },
                        {
                            key: t("Touch pad"), value: t("Touch pad")

                        },
                    ]
                },
                {
                    name: "date_of_equipment_transmission",
                    label: "Date of equipment Transmission",
                    required: true,
                    width: "45%",
                    type: "datepicker"
                },
                {
                    name: "theoretical_date_of_visit_1",
                    label: "Theoretical Date Of Visit 1",
                    required: true,
                    width: "45%",
                    type: "datepicker"
                },

            ]
        };
    }
 

    _onSubmit() {
        return new Promise((resolve, reject) => {
     
            const { t } = this.props;
            if (this.state.data.password !== this.state.data.confirm_password) {
                return reject({ message: t("Password and Confirm Password should match") });
            }
           
            var regexp = /^[0-9a-fA-F]+$/;
            const result = encodeURI(this.state.data.collector_number.trim()).split(/%..|./).length - 1;
            var reg = /^-?\d*\.?\d*$/;
           // console.log("inclusion"+this.state.data.inclusion_id.length);
            if (!(((this.state.data.inclusion_id).trim()).length==5)||!(reg.test(this.state.data.inclusion_id.trim()))) {
                return reject({ message:t("The inclusion number must contain 5 digits") });
            }
            if (!(regexp.test(this.state.data.collector_number.trim()))||!(result==16)) {
                return reject({ message:t("The collector number must contain 16 hexadecimal digits") });
            }
            if (this.state.data.consulting_doctor_name.length === 0 ) {
                return reject({ message: t("Consulting Doctor Name is required") });
            }
            if (this.state.data.consulting_nurse_name.length === 0 ) {
                return reject({ message: t("Consulting Nurse Name is required") });
            }

            const data = JSON.parse(JSON.stringify(this.state.data));
            data.collector_number=data.collector_number.toLowerCase().trim();
            delete data.confirm_password;
 
            const options = {
                entity: "patients",
                data
            };
            Entity.add(options)
                .then((response) => {
                    resolve({ message: t("Register Successfully") });
                })
                .catch((err) => {
                    reject({ message: err.error });
                })
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Dashboard title={t("Patients")}>
                <Form options={this.form} title={t("PATIENT REGISTRATION")} />
                <h3>{t("Password is only known by the patient and must be well memorized")}</h3>
            </Dashboard>

        )
    }
}

export default withStyles(useStyles)(withTranslation('common')(AddPatient));
