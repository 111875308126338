import { withStyles } from '@material-ui/core';
import Dashboard from 'components/Dashboard';
import Form from 'components/Form';
import React, { Component } from 'react';
import moment from "moment";
//import { Entity } from 'utils/services/entity';
import { HepadomEntity } from 'utils/services/hepadom_entity';
import { withTranslation } from 'react-i18next';

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        overflow: 'auto',
        margin: theme.spacing(4, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
});

class AddPatient extends Component {
    state = {};

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.form = {
            onSubmit: this._onSubmit,
            submitButtonText: "Register",
            buttonWidth: '93.5%',
            successRedirect: "/hepadom/patients",
            fields: [
                {
                    name: "user_name",
                    label: "User Name",
                    required: true,
                    width: "100%",
                    marginRight: "0px",
                    type: "text"

                },
                {
                    name: "password",
                    label: "Password",
                    required: true,
                    width: "50%",
                    marginRight: "0px",
                    paddingRight: "40px",
                    validate: [
                        {
                            type: "regex",
                            value: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})",
                            message: "Password must have a minimum length of 8 characters including at least 1 capital letter, 1 number 1 special character and not contain spaces"
                        }
                    ],
                    type: "password"
                },
                {
                    name: "confirm_password",
                    label: "Confirm Password",
                    required: true,
                    width: "50%",
                    marginRight: "0px",
                    type: "password"
                },
                {
                    name: "collector_number",
                    label: "Collector Number",
                    value: "0000000200xx0001",
                    required: true,
                    width: "50%",
                    marginRight: "0px",
                    paddingRight: "40px",
                    type: "text",

                },
                {
                    name: "inclusion_id",
                    label: "Inclusion Number",
                    required: true,
                    width: "50%",
                    marginRight: "0px",
                    type: "text",
                },

                {
                    name: "consulting_doctor_name",
                    label: "Consulting On-Call Surgeon Name",
                    required: true,
                    resolve: {
                        entity: "doctors",
                        fields: { first_name: 1, _id: 1, encrypted: 1 },
                        key: "first_name",
                        value: "_id",
                    },
                    width: "50%",
                    paddingRight: "40px",
                    marginRight: "0px",
                    type: "multi-select-advanced",
                },
                {
                    name: "consulting_nurse_name",
                    label: "Consulting IPA Name",
                    required: true,
                    resolve: {
                        entity: "nurses",
                        fields: { first_name: 1, _id: 1, encrypted: 1 },
                        key: "first_name",
                        value: "_id",
                    },
                    width: "50%",
                    marginRight: "0px",
                    type: "multi-select-advanced",
                },
                {
                    name: "type_of_equipments",
                    label: "Type of Equipment",
                    required: true,
                    width: "50%",
                    marginRight: "0px",
                    paddingRight: "40px",
                    type: "multi-select-advanced",
                    options: [
                        {
                            key: t("Weight scale"), value: t("Weight scale"), label: t("Weight scale")
                        },
                        {
                            key: t("Ear thermometer"), value: t("Ear thermometer"), label: t("Ear thermometer")
                        },
                        {
                            key: t("Tensiometer"), value: t("Tensiometer"), label: t("Tensiometer")
                        },
                        {
                            key: t("Pulse Oximeter"), value: t("Pulse Oximeter"), label: t("Pulse Oximeter")
                        },
                        {
                            key: t("Gateway"), value: t("Gateway"), label: t("Gateway")
                        },
                        {
                            key: t("Touch pad"), value: t("Touch pad"), label: t("Touch pad")

                        },
                    ]
                },
                {
                    name: "Patient_Registration_Date",
                    label: "Date of Patient Registration",
                    required: true,
                    width: "50%",
                    marginRight: "0px",
                    type: "datepicker"
                },
                {
                    name: "alert",
                    label: "Alert Activation",
                    width: "50%",
                    marginRight: "0px",
                    required: true,
                    type: "select",
                    options: [
                        {
                            key: t("Yes"), value: true
                        },
                        {
                            key: t("No"), value: "false"
                        }
                    ]
                }

            ]
        };
    }


    _onSubmit() {
        return new Promise((resolve, reject) => {

            const { t } = this.props;
            if (this.state.data.password !== this.state.data.confirm_password) {
                return reject({ message: t("Password and Confirm Password should match") });
            }

            var regexp = /^[0-9a-fA-F]+$/;
            const result = encodeURI(this.state.data.collector_number.trim()).split(/%..|./).length - 1;
            var reg = /^-?\d*\.?\d*$/;
            if (!(((this.state.data.inclusion_id).trim()).length == 5) || !(reg.test(this.state.data.inclusion_id.trim()))) {
                return reject({ message: t("The inclusion number must contain 5 digits") });
            }
            if (!(regexp.test(this.state.data.collector_number.trim())) || !(result == 16)) {
                return reject({ message: t("The collector number must contain 16 hexadecimal digits") });
            }
            if (this.state.data.consulting_doctor_name.length === 0 ) {
                return reject({ message: t("Consulting On-Call Surgeon Name is required") });
            }
            if (this.state.data.consulting_nurse_name.length === 0 ) {
                return reject({ message: t("Consulting IPA Name is required") });
            }
            if (this.state.data.type_of_equipments.length === 0 ) {
                return reject({ message: t("Type of equipments is required") });
            }
            const now = moment().format("DD-MM-YYYY hh:mm:ss");
            const data = JSON.parse(JSON.stringify(this.state.data));
            // addition of other dates to the patient records
            if (this.state.data.Patient_Registration_Date) {

                // calculate Hepactectomy_Date dates
                let Hepactectomy_Date = moment(this.state.data.Patient_Registration_Date, "YYYY-MM-DD").add(3, 'days');
                Hepactectomy_Date = moment(Hepactectomy_Date).format("YYYY-MM-DD");
                // calculate Return_Of_Material_Date dates
                let Return_Of_Material_Date = moment(Hepactectomy_Date, "YYYY-MM-DD").add(7, 'days');
                Return_Of_Material_Date = moment(Return_Of_Material_Date).format("YYYY-MM-DD");
                // calculate end of study dates
                let End_Of_Study_Date = moment(Hepactectomy_Date, "YYYY-MM-DD").add(30, 'days');
                End_Of_Study_Date = moment(End_Of_Study_Date).format("YYYY-MM-DD");
                data["Return_Of_Material_Date"] = Return_Of_Material_Date;
                data["End_Of_Study_Date"] = End_Of_Study_Date;
                data["Hepactectomy_Date"] = Hepactectomy_Date;
            }

            data.collector_number = data.collector_number.toLowerCase().trim();
            delete data.confirm_password;
            // create the options
            const options = {
                entity: "patients",
                data
            };
            // make an api call for patient registration
            HepadomEntity.add(options)
                .then((response) => {
                    //// get the response
                    resolve({ message: t("Register Successfully") });
                })
                .catch((err) => {
                    reject({ message: err.error });
                })
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Dashboard title={t("Patients")}>
                <Form options={this.form} title={t("PATIENT REGISTRATION")} />
                <h3>{t("Password is only known by the patient and must be well memorized")}</h3>
            </Dashboard>

        )
    }
}

export default withStyles(useStyles)(withTranslation('common')(AddPatient));