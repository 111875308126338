import React from "react";
import Dashboard from 'components/Dashboard';
import Table from 'components/Table';
import { withTranslation } from 'react-i18next';
class Patients extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <Dashboard title= {t("Investigating On-Call Surgeon")}>
                <React.Fragment>
                    <Table entity="admins"
                           fields={[
                               { key: "first_name", value: "First Name" },
                               { key: "last_name", value: "Last Name", },
                               { key: "user_name", value: "User Name", },
                               { key: "phone_number", value: "Phone Number" }
                           ]}
                           addShow={true}
                           clearShow={true}

                           actions={["delete"]}
                           addLink={"/hepadom/admin/_add"}
                    />
                </React.Fragment>


            </Dashboard>
        );
    }
}

export default (withTranslation('common')(Patients));
