import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import { Entity } from "utils/services/entity";
import { HepadomEntity } from "utils/services/hepadom_entity";
import Select from '@material-ui/core/Select';
import { withTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';

import {withStyles} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    chip: {
        margin: 2,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class Selector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            SelectedEntity:window.location.pathname.includes('hepadom') ? HepadomEntity : Entity,
        }
    }

    getData(options) {
        return new Promise((resolve, reject)=> {
            this.state.SelectedEntity.list(options)
                .then((response)=> {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch((err)=> {
                    reject(err);
                })
        });
    }

    componentDidMount() {
        const { field } = this.props;
        if (field.resolve && typeof field.resolve === "object" && Object.keys(field.resolve).length) {
            const { entity, fields, filters } = field.resolve;
            const options = {
                entity,
                query: {
                    fields,
                    filters
                }
            };
            this.getData(options)
                .then((records)=> {
                    const options = records.map((record)=> { return { key: record[field.resolve.key], value: record[field.resolve.value] } });
                    this.setState({ ...this.state, options });
                })
                .catch((err)=> {
                    // do nothing
                })

        } else if (field.options) {
            this.setState({ ...this.state, options: field.options });
        }
    }

    render() {
        let {t, field, onChange, classes, value, disabled, error, helpertext } = this.props;
        if (!value) value = [];
        const optionHash = {};

        this.state.options.forEach((option)=> {
           optionHash[option.value] = option.key;
        });

        return(
            <React.Fragment>
                <InputLabel htmlFor={field.id}>&nbsp;{t(`Select ${field.label}`)}</InputLabel>
                <Select
                    multiple
                    id={field.id}
                    label={t(field.label)}
                    input={<Input id={field.id} />}
                    name={field.name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    autoFocus={field.autoFocus}
                    renderValue={(selected) => {
                        return (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip key={t(value)} label={t(value)}  label={optionHash[value]} className={classes.chip} />
                                ))}
                            </div>
                    )}}
                    error={error}
                    helpertext={helpertext}
                    MenuProps={MenuProps}
                >
                    <MenuItem value="" disabled>
                        <em>{t(`Select ${field.label}`)}</em>
                    </MenuItem>
                    {this.state.options.map((option, index) => <MenuItem key={index}
                                                                         value={option.value}>{t(option.key)}</MenuItem>)}

                </Select>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(withTranslation('common')(Selector));
