import React from "react";
import Form from "components/Form";
import {Grid, Box, withStyles} from "@material-ui/core";
import {HepadomEntity} from "utils/services/hepadom_entity";
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const getReferenceSettings = (t) => ({


    referenceweight: {
        label: `${t('Reference Weight')} (Kg)`,
        min: 40,
        max: 130,
        step: 1,
        default_value:80
    },
});

const getReferences = (id) => {
    const options = {
        entity: "patients",
        data: {
            "inclusion_id": id
        }
    };
    const weightPromise = HepadomEntity.call('ref_weight', options);
    return new Promise((resolve, reject) => {
        Promise.all([weightPromise])
            .then((res)=> {
                const references = [
                    { name: 'referenceweight', value: res[0].data.referenceweight },
                ];
                resolve(references);
            })
            .catch((err)=> {
                reject(err);
            });
        });
};

const setReferences = (id, referenceweight) => {
    const promiseArray = [];
    if (referenceweight) {
        const options = {
            entity: 'patients',
            data: { InclusionId: id, referenceweight }
        };
        const weightPromise = HepadomEntity.call('ref_weight_update', options);
        promiseArray.push(weightPromise);
    }
 
    return new Promise((resolve, reject) => {
        Promise.all(promiseArray)
            .then(()=> {
                resolve();
            })
            .catch((err)=> {
                reject(err);
            });
        });
};

class ReferenceIndicator extends React.Component {
    renderTab(props) {
        getReferences(props.patient_details.inclusion_id)
        .then((references)=> {
            this.references = references;
            this.constructReferenceValues();
        })
        .catch((err)=> {
            this.setState({ ...this.state, error: err.error });
        });
    }
    constructor(props) {
        super(props);
        this.unsavedReferenceValues = {};
        this.state = {
            error: '',
            language:this.props.i18n.language,
            edit_reference_called:false
        };
        this.renderTab(props);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.i18n.language!==this.state.language) {
            this.setState({ ...this.state, language: prevProps.i18n.language });
            if(this.state.edit_reference_called===false)
            {this.renderTab(this.props);}
            else
            {this.constructFormObject(this.props);}

        }
    }
    constructReferenceValues = () => {
        const references = this.references;
        const referenceSettings = getReferenceSettings(this.props.t);
        const referenceValues = [];
        references.forEach((item)=> {
            const settings = referenceSettings[item.name];
            const label = settings.label;
            const value = item.value ? String(item.value) : this.props.t('No data available');
            referenceValues.push({
                name: item.name,
                label,
                value,
            });
        });
        this.setState({ referenceValues, edit: false });
    }

    constructFormObject = (props) => {
        const {t}=props;
        const references = this.references;
        const referenceSettings = getReferenceSettings(props.t);
        const threshold = {
            fields: [],
            onChange: this.onChange,
            onSubmit: this.saveReference,
            submitButtonText: t('Save Reference Values'),
            buttonWidth: 'auto',
        };
        references.forEach((item)=> {
            const settings = referenceSettings[item.name];
            const label = settings.label;
            const min = settings.min;
            const max = settings.max;
            const step = settings.step;
            const value = item.value ? String(item.value) : this.props.t('No data available');

            threshold.fields.push({
                name: item.name,
                label,
                value,
                type: 'slider',
                min,
                max,
                step,
                display_type:"on",
                marks: true
            });
        });
        this.setState({ formThreshold: threshold, edit: true });
    }

    editReference = () => {
        const referenceValues = this.state.referenceValues;   
        if(referenceValues[0].value !== "No data available"){
            
                    this.setState({ ...this.state, edit_reference_called: true });
                    this.constructFormObject(this.props);
        }
      
    }

    onChange = (change) => {
        const { data } = change;
        this.unsavedReferenceValues[data.name] = data.value;
    }

    saveReference = () => {
        const inclusion_id = this.props.patient_details.inclusion_id;
        let newWeight = null;
        if (this.unsavedReferenceValues.referenceweight !== undefined) {
            newWeight = this.unsavedReferenceValues.referenceweight;
        }
        

        setReferences(inclusion_id, newWeight)
            .then(() => {
                if (newWeight) {
                    this.references[0].value = newWeight;
                }
                
                this.constructReferenceValues();
            })
            .catch((err)=> {
                this.setState({ error: err.error });
            });
    }

    renderReferenceValues = (props) => {
        const referenceValues = this.state.referenceValues;
        const { t } = props;
        return (
            <div>
                <Typography variant="h5" component="h5" style={{ paddingBottom: '30px', textShadow: '2px 2px 4px blue' }}>
                    {t("Reference Values")}
                </Typography>
                <Grid container justify="center">
                    <Grid item xs={12} sm={8} md={7} lg={6}>
                        {
                            referenceValues.map(item => (
                                <div key={item.label} style={{textAlign: 'left', padding: '20px 0', color: '#3f51b5'}}>
                                    <span>{item.label}</span>
                                    <span style={{float: 'right'}}>{item.value}</span>
                                </div>
                            ))
                        }
                        <div style={{padding: '30px 0'}}>
                            <Button style={{width: 'auto'}} onClick={this.editReference} variant="contained" color="primary">
                                {t("Edit Reference Values")}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        const { t } = this.props;
        if (this.state.error && this.state.error.length) {
            return (
                <Box bgcolor="error.main" color="error.contrastText" p={2} m={1}>
                    {this.state.error}
                </Box>
            );
        }
        if (!this.references)
           { return (
                <div className="loader"/>
            );}
        return (
            <Grid container spacing={3}>
                <Grid item xs>
                    {!this.state.edit && this.renderReferenceValues(this.props)}
                    {this.state.edit &&
                        <Form options={this.state.formThreshold} title={t('Reference Values')}/>
                    }
                </Grid>
            </Grid>
        );
    }
}

const useStyles = (theme) => ({
    title: {
        padding: theme.spacing(1.5),
        fontSize : '1rem',
        textAlign: 'justify',
        display : 'flex',
        flexGrow: 1,
        flexWrap: 'nowrap'
    }
});

export default withStyles(useStyles)(withTranslation('common')(ReferenceIndicator));
