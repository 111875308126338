import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import LanguageSelectorCommon from '../LanguageSelectorCommon'
class Layout extends Component {

    render() {

        const { text, children, classes } = this.props;

        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Grid container spacing={3}>
                   <Grid item xs={8}/>
                   <Grid item xs={3}>
                       <LanguageSelectorCommon />
                   </Grid>
                   <Grid item xs={1}/>
                   <Grid item xs={12}>
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {text}
                            </Typography>
                            {children}
                        </div>
                   </Grid>  
                </Grid>  
                </Grid>
            </Grid>
        )
    }
}
export default Layout;

