import { FormHelperText, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import FormControl from "@material-ui/core/FormControl";
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import ArrayHandler from 'components/ArrayHandler';
import MultiSelect from "components/MultiSelect";
import RadioGroups from 'components/RadioGroup';
import Select from "components/Select";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import React, { Component } from 'react';
import { isMobileBrowser } from 'utils/common';
import Routing from 'utils/routing';
import CheckBox from 'components/CheckBox';
import TimePicker from "components/TimePicker";
import Slider from '@material-ui/core/Slider';
import DatePicker from 'components/DatePicker';
import { Grid } from "@material-ui/core";
import MuiPhoneNumber from 'material-ui-phone-number';
import { withTranslation } from 'react-i18next';
import 'moment/locale/fr';
import moment from 'moment';
import AdvancedSelect from '../AdvancedMultiSelect' ;
const { Redirect } = Routing;

class Form extends Component {
    constructor(props) {
        super(props);
        const { options } = props;
        const state = { data: {}, error: {}, loading: false, open: false, variant: 'info', message: '', redirect: false };
        const fields = options.fields;

        fields.forEach((field) => {
            if (field.value) state.data[field.name] = field.value;
            else state.data[field.name] = "";
        });

        const isMobile = isMobileBrowser();
        let width = true;
        if (isMobile) {
            width = false;
        }
        state.width = width;
        this.state = state;
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange.bind(this));
    }

    handleWindowSizeChange() {
        const isMobile = isMobileBrowser();
        let width = true;
        if (isMobile) {
            width = false;
        }
        this.setState({ ...this.state, width });
    }

    validate() {
        return new Promise((resolve, reject) => {
            const { t } = this.props;
            const { data } = this.state;
            const { fields } = this.props.options;
            const error = {};
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].required === true && !data[fields[i].name]) {
                    error[fields[i].name] = `${fields[i].label} is required`;
                    return reject({ message: t(error[fields[i].name]), error });
                } else if (fields[i].validate && fields[i].validate.length) {
                    const validate = fields[i].validate;
                    validate.forEach((v) => {
                        switch (v.type) {
                            case "regex":
                                const regex = new RegExp(v.value);
                                if (!regex.test(data[fields[i].name])) {
                                    error[fields[i].name] = v.message;
                                }
                                break;
                            default:
                                break;
                        }
                    })
                }
                if (Object.keys(error).length) {
                    return reject({ message: t(error[fields[i].name]), error });
                }
            }

            //fields[i].required === true && !data[fields[i].name]
            if (Object.keys(error).length) return reject({ message: t("Error in form"), error });
            resolve();
        });
    }

    _onSubmit(event) {
        event.preventDefault();
        this.setState({ ...this.state, loading: true });
        const { parameters,t } = this.props;
        const { onSubmit, successRedirect } = this.props.options;
        if (parameters) {
            this.parameters = parameters;
        }
        this.validate()
            .then(() => {
                this.setState({ error: {} })
                return onSubmit.call(this)
            })
            .then((response) => {
                if (!response) {
                    return;
                }
                const state = {
                    ...this.state,
                    open: true,
                    variant: 'success',
                    message:t(response.message),
                    loading: false
                };
                this.setState(state);

                if (successRedirect) setTimeout(() => {
                    this.setState({ ...this.state, redirect: true })
                }, 1000)

            })
            .catch((err) => {
                const state = {
                    ...this.state,
                    open: true,
                    variant: 'error',
                    message: t(err.message),
                    error: (err.error || {}),
                    loading: false
                };
                this.setState(state);
            });

    }

    handleChange = (e) => {
        const { options, parameters,t } = this.props;
        const data = { ...this.state.data };
        if(e.type === "multi-select-advanced"){
            const fieldData = e.target.value;
            let fieldValues = [];
            fieldData.map(x => (
                fieldValues.push(x.value)
            ))
            data[e.target.name] = fieldValues;
        } else {
            data[e.target.name] = e.target.value;
        }
       
        if(e.target.name==="clinical_study")
        {
            if(e.target.value.trim()==="multidom"&&this.props.i18n.language==='en')
            {
                window.alert(t("Multidom supports only French language"));
               this.props.i18n.changeLanguage('fr')
               
            }
        }
        if (typeof options.onChange === "function") {
            const option = {
                data: {
                    name: e.target.name,
                    value: e.target.value
                },
                parameters
            };
            options.onChange(option);
        }
        this.setState({ ...this.state, data });
    };
    toInputUppercase = (e) => {
        const name = e.target.name;
        if(name==="collector_number")
        e.target.value = ("" + e.target.value).toLocaleLowerCase();
    };
    onfocusout= (e) => {

        const { t } = this.props;
        const error = {};
        const name = e.target.name;
        if(name==="collector_number"||name==="inclusion_id")
       {
        var regexp;
        var result;
        var error_message="";
        if(name==="collector_number")
        {
            regexp = /^[0-9a-fA-F]+$/;
            result = encodeURI(e.target.value.trim()).split(/%..|./).length - 1;
            if (!(regexp.test(e.target.value.trim()))||!(result==16))
            error_message=t("The collector number must contain 16 hexadecimal digits");
        }
        else if(name==="inclusion_id")
        {
            regexp = /^-?\d*\.?\d*$/;
            result = (e.target.value.trim()).length;
            if (!(regexp.test(e.target.value.trim()))||!(result==5))
            error_message=t("The inclusion number must contain 5 digits");
        }

            if (!(error_message===""))
           {  
             error[name]=error_message;
           
            const state = {
                ...this.state,
                open: true,
                variant: 'error',
                message: t(error_message),
                error: (error),
                loading: false
            };
               setTimeout(() => {
                    this.setState(state);
                }, 200)
            }
            else{
            const state = { error: {}, loading: false, open: false, variant: 'info', message: '', redirect: false };
            this.setState(state);
           }
        }

    };

    handleCheckboxChange = (e) => {
        const { options, parameters } = this.props;
        const name = e.target.name;
        const isChecked = e.target.checked;
        const data = JSON.parse(JSON.stringify(this.state.data));
        let checkbox = data[name];
        if (typeof checkbox !== "object") checkbox = [];
        if (isChecked) checkbox.push(e.target.value);
        else checkbox.splice(checkbox.indexOf(e.target.value), 1);
        data[name] = checkbox;
        if (typeof options.onChange === "function") {
            const option = {
                data: {
                    name: e.target.name,
                    value: checkbox
                },
                parameters
            };
            options.onChange(option);
        }
        this.setState({ ...this.state, data });
    };

    handleSliderChange = name => (event, value) => {
        const { options, parameters } = this.props;
        const data = { ...this.state.data };
        data[name] = value;
        if (typeof options.onChange === "function") {
            const option = {
                data: {
                    name: name,
                    value: value
                },
                parameters
            };
            options.onChange(option);
        }
        this.setState({
            ...this.state, data
        });
    };

    handlePhoneChange = name => (value) => {
        const { options, parameters } = this.props;
        const data = { ...this.state.data };
        data[name] = value;
        if (typeof options.onChange === "function") {
            const option = {
                data: {
                    name,
                    value
                },
                parameters
            };
            options.onChange(option);
        }
        this.setState({ ...this.state, data });
    };

    handleClose = () => {
        this.setState({ ...this.state, open: false });
    };

    static validate(options) {
        if (!options.onSubmit || typeof options.onSubmit !== 'function') return false;

        return !(!options.fields || !Array.isArray(options.fields));
    }

    onDateChange(name, date) {
        const { options, parameters } = this.props;
        const data = { ...this.state.data };
        data[name] = new Date(date);
        if (typeof options.onChange === "function") {
            const option = {
                data: {
                    name: name,
                    value: new Date(date)
                },
                parameters
            };
            options.onChange(option);
        }
        this.setState({ ...this.state, data });
    }

    render() {
        const { t, classes, options, title, className } = this.props;
        const fields = options.fields;
        if (this.state.redirect && options.successRedirect) return <Redirect to={options.successRedirect} />;
        return (<React.Fragment>
            <Typography variant="h5"
                component="h5"
                style={{ textShadow: '2px 2px 4px blue' }}
            >
                {t(title)}
            </Typography>
            <form className={className || classes.form}
                ref="form"
                onSubmit={this._onSubmit.bind(this)}
            >
                {
                    fields.filter((field) => {
                        if (field.show_on) {
                            for (let i = 0; i < field.show_on.length; i++) {
                                const show_on = field.show_on[i];
                                if (!(show_on.field && show_on.value)) continue;
                                if (typeof show_on.value === "string" && this.state.data[show_on.field] !== show_on.value) {
                                    return false;
                                } else if (typeof show_on.value === "object") {
                                    let returnValue = false;
                                    for (let j = 0; j < show_on.value.length; j++) {
                                        if (this.state.data[show_on.field] === show_on.value[j]) returnValue = true;
                                    }

                                    return returnValue;
                                }
                            }
                            return true;
                        } else return true;
                    }).map((field, index) => {
                        let fieldValue;
                        switch (field.type) {
                            case 'custom':
                                fieldValue = field.htmlContent;
                                break;
                            case 'text':
                                fieldValue = < FormControl key={index} className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        key={index}
                                        value={this.state.data[field.name]}
                                        type="text"
                                        id={field.name}
                                        disabled={this.state.loading || field.disabled}
                                        label={t(field.label)}
                                        name={field.name}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        onChange={e => this.handleChange(e)}
                                        onInput={e => this.toInputUppercase(e)}
                                        onBlur={e => this.onfocusout(e)}
                                        autoFocus={field.autofocus === true}>
                                    </TextField>
                                </FormControl>;
                                break;
                            case 'textarea':
                                let formStyles = {
                                    width: '100%',
                                    margin: '20px 0'
                                };
                                const textareaStyles = {
                                    fontSize: '14px',
                                    margin: '20px 0px',
                                    minHeight: '60px'
                                }
                                if (this.state.width) {
                                    formStyles = {
                                        width: '90%',
                                        margin: '20px 40px 20px -40px'
                                    }
                                    textareaStyles.marginRight = '-40px';
                                }
                                fieldValue = < FormControl key={index} className={classes.formControl}
                                    style={formStyles}>
                                    <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink">
                                        {t(field.label)}
                                    </label>
                                    <TextareaAutosize
                                        style={textareaStyles}
                                        // rowsMin={3}
                                        variant="outlined"
                                        margin="normal"
                                        key={index}
                                        value={this.state.data[field.name]}
                                        id={field.name}
                                        disabled={this.state.loading || field.disabled}
                                        name={field.name}
                                        onChange={e => this.handleChange(e)}
                                        autoFocus={field.autofocus === true}
                                    />
                                </FormControl>;
                                break;
                            case "number":
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        key={index}
                                        value={this.state.data[field.name]}
                                        type="number"
                                        id={field.name}
                                        disabled={this.state.loading || field.disabled}
                                        label={t(field.label)}
                                        name={field.name}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        onChange={e => this.handleChange(e)}
                                        autoFocus={field.autofocus === true}>
                                    </TextField>
                                </FormControl>;
                                break;
                            case "number_with_seperate_label":
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} lg md>
                                            <Typography variant="subtitle2" color="primary"
                                                className={classes.title}>{field.label}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg md>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                key={index}
                                                value={this.state.data[field.name]}
                                                type="number"
                                                id={field.name}
                                                disabled={this.state.loading || field.disabled}
                                                name={field.name}
                                                error={typeof this.state.error[field.name] !== "undefined"}
                                                helpertext={this.state.error[field.name]}
                                                onChange={e => this.handleChange(e)}
                                                autoFocus={field.autofocus === true}>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </FormControl>;
                                break;
                            case 'password':
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        key={index}
                                        type="password"
                                        disabled={this.state.loading || field.disabled}
                                        value={this.state.data[field.name]}
                                        id={field.name}
                                        label={t(field.label)}
                                        name={field.name}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        onChange={e => this.handleChange(e)}
                                        autoFocus={field.autofocus === true}>
                                    </TextField>
                                </FormControl>;
                                break;
                            case "phone_number":
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width,
                                            marginTop: 30,
                                        } : { width: '100%' }
                                    }>
                                    <MuiPhoneNumber
                                        error={this.state.error[field.name]}
                                        defaultCountry={'fr'}
                                        value={this.state.data[field.name]}
                                        id={field.name}
                                        key={index}
                                        autoFormat={false}
                                        disabled={this.state.loading || field.disabled}
                                        onChange={this.handlePhoneChange(field.name)} />
                                </FormControl>;
                                break;
                            case 'email':
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        key={index}
                                        type="email"
                                        value={this.state.data[field.name]}
                                        style={
                                            field.width !== "" && this.state.width ? {
                                                marginRight: field.marginRight || 40,
                                                paddingRight: field.paddingRight,
                                                width: field.width
                                            } : { width: '100%' }
                                        }

                                        id={field.name}
                                        label={t(field.label)}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        disabled={this.state.loading || field.disabled}
                                        name={field.name}
                                        onChange={e => this.handleChange(e)}
                                        autoFocus={field.autofocus === true}>
                                    </TextField>
                                </FormControl>;
                                break;
                            case 'select':
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            marginTop: 16,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <Select
                                        field={field}
                                        onChange={e => this.handleChange(e)}
                                        value={this.state.data[field.name]}
                                        disabled={this.state.loading || field.disabled}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]} />
                                </FormControl>;
                                break;
                            case 'multiselect':
                                fieldValue = < FormControl key={index} className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            marginTop: 16,
                                            width: field.width,
                                            paddingTop:field.paddingTop,
                                        } : { width: '100%' }
                                    }>
                                    <MultiSelect
                                        field={field}
                                        onChange={e => this.handleChange(e)}
                                        value={this.state.data[field.name]}
                                        disabled={this.state.loading || field.disabled}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                    /> </FormControl>;
                                break;
                            case "multi-select-advanced":
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            marginTop: 16,
                                            width: field.width
                                        } : { width: '100%' }
                                }>
                                    <AdvancedSelect
                                       field={field}
                                       onChange={e => this.handleChange(e)}
                                       value={this.state.data[field.name]}
                                       disabled={this.state.loading || field.disabled}
                                       error={typeof this.state.error[field.name] !== "undefined"}
                                       helpertext={this.state.error[field.name]}
                                    /></FormControl>;
                                break;
                            case 'multiline':
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            marginTop: 16,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <TextField
                                        multiline
                                        rows="5"
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        key={index}
                                        id={field.name}
                                        disabled={this.state.loading || field.disabled}
                                        label={t(field.label)}
                                        name={field.name}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        onChange={e => this.handleChange(e)} /> </FormControl>;
                                break;
                            case 'datepicker':
                                const today = moment().format("YYYY-MM-DD");
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <TextField
                                        margin="normal"
                                        id={field.id}
                                        type="date"
                                        locale="fr"
                                        label={t(field.label)}
                                        name={field.name}
                                        key={index}
                                        // defaultValue="select"
                                        disabled={this.state.loading || field.disabled}
                                        value={this.state.data[field.name]}
                                        //inputProps={{ min:today}} 
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        onChange={e => this.handleChange(e)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </FormControl>;
                                break;
                            case "datetimepicker":
                              
                                if (!this.state.data[field.name]) {
                                    const data = this.state.data;
                                    data[field.name] = new Date();
                                    this.setState({ ...this.state, data });
                                }
                                fieldValue = <FormControl className={classes.formControl}
                                    key={index}
                                    style={field.width !== "" && this.state.width ? {
                                        marginRight: field.marginRight || 40,
                                        paddingRight: field.paddingRight,
                                        width: field.width
                                    } : { width: '100%' }}>
                                    <DatePicker
                                        margin="normal"
                                        id={field.name}
                                        label={t(field.label)}
                                        name={field.name}
                                        value={this.state.data[field.name]}
                                        onDateChange={this.onDateChange.bind(this)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helperText={this.state.error[field.name]}
                                    />
                                </FormControl>;
                                break;
                            case 'radio':
                                fieldValue = < FormControl key={index} component={field.component ? field.component : "div"}
                                    className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginTop: 10,
                                            width: field.width,
                                            display: "block"
                                        } : { width: '100%' }
                                    }>
                                    <RadioGroups
                                        field={field}
                                        onChange={e => this.handleChange(e)}
                                        value={this.state.data[field.name]}
                                        disabled={this.state.loading || field.disabled}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        align={field.align}
                                        helpertext={this.state.error[field.name]}
                                        checked={this.props.checked}>
                                    </RadioGroups>

                                </FormControl>;
                                break;
                            case 'checkbox':
                                fieldValue = < FormControl key={index} component={field.component ? field.component : "div"}
                                    className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginTop: 10,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <CheckBox
                                        field={field}
                                        key={index}
                                        onChange={(e, checked) => this.handleCheckboxChange(e, checked)}
                                        value={this.state.data[field.name]}
                                        disabled={this.state.loading || field.disabled}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        checked={this.props.checked || false}
                                    >
                                    </CheckBox>

                                </FormControl>;
                                break;
                            case 'array':
                                fieldValue = <ArrayHandler key={index}
                                    field={field}
                                    width={this.state.width}
                                    onChange={this.handleChange.bind(this)}
                                    disabled={this.state.loading || field.disabled}
                                    error={typeof this.state.error[field.name] !== "undefined"}
                                    helpertext={this.state.error[field.name]}
                                />;
                                break;
                            case 'slider':
                                fieldValue = <FormControl className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginTop: 30,
                                            width: field.width
                                        } : { width: '100%', marginTop: 30 }
                                    }>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} lg md>
                                            <Typography variant="subtitle2" color="primary"
                                                className={classes.title}>{field.label}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg md>
                                            <Slider
                                                name={field.name}
                                                min={field.min}
                                                max={field.max}
                                                step={field.step}
                                                marks={field.marks || false}
                                                value={this.state.data[field.name]}
                                                aria-labelledby="discrete-slider-restrict"
                                                valueLabelDisplay={field.display_type}
                                                onChange={this.handleSliderChange(field.name)}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>;
                                break;
                            case 'timepicker':
                                if (!this.state.data[field.name]) {
                                    const data = this.state.data;
                                    data[field.name] = new Date();
                                    this.setState({ ...this.state, data });
                                }
                                fieldValue = <FormControl key={index}
                                    className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginTop: 10,
                                            width: field.width
                                        } : { width: '45%' }
                                    }>

                                    <FormHelperText>{field.label}</FormHelperText>
                                    <TimePicker
                                        margin="normal"
                                        id={field.name}
                                        // label={field.label}
                                        name={field.name}
                                        value={this.state.data[field.name]}
                                        disabled={this.state.loading || field.disabled}
                                        onDateChange={this.onDateChange.bind(this)}
                                        KeyboardButtonProps={
                                            {
                                                'aria-label': 'change date',
                                            }
                                        }
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]} />
                                </FormControl>;
                                break;
                            case "timepicker_questionnaire":
                                if (!this.state.data[field.name]) {
                                    const data = this.state.data;
                                    data[field.name] = new Date();
                                    this.onDateChange(field.name, data[field.name]);
                                    this.setState({ data });
                                }
                                fieldValue = <FormControl key={index}
                                    className={classes.formControl}
                                    style={
                                        field.width !== "" && this.state.width ? {
                                            marginTop: 10,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} lg md>
                                            <Typography variant="subtitle2" color="primary"
                                                className={classes.title}>{field.label}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg md>
                                            <TimePicker
                                                ampm={false}
                                                margin="normal"
                                                id={field.name}
                                                name={field.name}
                                                value={this.state.data[field.name]}
                                                disabled={this.state.loading || field.disabled}
                                                onDateChange={this.onDateChange.bind(this)}
                                                KeyboardButtonProps={
                                                    {
                                                        'aria-label': 'change date',
                                                    }
                                                }
                                                error={typeof this.state.error[field.name] !== "undefined"}
                                                helpertext={this.state.error[field.name]} />
                                        </Grid>
                                    </Grid>
                                </FormControl>;
                                break;
                            default:
                                fieldValue = <FormControl key={index} className={classes.formControl}
                                    style={
                                        typeof field.width === "string" && this.state.width ? {
                                            marginRight: field.marginRight || 40,
                                            paddingRight: field.paddingRight,
                                            width: field.width
                                        } : { width: '100%' }
                                    }>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        key={index}
                                        value={this.state.data[field.name]}
                                        type="text"
                                        id={field.name}
                                        disabled={this.state.loading || field.disabled}
                                        label={t(field.label)}
                                        name={field.name}
                                        error={typeof this.state.error[field.name] !== "undefined"}
                                        helpertext={this.state.error[field.name]}
                                        onChange={e => this.handleChange(e)}
                                        autoFocus={field.autofocus === true}>
                                    </TextField> </FormControl>;
                        }
                        return fieldValue;
                    })
                }
                {options.submitButtonText ? <div className={classes.wrapper}>
                    <Button type="submit"
                        variant="contained"
                        style={options.buttonWidth && options.buttonWidth.length ? { width: options.buttonWidth } : { width: '100%' }}
                        disabled={this.state.loading}
                        color="primary"> {t(options.submitButtonText)}
                    </Button> {
                        this.state.loading && <CircularProgress size={40}
                            className={classes.buttonProgress} />}
                </div> : <div />}
                <Snackbar anchorOrigin={
                    { vertical: "top", horizontal: "right" }
                }
                    style={{ position: 'absolute' }}
                    key={`top,left`}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}>
                    <SnackbarContentWrapper onClose={this.handleClose}
                        variant={this.state.variant}
                        message={this.state.message} />
                </Snackbar>
            </form>
        </React.Fragment >
        )
    }
}


const useStyles = (theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        position: 'relative'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        marginTop: theme.spacing(1),
    },
    formControl: {
        minWidth: 120,
    },
    timepick: {
        textalign: 'justify',
    },
    title: {
        padding: theme.spacing(1.5),
        fontSize: '1rem',
        textAlign: 'justify',
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'nowrap'
    }
});


export default withStyles(useStyles)(withTranslation('common')(Form));