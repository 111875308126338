import React from "react";
import Dashboard from 'components/Dashboard';
import Table from 'components/Table';
import { withTranslation } from 'react-i18next';
class DoctorsPatientList extends React.Component {
    render() {
        const { t } = this.props;
        return ( <Dashboard title = {t("Patients")}
            disablePaper = { true }>
            <React.Fragment>
            <Table entity = "patients"
            actions = {
                [t("edit"), t("view")] }
            fields = {
                [
                    { key: "user_name",value: "User Name" },
                    { key: "clinical_study", value: "Clinical Study" },
                    { key: "collector_number", value: "Collector Number", },
                    { key: "inclusion_id", value: "Inclusion Number", },
             ]
            }
            >
            </Table> </React.Fragment> </Dashboard>
        );
    }
}

export default withTranslation('common')(DoctorsPatientList);
