import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { HepadomEntity } from 'utils/services/hepadom_entity';
import {
    Button
} from '@material-ui/core';
import Form from 'components/Form';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThumbUpAltOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {refreshPage} from '../../../../../utils/common';
import { withTranslation } from 'react-i18next';

const answers = [];
class PainAssessment extends React.Component {
    state = { answers: [],variant: "success", disabled: false,PainAssessment_value:0 };
    
    onChange(change) {
        const { data } = change;
        this.setState({ ...this.state, PainAssessment_value:data.value });
        const match = answers.find(item => item._id === data.name);
        if (match) {
            match.value = data.value;
        } else {
            answers.push({ _id: data.name, value: data.value });
            
        }
        this.setState({ ...this.state, answers });
    }

    save() {

        const { id,t } = this.props;
        this.setState({ ...this.state, loading: true });
        if(!answers.length)
        answers.push({ _id: "5de60ed767fe2644909c66b4", value: 0 });
        const formattedAnswers = answers.map(item => {
            return Array.isArray(item.value) ? { ...item, value: item.value.toString() } : item;
        });
        const options = {
            entity: "questionnaire",
            entity_id: id,
            data: {
                answers: formattedAnswers
            }
        };

        HepadomEntity.edit(options)
            .then((response) => {
                this.setState({ ...this.state, open: true, variant: 'success', message: t(response.message), disabled: true, loading: false });
                refreshPage()
            })
            .catch((err) => {
                this.setState({ ...this.state, open: true, variant: 'error', message: t(err.error), loading: false });
            })

    }

    handleClose = () => {
        this.setState({ ...this.state, open: false });
    };

    render() {
        const { questionnaires, classes, activated,t} = this.props;
        let form = [];

        questionnaires.forEach((questionnaire, index) => {
            if (index === 0) return;
            let f = {
                addDivider: true,
                onChange: this.onChange.bind(this),
                fields: []
            };
            questionnaire.questionnaires.forEach((questionnaire) => {
                let marks=  
                [
                  {
                    value: questionnaire.min,
                    label: t('No pain')
                  },
                  {
                    value: questionnaire.max,
                    label: t('Maximum pain imaginable')
                  }
                ]
                f.fields.push({
                    name:  questionnaire._id,
                    label: t("Pain Assessment"),
                    type: "slider",
                    value:(this.state.PainAssessment_value),
                    min:questionnaire.min,
                    max:questionnaire.max,
                    step:questionnaire.step,
                    marks:marks,
                    display_type:"off",
                    disabled: (this.state.disabled || activated === false),
                    width: "83%"
                });
            });
            form.push(f);
        });

        if (activated === false) return (<Grid container spacing={3}>
            <Grid item xs>
                <ThumbUpAltOutlined variant="subtitle2" color="primary" /><Typography variant="subtitle2" color="primary">Déjà répondu</Typography>
            </Grid>
        </Grid>);
        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "left" }}>
                        <h4>{}</h4>
                    </Grid>
                </Grid>
                <br />
                {questionnaires.map((questionnaire, index) => {
                    if (index === 0) return <React.Fragment key={index} />;
                    return <React.Fragment key={index}>
                        <Form  options={form[index - 1]} />
                    </React.Fragment>

                })}
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    style={{ position: 'absolute', right: '100px' }}
                    key={`top,left`}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={this.state.variant}
                        message={this.state.message}
                    />
                </Snackbar>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.save.bind(this)} disabled={(this.state.disabled || activated === false)}>
                            {this.state.loading ? <CircularProgress
                                size={40}
                                className={classes.buttonProgress} /> : t("Submit")}
                        </Button>
                    </Grid>
                </Grid>
                <br />
            </div>
        )
    }
}

const useStyles = (theme) => ({
    root: {
        position: 'relative',
    },
    description: {
        textAlign: "left",
        
    },
    formControl: {
        textAlign: "left",
    },
    button: {
        margin: theme.spacing(1),
    },
    divider: {
        marginBottom: "3%"
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default withStyles(useStyles)(withTranslation('common')(PainAssessment));

