import React from "react";
import Dashboard from 'components/Dashboard';
import { Entity } from 'utils/services/entity';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { withTranslation } from 'react-i18next';
import {Grid, withStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import { ThumbUpAltOutlined } from '@material-ui/icons';
import MDASI from "./components/MDASI";
import EORTC from "./components/EORTC";
import Pittsburgh from "./components/Pittsburgh";
import Chronotype from "./components/Chronotype";
import SUTAQ from "./components/SUTAQ";
import RRPQ from "./components/RRPQ";
import { timezone } from "../../../utils/common";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = (theme)=> ({
    root: {
        backgroundColor: theme.palette.background.paper
    },
    questionnaireContent: {
        fontSize: '1.1rem'
    }
});

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function getQuestionnaires(_id) {
    return new Promise((resolve, reject)=> {
        const options = {
            entity: "questionnaire",
            entity_id: _id
        };

        Entity.get(options)
            .then((response)=> {
                const {questionnaires, activated, created_at, first_triggered_at} = response.data;
                try {
                    resolve({ questionnaires, activated, created_at, first_triggered_at });
                } catch(e) {
                    reject({ status: 406, error: "Error while getting questionnaires" });
                }
            })
            .catch((err)=> {
                reject(err);
            })
    })
}

class QuestionnaireTab extends React.Component {

    state = { questionnaires: [] };
    constructor(props) {
        super(props);
        const { questionnaire } = props;
        getQuestionnaires(questionnaire._id)
            .then(({ questionnaires, activated, created_at, first_triggered_at })=> {
                this.setState({ questionnaires, activated, created_at, first_triggered_at });
            })
    }

    renderQuestionnaire = () => {
        const { questionnaire, user } = this.props;
        switch (questionnaire.name) {
            case "MDASI":
                return <MDASI user={user} questionnaires={this.state.questionnaires} id={questionnaire._id} activated={this.state.activated} />;
            case "EORTC-QLQ-C30":
                return <EORTC user={user} questionnaires={this.state.questionnaires} id={questionnaire._id} activated={this.state.activated}/>;
            case "Pittsburgh":
                return <Pittsburgh user={user} questionnaires={this.state.questionnaires} id={questionnaire._id} activated={this.state.activated}/>;
            case "Chronotype":
                return <Chronotype user={user} questionnaires={this.state.questionnaires} id={questionnaire._id} activated={this.state.activated}/>;
            case "SUTAQ":
                return <SUTAQ user={user} questionnaires={this.state.questionnaires} id={questionnaire._id} activated={this.state.activated}/>;
            case "RRPQ":
                return <RRPQ usee={user} questionnaires={this.state.questionnaires} id={questionnaire._id} activated={this.state.activated}/>;
        }
    }

    render() {
        if (!this.state.questionnaires.length) {
            return <div className="loader"/>;
        }
        const getTimezone = timezone();
        const first_triggered_at = moment(this.state.first_triggered_at).tz(getTimezone).format('llll');
        return (
            <div>
                <div>{this.props.t('Questionnaire date')} {first_triggered_at}</div>
                {this.renderQuestionnaire()}        
            </div>
        );
    }
}

class Questionnaire extends React.Component {
    state = { questionnaires: [], loading: true, value: 0 };

    getQuestionnairesList() {
        return new Promise((resolve, reject)=> {
            const options = {
                entity: "questionnaire"
            };
            Entity.list(options)
                .then((response)=> {
                    try {
                        const records = response.data.records;
                        resolve(records);
                    } catch(e) {
                        reject(e.toString());
                    }
                })
                .catch((err)=> {
                    reject(err.error);
                })
        })
    }

    handleChange = (event, newValue)=> {
        this.setState({ ...this.state, value: newValue });
    };

    handleChangeIndex = (event, newValue)=> {
        this.setState({ ...this.state, value: newValue });
    };

    constructor(props) {
        super(props);

        this.getQuestionnairesList()
            .then((records)=> {
                this.setState({ ...this.state, questionnaires: records, loading: false })
            })
            .catch((err)=> {
                this.setState({ ...this.state, loading: false, error: err });
            })

    }

    // refresh() {
    //     this.getQuestionnairesList()
    //         .then((records)=> {
    //             this.setState({ ...this.state, questionnaires: records, loading: false, value: 0 })
    //         })
    //         .catch((err)=> {
    //             this.setState({ ...this.state, loading: false, error: err });
    //         })
    // }

    render() {
        const { classes, user } = this.props;
        return (
            <Dashboard title="Questionnaires">
                { (!this.state.questionnaires.length && this.state.loading) ?
                    <div className="loader"/> :
                    (!this.state.questionnaires.length && !this.state.loading) ?
                        (this.state.error ?
                            this.state.error :
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <ThumbUpAltOutlined variant="subtitle2" color ="primary"/><Typography variant="subtitle2" color ="primary">TOUT FAIT POUR AUJOURD'HUI</Typography>
                                    </Grid>
                                </Grid>
                        ) :
                        <div className={classes.root}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    aria-label="full width tabs example"
                                >
                                    { this.state.questionnaires.map((questionnaire, index)=> {
                                        return <Tab label={questionnaire.name} {...a11yProps(index)} key={index}/>
                                    })}
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                index={this.state.value}
                                onChangeIndex={this.handleChangeIndex}>
                                { this.state.questionnaires.map((questionnaire, index) => {
                                    return (
                                        <TabPanel value={this.state.value} index={index}>
                                            {this.state.value === index &&
                                                <QuestionnaireTab
                                                    // refresh={this.refresh.bind(this)}
                                                    questionnaire={questionnaire}
                                                    user={user}
                                                    key={index}
                                                    value={index}
                                                    t={this.props.t}
                                                />
                                            }
                                        </TabPanel>
                                    );
                                })}
                            </SwipeableViews>
                        </div>
                }
            </Dashboard>
        );
    }
}

export default withStyles(useStyles)(withTranslation('common')(Questionnaire));
