export default theme =>({
    root:{
        padding: 10,
        display : 'flex',
        height : '10vh',
    },
    questions : {
        padding: theme.spacing(1.5),
        fontSize : '1.1rem',
        // textAlign: 'justify',
        display : 'flex',
        flexGrow: 1,
        flexWrap: 'nowrap',
        float: "left"
    },
    title :{
        flexGrow: 1,
    },
    buttons : {
        display : 'flex',
        paddingTop: theme.spacing(0.5),
        padding: theme.spacing(1.5),
        fontSize : '1rem'
    },
    textarea:{},
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    group: {
        margin: theme.spacing(1, 0),
    },
});
