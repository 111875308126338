import React, { Component } from 'react';

import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider'
import KeyboardTimePicker from 'material-ui-pickers/TimePicker'
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { withTranslation } from 'react-i18next';
import frLocale from "date-fns/locale/fr";
class TimePicker extends Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.onChange = this.onChange.bind(this);
    }

    onChange(date) {
        const { onDateChange, name } = this.props;
        onDateChange(name, date);
    }

    render() {
        const {
            t,
            error,
            helpertext,
            value,
            id,
            label,
            name,
            style,
            KeyboardButtonProps,
            disabled,
            ampm
        } = this.props;

        return (
            <React.Fragment>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <Grid container justify="flex-start" style={{ display: 'contents' }}>
                        <KeyboardTimePicker
                            margin="normal"
                            id={id}
                            disabled={disabled}
                            label={t(label)}
                            name={name}
                            value={value}
                            style={style}
                            KeyboardButtonProps={KeyboardButtonProps}
                            error={t(error)}
                            helpertext={t(helpertext)}
                            onChange={this.onChange}
                            ampm={ampm}
                        >
                        </KeyboardTimePicker>
                    </Grid>
                </MuiPickersUtilsProvider>
            </React.Fragment>
        );
    }
}

export default (withTranslation('common')(TimePicker));
