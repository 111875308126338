import React from "react";
import AppBar from '@material-ui/core/AppBar';
import { Grid, withStyles, } from "@material-ui/core";
import SurveyMDASIHEP from "components/SurveyMDASIHEP";
import SurveyTRANSIT from "components/SurveyTRANSIT";
import SurveyPAIN from "components/SurveyPAIN";
const useStyles = () => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#3f51b5',
            outline: '1px solid slategrey'
        }
    },
    gridcontainer : {
       height: 'calc(100% - 10%)',
      },
      
      gridcolumn: {
        height: '700px',
        overflow: 'scroll',
        width : '100%'
      }
});

class Survey extends React.Component{
    render() {
        const { patient_details,classes } = this.props;
        return(
            <React.Fragment>
                <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
               </AppBar>
               <Grid container className = {classes.gridcolumn}>
               <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        <SurveyMDASIHEP patient_details={patient_details} i18n={this.props.i18n}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SurveyPAIN patient_details={patient_details} i18n={this.props.i18n}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SurveyTRANSIT patient_details={patient_details} i18n={this.props.i18n}/>
                        </Grid>
                    </Grid>
                    </Grid>


            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(Survey);