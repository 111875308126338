import React from "react";
import SurveyMDASI from "components/SurveyMDASI";
import SurveyEORTC from "components/SurveyEORTC";
import SurveyPITTSBURGH from "components/SurveyPITTSBURGH";
import SurveyCHRONOTYPE from "components/SurveyCHRONOTYPE";

class Survey extends React.Component{
    render() {
        const { patient_details } = this.props;
        return(
            <React.Fragment>
                <SurveyMDASI patient_details={patient_details}/>
                <SurveyEORTC patient_details={patient_details}/>
                <SurveyPITTSBURGH patient_details={patient_details}/>
                <SurveyCHRONOTYPE patient_details={patient_details}/>
            </React.Fragment>
        )
    }
}

export default Survey;
