import '../../styles/common.css';
import React from "react";
import { withTranslation } from 'react-i18next';
import { Entity } from "utils/services/entity";
import moment from "moment-timezone";
import { timezone } from "../../utils/common";


function getQuestionnaires(patient_id) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "questionnaire",
            data: {
                patient_id,
                name: "Chronotype"
            }
        };
        Entity.call("getQuestionnaire", options)
            .then(response => {
                const records = response.data.records;
                resolve(records);
            })
            .catch(err => {
                reject(err)
            })
    })
}

class SurveyCHORONOTYPE extends React.Component {
    state = { scores: "", visit_1_dates: "", imageURL: "" , isDataAvailable:false};
    imageURL = "";
    constructor(props) {
        super(props);
        getQuestionnaires(props.patient_details._id)
            .then((data) => {
                const getTimezone = timezone();
                let dataScore
                 if(data[0].scores["Score du Chronotype"]){
                     
                     dataScore  = data[0].scores["Score du Chronotype"];
                 }else{
                   
                     dataScore = data[0].scores["chronotype indicator"];
                 }
                

                if (dataScore > 0) {
                    this.state.isDataAvailable = true;
                    if (dataScore >= 16 && dataScore <= 30) {
                        this.setState({ imageURL: require("images/Sleep_Pattern1.png") });
                    } else if (dataScore >= 31 && dataScore <= 41) {
                        this.setState({ imageURL: require("images/Sleep_Pattern2.png") });
                    } else if (dataScore >= 42 && dataScore <= 58) {
                        this.setState({ imageURL: require("images/Sleep_Pattern3.png") });
                    } else if (dataScore >= 59 && dataScore <= 69) {
                        this.setState({ imageURL: require("images/Sleep_Pattern4.png") });
                    } else {
                        this.setState({ imageURL: require("images/Sleep_Pattern5.png") });
                    }


                }
                this.setState({ scores: dataScore });
                this.setState({ visit_1_dates: moment(data[0].answered_at).tz(getTimezone).format("DD-MM-YYYY") })

            })
            .catch((err) => {

                // console.log("sky  chronotype data err", err);
            });
    }
    render() {

        return (

            <div>
                {(() => {
                    if (this.state.isDataAvailable) {
                        return (
                            <div style={{
                                align: 'center',
                                style: {
                                    fontSize: '20px'
                                }
                            }}>
                                <div>
                                    <h2 style={{
                                        align: 'center',
                                        fontSize: '20px',
                                        fontWeight: 900,
                                        fontFamily: `"Helvetica Neue", "Arial", "sans-serif"`,
                                    }}>
                                        Score questionnaire Chronotype</h2>
                                </div>

                                <div>
                                    <h3> Score : {this.state.scores}</h3>
                                    <h3> Date de réponse au questionnaire : {this.state.visit_1_dates}</h3>

                                </div>
                                <div>
                                    <img src={this.state.imageURL} style={{ height: '4rem' }} />
                                </div>
                            </div>

                        )
                    } else {
                        return (
                            <div>
                                <h2 style={{
                                        align: 'center',
                                        fontSize: '20px',
                                        fontWeight: 900,
                                        fontFamily: `"Helvetica Neue", "Arial", "sans-serif"`,
                                    }}>
                                        Score questionnaire Chronotype</h2>
                                <div > Les questions n'ont pas reçu de réponse  </div>

                            </div>


                        )
                    }
                })()}
            </div>


        );





    }

}

export default ((SurveyCHORONOTYPE));