import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Routing from 'utils/routing';
import Layout from 'components/Layout';
import {Grid} from "@material-ui/core";
import Form from 'components/Form';
import { Entity } from 'utils/services/entity';
import {HepadomEntity} from "utils/services/hepadom_entity"
import { withTranslation } from 'react-i18next';
import loginImage from '../../../images/login.jpg';
import configData from "../../../common.json";

const { Link } = Routing;

const useStyles = (theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        // backgroundImage: 'url(https://40.89.185.245/login.jpg)',
        backgroundImage: `url(${loginImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.form = {
            onSubmit: this._onSubmit,
            submitButtonText: "Forgot Password",
            fields: [
                {
                    name: "user_name",
                    label: "User Name",
                    autofocus: true,
                    required: true,
                    type: "text",
                    width: "100%"
                },
                {
                    name: "clinical_study",
                    label: "Name of the clinical study",
                    required: true,
                    width: "70%",
                    type: "select",
                    options: [
                        {
                            key: "Multidom", value: "multidom"
                        },
                        {
                            key: "Hepadom", value: "hepadom"
                        }

                    ]
                },
            ]
        }
    }

    _onSubmit() {
        return new Promise((resolve, reject)=> {
            const data = this.state.data;
            const options = {
                entity: "users",
                data: {
                    user_name: data.user_name
                }
            };
            //depends upon clinical_study corresponding entity is selected here
            const SelectedEntity=(data.clinical_study===configData.clinical_study.HEPADOM_CLINICAL_STUDY)? HepadomEntity : Entity;

            SelectedEntity.call("forgot_password", options)
                .then((response)=> {
                    resolve(response);
                })
                .catch((err)=> {
                    err.message = err.error;
                    reject(err);
                })
        })
    }

    render() {
        const { t,classes } = this.props;
        return (
            <Layout classes={classes} text={t("Forgot Password")}>
                <Form options={this.form}/>
                <Grid container style={{marginTop: "1%"}}>
                    <Grid item>
                        <Link
                              variant="body2" to="/sign-in">
                            {t("Sign In")}
                        </Link>
                    </Grid>
                </Grid>
            </Layout>
        )
    }
}

export default withStyles(useStyles)(withTranslation('common')(ForgotPassword))
