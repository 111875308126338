import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
import { Entity } from "utils/services/entity";
import Select from '@material-ui/core/Select';
import { withTranslation } from 'react-i18next';
import {withStyles} from "@material-ui/core";

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

class Selector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    getData(options) {
        return new Promise((resolve, reject)=> {
            Entity.list(options)
                .then((response)=> {
                    const records = response.data.records;
                    resolve(records);
                })
                .catch((err)=> {
                    reject(err);
                })
        });
    }

    componentDidMount() {
        const { field } = this.props;
        if (field.resolve && typeof field.resolve === "object" && Object.keys(field.resolve).length) {
            const { entity, fields, filters } = field.resolve;
            const options = {
                entity,
                query: {
                    fields,
                    filters
                }
            };
            this.getData(options)
                .then((records)=> {
                    const options = records.map((record)=> { return { key: record[field.resolve.key], value: record[field.resolve.value] } });

                    this.setState({ ...this.state, options });
                })
                .catch((err)=> {
                    // do nothing
                })

        } else if (field.options) {
            this.setState({ ...this.state, options: field.options });
        }
    }

    render() {
        let { t, field, onChange, disabled, error, helpertext} = this.props;

        return(
            <React.Fragment>
                <InputLabel htmlFor="age-simple">&nbsp;{t(`Select ${field.label}`)}</InputLabel>
                <Select
                    id={field.id}
                    label={t(field.label)}
                    inputProps={{
                        name: field.name,
                        id: 'age-simple',
                    }}
                    disabled={disabled}
                    name={field.name}
                    value={t(this.props.value)}
                    onChange={onChange}
                    autoFocus={field.autoFocus}
                    error={error}
                    helpertext={helpertext}
                >
                    <MenuItem value="" disabled>
                        <em>{t(`Select ${field.label}`)}</em>
                    </MenuItem>
                    {this.state.options.map((option, index) => <MenuItem key={index}
                                                                    value={option.value}>{t(option.key)}</MenuItem>)}

                </Select>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(withTranslation('common')(Selector));
