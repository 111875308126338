import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import { graphColor } from '../../utils/common';
import moment from 'moment';

function getData(props) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "sensors",
            query: {
                name: "temperaturePhase",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        Entity.call("graph", options)
            .then(res => {
                if (res.status !== 200) return reject();

                resolve(res.data.records);
            })
            .catch(err => {
                reject();
            })
    })
}


class TemperaturePhase extends React.Component {

    state = { data: [], options: {} };
    constructor(props) {
        super(props);

        getData(props)
            .then(records => {
                const options = {
                    title: "Acrophase du rythme de température",
                    vAxis: { title: 'heure (décimale)' },
                    hAxis: {
                        type: 'category'
                    },
                    legend: { position: 'top', alignment: 'end' },
                    // colors:[graphColor.temperature],
                    // lineWidth: 1,
                    series: {
                        0: { color: graphColor.temperature },
                        1: { color: graphColor.alert, lineDashStyle: [5, 5, 5], pointSize: 0, lineWidth: 1 },
                        2: { color: graphColor.alert, lineDashStyle: [5, 5, 5], pointSize: 0, lineWidth: 1 },
                    },
                    pointSize: 5,
                    interpolateNulls: false


                };

                records = records.map((record) => {
                    record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm')
                    return record;
                });

                // records = getSeries(records);
                // records.unshift(["Time", "Phase du rythme de temperature"]);
                records.unshift(["Time", "Acrophase du rythme de température", "Intervalle anormal",""]);

                this.setState({ ...this.state, data: records, options });
                // this.state.data.forEach(function (row, index) {
                //     console.log("row", row)
                //     if (index === 0) {
                //         // add column heading
                //         row.push({
                //             role: 'style',
                //             type: 'string'
                //         });

                //     } else {
                //         // add color for row
                //         if ((row[1] >= 7.0) && (row[1] <= 22.0)) {
                //             console.log("orange", row[1])
                //             row.push(graphColor.temperature);
                //             // options.
                //         } else {
                //             console.log("red", row[1])
                //             row.push(graphColor.alert);
                //             //options.series.row[0].push(graphColor.alert)
                //         }
                //     }
                // });
            })
            .catch(err => {
                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    },]
                ];
                const options = {
                    title: "Acrophase du rythme de température (Aucune donnée disponible)",
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({ ...this.state, data: records, options });
            })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.graphDate !== this.props.graphDate) {
            getData(this.props)
                .then(records => {
                    const options = {
                        title: "Acrophase du rythme de température",
                        vAxis: { title: 'heure (décimale)' },
                        hAxis: {
                            type: 'category'
                        },
                        legend: { position: 'top', alignment: 'end' },
                        series: {
                            0: { color: graphColor.temperature },
                            1: { color: graphColor.alert, lineDashStyle: [5, 5, 5], pointSize: 0, lineWidth: 1 },
                            2: { color: graphColor.alert, lineDashStyle: [5, 5, 5], pointSize: 0, lineWidth: 1 },
                        },
                        pointSize: 5,
                        interpolateNulls: false
                    };

                    records = records.map((record) => {
                        record[0] = moment(record[0]).format('DD-MM-YYYY HH:mm')
                        return record;
                    });

                    // records = getSeries(records);
                    // records.unshift(["Time", "Phase du rythme de temperature"]);
                    records.unshift(["Time", "Acrophase du rythme de température", "Intervalle anormal",""]);

                    this.setState({ ...this.state, data: records, options });

                })
                .catch(err => {
                    const records = [
                        [{
                            f: 'Date',
                            type: 'date'
                        }, {
                            f: 'Line',
                            type: 'number'
                        },]
                    ];
                    const options = {
                        title: "Acrophase du rythme de température (Aucune donnée disponible)",
                        series: {
                            0: {
                                color: 'transparent'
                            }
                        }
                    };
                    this.setState({ ...this.state, data: records, options });
                })
        }
    }

    render() {
        if (!this.state.data.length) return <div className="loader" />;
        return (
            <Chart
                chartType="LineChart"
                loader={<div>Phase du rythme de temperature</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />
        )
    }

}


export default TemperaturePhase;
