import React from 'react';
import {any} from 'prop-types';
import {connect} from 'react-redux';
import Routing from 'utils/routing';

const { Route } = Routing;

export const PrivateRoute = ({user, component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={
                (props) => {
                    props.user = JSON.parse(JSON.stringify(user));                
                    return <Component {...props} />;
                }
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: any.isRequired,
};

export const mapStateToProps = state => () => {
    return ({
        user: state.user,
    });
};

export default connect(mapStateToProps)(PrivateRoute);
