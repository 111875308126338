import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import RadioGroups from 'components/RadioGroup';
import FormControl from "@material-ui/core/FormControl";
import { Entity } from 'utils/services/entity';
import {
    Divider,
    Button
} from '@material-ui/core';
import Form from 'components/Form';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThumbUpAltOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {refreshPage} from '../../../../../utils/common'


const numberRegex = /[^0-9]/g;
const answers = [];
const hours = 'hours';
const minutes = 'minutes';
var tempanswers=[];
var count=0;
var quecount=0;
class Pittsburgh extends React.Component {
    state = {
        variant: "success",
        open: false,
        message: "",
        disabled: false,
        sleepDuration: '.0',
        question1: '.0',
        question2: '.0'
    };
    onChange(options) {
        const { data } = options;
        let index;
        if(data.name.includes("^"))
        {
        index=data.name.split("^")[1];
        data.name=data.name.replace("^"+index,"");

        }
        if(parseInt(index)>=9)
        index=parseInt(index)+1;
        if(data.name==='5de4f251368f950da5bd89e0')
        index=9;
        if(data.name=== '5de4f22f3894e20ce67f545c'||data.name=== '5de4f22f3894e20ce67f545d'||data.name=== '5de4f22f3894e20ce67f545e'||data.name=== '5de4f22f3894e20ce67f545f')
        {      
        const match = answers.find(item => item._id === data.name);
        if (match) {
            match.value = data.value;
        } else {
            answers.push({ _id: data.name, value: data.value });
            
        }
      }
      else
      {
        if((!tempanswers[index])&&((parseInt(index))<=(parseInt(quecount)))&&(!(index==9)))
        count=count+1;
        if(parseInt(index)<=(parseInt(quecount)+1))
        tempanswers[index]= data.name+":"+data.value;
        if(!(count<quecount)||count==0)
        {
            if(count==0)
            {
             const match = answers.find(item => item._id === data.name);
             if (match) {
                 match.value = data.value;
             } else {
                 answers.push({ _id: data.name, value: data.value });
                 
             }
           }
            else
            {
            count=0;
            quecount=0;
         for (let i = 0; i < tempanswers.length; i++) 
         answers.push({ _id: tempanswers[i].split(":")[0], value: tempanswers[i].split(":")[1] });
         this.setState({ ...this.state, answers });
            }
           
        }
    }
      
    }
 

    save() {
        const valid = this.validateSleepDisorder();
        if (!valid) {
            const message = this.props.t('All Questionnaires are mandatory');
            this.setState({ open: true, variant: 'error', message, loading: false });
            return;
        }
        const { id } = this.props;
        this.setState({ ...this.state, loading: true });
        const options = {
            entity: "questionnaire",
            entity_id: id,
            data: { answers }
        };

        Entity.edit(options)
            .then((response) => {
                this.setState({ ...this.state, open: true, variant: 'success', message: response.message, loading: false, disabled: true });
                refreshPage()
            })
            .catch((err) => {
                this.setState({ ...this.state, open: true, variant: 'error', message: err.error, loading: false });
            })
    }

    handleClose = () => {
        this.setState({ ...this.state, open: false });
    };

    validateSleepDisorder = () => {
        let valid = true
        const { sleepDisorderReason, sleepDisorderComment, sleepDisorderFrequency, sleepDuration } = this.state;
        if (!sleepDisorderReason) {
            valid = false;
        } else if (sleepDisorderReason === '1' && (!sleepDisorderComment || !sleepDisorderFrequency)) {
            valid = false
        } else {
            const durationArr = sleepDuration.split('.');
            const durationHours = durationArr[0] || '';
            const durationMinutes = durationArr[1] || '';
            if (!durationHours || !durationMinutes) {
                valid = false;
            }
        }
        return valid;
    }

    sleepDisorderReasonChange = (e, question_id) => {
        const sleepDisorderReason = e.target.value;
        this.setState({ sleepDisorderReason });
        this.onChange({ data: { name: question_id, value: sleepDisorderReason } });
    }

    commentChange = (e, question_id) => {
        let sleepDisorderComment = e.target.value;
        if (sleepDisorderComment) {
            sleepDisorderComment = sleepDisorderComment.replace(/##/g, '');
        }
        this.setState({ sleepDisorderComment });
        const combinedValue = `${this.state.sleepDisorderReason}##${sleepDisorderComment}##${this.state.sleepDisorderFrequency || ''}`;
        this.onChange({ data: { name: question_id, value: combinedValue } });
    }

    sleepDisorderFrequencyChange = (e, question_id) => {
        const sleepDisorderFrequency = e.target.value;
        this.setState({ sleepDisorderFrequency });
        const combinedValue = `${this.state.sleepDisorderReason}##${this.state.sleepDisorderComment || ''}##${sleepDisorderFrequency}`;
        this.onChange({ data: { name: question_id, value: combinedValue } });
    }

    renderSleepDisorderComment = (question_id) => {
        const { classes, activated } = this.props;
        const formStyles = {
            width: '100%',
        };
        const textareaStyles = {
            fontSize: '14px',
            margin: '0 0 20px 10px',
            minHeight: '60px',
            minWidth: '250px'
        }
        return (
            <FormControl className={classes.formControl} style={formStyles}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg md sm={12}></Grid>
                    <Grid item xs={12} lg md sm={12}>
                        <TextareaAutosize
                            style={textareaStyles}
                            // rowsMin={3}
                            variant="outlined"
                            margin="normal"
                            value={this.state.sleepDisorderComment}
                            disabled={this.state.disabled || activated === false}
                            name="sleepDisorderComment"
                            onChange={(e) => this.commentChange(e, question_id)}
                        />
                    </Grid>
                </Grid>
            </ FormControl>
        );
    }

    renderSleepDisorderDetails = (data) => {
        const { classes, activated } = this.props;
        const options = data.options.map((option) => {
            return { value: option.value, label: option.key }
        });
        const field = { ...data, options, label: 'Indiquez la fréquence des troubles du sommeil pour ces raisons' };
        return (
            <div>
                {this.renderSleepDisorderComment(field._id)}
                <FormControl
                    component="div"
                    className={classes.formControl}
                    style={{ width: '100%' }}
                >
                    <RadioGroups
                        field={field}
                        onChange={(e) => this.sleepDisorderFrequencyChange(e, field._id)}
                        value={this.state.sleepDisorderFrequency}
                        disabled={this.state.disabled || activated === false}
                        align={field.align}
                    >
                    </RadioGroups>
                </FormControl>
            </div>
        );
    }

    getSleedDisorderContent = (field) => {
        const { activated } = this.props;
        const sleedDisorderReasonField = {
            name: 'sleedDisorderReason',
            label: field.value,
            options: [
                { label: 'Oui pour la(les) raison(s) suivante(s)', value: '1' },
                { label: 'Non', value: '0' }
            ]
        };
        return (
            <div>
                <div>
                    <RadioGroups
                        field={sleedDisorderReasonField}
                        onChange={(e) => this.sleepDisorderReasonChange(e, field._id)}
                        value={this.state.sleepDisorderReason}
                        disabled={this.state.disabled || activated === false}
                    >
                    </RadioGroups>
                </div>
                {this.state.sleepDisorderReason === '1' && this.renderSleepDisorderDetails(field)}
            </div>
        );
    }

    handleSleepDurationChange = (value, durationType, question_id, name) => {
        const index = durationType === hours ? 0 : 1;
        if (numberRegex.test(value) || (index === 0 && value >= 24) || (index === 1 && value >= 60)) {
            this.setState({ sleepDuration: this.state.sleepDuration });
            return;
        }
        //const durationArr = this.state.sleepDuration.split('.');
        const durationArr = this.state[name] ? this.state[name].split('.') : '';
        durationArr[index] = value;
        const durationValue = durationArr.join('.');
        if (question_id === '5de4f22f3894e20ce67f545c') {
            this.setState({ question1: durationValue });
            // this.onChange({ data: { name: question_id, value: durationValue } });
        } else if (question_id === '5de4f22f3894e20ce67f545e') {
            this.setState({ question2: durationValue });
            // this.onChange({ data: { name: question_id, value: durationValue } });
        } else {
            this.setState({ sleepDuration: durationValue });
            // this.onChange({ data: { name: question_id, value: durationValue } });
        }
        this.onChange({ data: { name: question_id, value: durationValue } });

    }

    getSleepDurationContent = (field, name) => {
        const { activated, classes, t } = this.props;
        const durationArr = this.state[name] ? this.state[name].split('.') : ''//this.state.sleepDuration.split('.');

        console.log(durationArr, name)
        const durationHours = durationArr ? durationArr[0] : ''
        const durationMinutes = durationArr ? durationArr[1] : ''
        return (
            <div style={{ paddingBottom: '10px' }}>
                <FormControl className={classes.formControl} style={{ width: '100%' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg md>
                            <Typography variant="subtitle2" color="primary" className={classes.title}>{field.value}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} lg md>
                            <TextField
                                style={{ width: '100px' }}
                                variant="outlined"
                                margin="normal"
                                value={durationHours}
                                type="text"
                                disabled={this.state.disabled || activated === false}
                                // min="0"
                                // max="24"
                                onChange={e => this.handleSleepDurationChange(e.target.value, hours, field._id, name)}
                            >
                            </TextField>
                            <span className={classes.durationLabel}>{t(hours)}</span>
                            <TextField
                                style={{ width: '100px' }}
                                variant="outlined"
                                margin="normal"
                                value={durationMinutes}
                                type="text"
                                disabled={this.state.disabled || activated === false}
                                // min="0"
                                // max="24"
                                onChange={e => this.handleSleepDurationChange(e.target.value, minutes, field._id, name)}
                            >
                            </TextField>
                            <span className={classes.durationLabel}>{minutes}</span>
                        </Grid>
                    </Grid>
                </FormControl>
            </div>
        );
    }

    render() {
        const { questionnaires, classes, activated } = this.props;
        if(count>=quecount)
        {
        count=0;
       // tempanswers=[];
        }

        quecount=0;
        let form = [];
        questionnaires.forEach((questionnaire, index) => {
            if (index === 0) return;
            let f = {
                addDivider: true,
                onChange: this.onChange.bind(this),
                fields: []
            };
            questionnaire.questionnaires.forEach((questionnaire) => {
                if (questionnaire.type === "radio") {
                    f.fields.push({
                        name: questionnaire._id+"^"+quecount,
                        label: <div dangerouslySetInnerHTML={{ __html: questionnaire.value }} />,
                        type: "radio",
                        align: "",
                        disabled: (this.state.disabled || activated === false),
                        options: questionnaire.options.map((option) => {
                            return { value: option.value, label: option.key }
                        })
                    });
                    quecount++;
                } else if (questionnaire.type === "custom" && (questionnaire._id === '5de4f22f3894e20ce67f545c')) {
                    f.fields.push({
                        name: questionnaire._id,
                        label: <div dangerouslySetInnerHTML={{ __html: questionnaire.value }} />,
                        //ss type: "timepicker_questionnaire",
                        disabled: (this.state.disabled || activated === false),
                        type: questionnaire.type,
                        htmlContent: this.getSleepDurationContent(questionnaire, 'question1')
                    });
                }
                else if (questionnaire.type === "custom" && (questionnaire._id === '5de4f22f3894e20ce67f545e')) {
                    f.fields.push({
                        name: questionnaire._id,
                        label: <div dangerouslySetInnerHTML={{ __html: questionnaire.value }} />,
                        //ss type: "timepicker_questionnaire",
                        disabled: (this.state.disabled || activated === false),
                        type: questionnaire.type,
                        htmlContent: this.getSleepDurationContent(questionnaire, 'question2')
                    });
                }
                else if (questionnaire.type === "number") {
                    f.fields.push({
                        name: questionnaire._id,
                        label: <div dangerouslySetInnerHTML={{ __html: questionnaire.value }} />,
                        type: "number_with_seperate_label",
                        disabled: (this.state.disabled || activated === false)
                    });
                } else if (questionnaire.type === 'custom' && questionnaire._id === '5de4f251368f950da5bd89e0') {
                    f.fields.push({
                        name: questionnaire._id,
                        type: questionnaire.type,
                        disabled: (this.state.disabled || activated === false),
                        htmlContent: this.getSleedDisorderContent(questionnaire)
                    });
                } else if (questionnaire.type === 'custom' && questionnaire._id === '5de4f22f3894e20ce67f545f') {
                    f.fields.push({
                        name: questionnaire._id,
                        type: questionnaire.type,
                        disabled: (this.state.disabled || activated === false),
                        htmlContent: this.getSleepDurationContent(questionnaire, 'sleepDuration')
                    });
                }
            });
            form.push(f);
        });
        if (activated === false) return (<Grid container spacing={3}>
            <Grid item xs>
                <ThumbUpAltOutlined variant="subtitle2" color="primary" /><Typography variant="subtitle2" color="primary">Déjà répondu</Typography>
            </Grid>
        </Grid>);
        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "left" }}>
                        <h4>{questionnaires[0]}</h4>
                    </Grid>
                </Grid>
                <br />
                {questionnaires.map((questionnaire, index) => {
                    if (index === 0) return <React.Fragment key={index} />;
                    return <React.Fragment key={index}>
                        <div className={classes.description} dangerouslySetInnerHTML={{ __html: questionnaire.description }} />
                        <Form className={classes.formControl} options={form[index - 1]} />
                        <Divider className={classes.divider} />
                    </React.Fragment>
                })}
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    style={{ position: 'absolute', right: '100px' }}
                    key={`top,left`}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={this.state.variant}
                        message={this.state.message}
                    />
                </Snackbar>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.save.bind(this)} disabled={(this.state.disabled || activated === false)}>
                            {this.state.loading ? <CircularProgress
                                size={40}
                                className={classes.buttonProgress} /> : "Enregistrer"}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const useStyles = (theme) => ({
    root: {
        position: 'relative'
    },
    description: {
        textAlign: "left",
    },
    formControl: {
        textAlign: "left"
    },
    button: {
        margin: theme.spacing(1),
    },
    divider: {
        marginBottom: "3%"
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    durationLabel: {
        verticalAlign: 'bottom',
        display: 'inline-block',
        padding: '0 10px 5px 5px'
    },
    title: {
        padding: theme.spacing(1.5),
        fontSize: '1rem',
        textAlign: 'justify',
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'nowrap'
    }
    // formControl: {
    //     minWidth: 120,
    // }
});

export default withStyles(useStyles)(withTranslation('common')(Pittsburgh));
