import '@fullcalendar/core/main.css';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/daygrid/main.css';
import interaction from "@fullcalendar/interaction";
import FullCalendar from '@fullcalendar/react';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Dashboard from 'components/Dashboard';
import Form from 'components/Form';
import React from 'react';
import { Entity } from 'utils/services/entity';
import frlocale from "@fullcalendar/core/locales/fr";
import { withTranslation } from 'react-i18next';

class CalendarNote extends React.Component {
    state = {
        date : new Date(),
        calendarWeekends: true,
        calendarEvents: [],
        header: {
            left: 'promptResource',
            center: 'title',
            right: "prev,next"
        },
        addNote: false,
    };
    constructor(props){
        super(props);

        this.form = {
            onSubmit: this._onSubmit,
            submitButtonText: "Save",
            buttonWidth: '20%',
            fields:[
                {
                    name: "title",
                    label: "Title",
                    required: true,
                    width: "90%",
                    type: "text"
                },
                {
                    name: "date",
                    label: "Date of Appointment",
                    required: true,
                    width: "90%",
                    type: "datetimepicker",
                    value: this.state.date
                },
                {
                    name: "patient",
                    label: "Patient",
                    required: true,
                    resolve: {
                        entity: "patients",
                        fields: { user_name: 1, _id: 1 },
                        key: "user_name",
                        value: "_id",
                    },
                    width: "90%",
                    type: "select",
                }
            ]
        }
    }

    getAppointments(user) {
        return new Promise((resolve, reject)=> {
            let key = "patient";
            let entity = "patients";
            if (user.role === "patient") {
                key = "doctor";
                entity = "doctors";
            }
            const options = {
                entity: "appointments",
                query: {
                    resolve: [
                        { key, entity, fields: { user_name: 1 } }
                    ]
                }
            };
            Entity.list(options)
                .then((response)=> {
                    resolve(response.data.records);
                })
                .catch((err)=> {
                    reject(err);
                })
        })
    }

    componentDidMount() {
        const { user } = this.props;
        this.getAppointments(user)
            .then((records)=> {
                const calendarEvents = records.map((record)=> {
                    let username;
                    if (user.role === "patient") username = record.doctor.user_name;
                    else username = record.patient.user_name;
                    return {
                        title: `${record.title} with ${username}`,
                        start: record.date
                    }
                });

                this.setState({
                    ...this.state,
                    calendarEvents,
                    addNote: false
                })
            })
            .catch((err)=> {
                // do nothing for now
            });
    }

    handleClose(info) {
        this.setState({...this.state, addNote: false});
    }

    customButton() {
        const _this = this;
        const {t, user } = this.props;
        if (user.role === "patient") return {};
        return {
            promptResource: {
                text: t('Add Appointment'),
                click: function () {
                    _this.setState({..._this.state, addNote: true});
                }
            }
        }
    }

    addNote() {
        const events = this.state.calendarEvents;
        const note = this.state.note;

        events.push({ title: note, date: `2019-11-19`});
        this.setState({ ...this.state, events, addNote: false })
    }

    _onSubmit() {
        return new Promise((resolve, reject)=> {
            const { t } = this.props;
            const data = JSON.parse(JSON.stringify(this.state.data));
            const options = {
                entity: "appointments",
                data
            };

            Entity.add(options)
                .then((response)=> {
                    resolve({message: t("Added Successfully")});
                    this.parameters.refresh();
                })
                .catch((err)=> {
                    reject({message: err.error });
                })
        });

    }

    refresh() {
        const { user } = this.props;
        this.getAppointments(user)
            .then((records)=> {
                const calendarEvents = records.map((record)=> {
                    return {
                        title: `${record.title} with ${record.patient.user_name}`,
                        start: record.date
                    }
                });

                this.setState({
                    ...this.state,
                    calendarEvents,
                    addNote: false
                })
            })
            .catch((err)=> {
                // do nothing for now
            });
    }

    render() {
        const {t,classes} = this.props;
        return (
            <Dashboard title={t("Calendar")}>
                <FullCalendar defaultView="dayGridMonth"
                              plugins={[dayGridPlugin, interaction]}
                              events={this.state.calendarEvents}
                              customButtons={this.customButton.call(this)}
                              header={this.state.header}
                              editable = {false}
                              nowIndicator
                              selectable = {true}
                              handleWindowResize = {true}
                              weekends = {this.state.calendarWeekends}
                              snapDuration="00:05"
                              locale = {frlocale}
                />
                <Dialog className={classes.root}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.addNote}
                    onClose={this.handleClose.bind(this)}
                >
                        <DialogContent>
                            <Form options={this.form} title={t("Add Appointment : ")} parameters={{refresh : this.refresh.bind(this)}}/>
                        </DialogContent>
                </Dialog>
            </Dashboard>

        );
    }
}

const useStyles = (theme) => ({
    root: {
        position: 'absolute',
        width: "95%",
        top: "40%",
        left: "80%",
        transition: "all 2s"
    },
    chip: {
      margin: theme.spacing(1),
    },
    card: {
        maxWidth: 300,
      },
    content: {
        flex: '1 0 auto',
    },
    action: {
        marginTop: "8px",
        marginRight: "8px"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
});

export default withStyles(useStyles)(withTranslation('common')(CalendarNote));
