import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import Tooltip from "@material-ui/core/Tooltip";
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import { Entity } from "utils/services/entity";
import { HepadomEntity } from "utils/services/hepadom_entity";
import configData from "../../common.json";

import { withStyles } from "@material-ui/core";
import Routing from 'utils/routing';
import {
    Edit,
    Delete,
    ViewComfy as View,
    QuestionAnswer as Survey,
    CloudDownload as Download
} from '@material-ui/icons';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';

const { Link } = Routing;

function getRecord(record, key, filter = (columns) => columns) {
    const keys = key.split(".");
    if (keys.length === 1) {
        return filter(record[keys[0]] || "");
    } else {
        if (record[keys[0]]) {
            const obj = record[keys[0]];
            keys.splice(0, 1);
            return getRecord(obj, keys.join("."), filter);
        } else {
            return filter("");
        }
    }
}

class Table extends Component {
    state = {
        page: 0,
        data: [["Loading Data..."]],
        isLoading: true,
        open: true,
        variant: "success",
        downloading: false,
        disabled: false,
        SelectedEntity : window.location.pathname.includes(configData.clinical_study.HEPADOM_CLINICAL_STUDY)? HepadomEntity : Entity,
        language:this.props.i18n.language,
        isClear: false,
        searchText: null
    };

    delete = (entity, _id, userName) => {
        const { t } = this.props;
        var msg = t("Are you sure you want to delete?");
        var x = window.confirm(msg);
        if (!x) return false;

        const options = {
            entity,
            entity_id: _id,
            user_name: userName
        };

        this.state.SelectedEntity.delete(options)
            .then((response) => {
                const state = {
                    ...this.state,
                    open: true,
                    variant: 'success',
                    message: t(response.data),
                };

                this.setState(state);

                const { entity, filters, fields, actions } = this.props;

                const options = {
                    entity,
                    filters,
                    fields,
                    start: 0,
                    actions
                };

                this.loadData(options);
            })
            .catch((err) => {
                const state = {
                    ...this.state,
                    open: true,
                    variant: 'error',
                    message: t(err.error),
                };
                this.setState(state);
            })
    };

    showFile(filename, blob) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.

        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    downloadFile(data, path, name) {
        const { access_token, accountName, dnsSuffix, filesystem } = data;
        fetch(`https://${accountName}.${dnsSuffix}/${filesystem}/${path}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${access_token}`
            }
        })
            .then(r => r.blob())
            .then((blob) => {
                this.setState({ ...this.state, downloading: false, disabled: false });
                this.showFile(name, blob);
            })
    }

    getToken(name, path, entity) {
        const options = {
            entity: "reports"
        };
        this.setState({ ...this.state, downloading: "Downloading", disabled: true });
        this.state.SelectedEntity.call("getToken", options)
            .then((response) => {
                this.downloadFile(response.data, path, name);
            })
            .catch((err) => {
                // do nothing
            })
    }
    competeStudy = (_id)=> {
        const { t } = this.props;
        var msg = t("Are you sure you want to complete?");
        let x = window.confirm(msg);
        if (!x) return false;
      //  this.setState({ ...this.state, loader: true });
        const options = {
            entity: "patients",
            query: {
                patient_id: _id
            }
        };
        this.state.SelectedEntity.call("complete_study", options)
            .then(response=> {
                const status = response.status; 
                if (status !== 200) {
                    this.setState({ ...this.state, error: response.error });
                }
            })
            .catch(err=> {
                this.setState({ ...this.state, error: err.error });
            })
    };
    checkCollectorID(record){
        if (record.deactivated === true) {
           this.setState({...this.state,disabled: true});
        }else{
            this.setState({...this.state,disabled: false});
         }
    }
    loadData(options) {
        return new Promise((resolved, reject) => {
            const { t, fileField ,disabled} = this.props;
            this.setState({ ...this.state, isLoading: true });
            const pathName = window.location.pathname;
            let { entity, filters, start, limit, fields, actions, resolve, isSearch } = options;

            if (!start) start = 0;
            if (!limit) limit = 100;
            if (!Array.isArray(filters)) filters = [];

            let columns = {};
            let show_columns = {};
            if (Array.isArray(fields)) {
                const columns1 = fields.map(field => t(field.value));
                if (Array.isArray(actions) && actions.length) {
                    columns1.push(t("Action"));
                }
                this.setState({ ...this.state, columns: columns1 });
                fields.forEach((field) => {
                    const key = field.key.split(".")[0];
                    show_columns[field.key] = field.filter || ((columns) => columns);
                    columns[key] = 1
                });
            }
            columns["deactivated"] = 1;
            columns["encrypted"] = 1;

            const request = {
                entity,
                query: {
                    limit,
                    filters,
                    fields: columns,
                    start,
                    resolve,
                    total: 1,
                    isSearch
                }
            };
            this.state.SelectedEntity.list(request)
                .then((response) => {
                    const records = response.data.records;
                    const data = [];

                    records.forEach((record) => {
                        this.checkCollectorID(record);
                        const tabelRecords = [];
                        Object.keys(show_columns).forEach((key) => {
                            const r = getRecord(record, key, show_columns[key]);
                            tabelRecords.push(r);
                        });

                        if (Array.isArray(actions) && actions.length) {
                            const actionIcons = actions.map((action, index) => {
                                let action1;
                                switch (action) {
                                    case 'edit':
                                        action1 =
                                            <Tooltip key={index} title={t("Edit")} placement="right">
                                                <IconButton aria-label="view"><Link to={`${pathName}/${record._id}/_edit`}><Edit /></Link></IconButton>
                                            </Tooltip>;
                                        break;
                                    case 'delete':
                                        action1 = <Tooltip key={index} title={t("Delete")}><Button onClick={e => this.delete(entity, record._id, record.user_name)}><Delete /></Button></Tooltip>;
                                        break;
                                    case 'view':
                                        action1 =
                                            <Tooltip key={index} title={t("View")} placement="left">
                                                <IconButton aria-label="view"><Link to={`${pathName}/${record._id}/view`}><View /></Link></IconButton></Tooltip>;
                                        break;
                                    case 'survey':
                                        action1 =
                                            <Tooltip key={index} title={t("Survey")} placement="right">
                                                <IconButton aria-label="Survey"><Link to={`${pathName}/${record._id}/survey`}><Survey /></Link></IconButton></Tooltip>;
                                        break;
                                    case "download":
                                        action1 =
                                            <Tooltip key={index} title={t("Download")} placement="right">
                                                <IconButton aria-label="Survey"><a onClick={e => this.getToken.call(this, record[fileField], record.path, entity)}><Download /></a></IconButton>
                                            </Tooltip>;
                                        break;
                                        case 'completeStudy':
                                            action1 = <Tooltip key={index} title={t("Delete")}><Button onClick={e => this.competeStudy(record._id)}  disabled={this.state.disabled}><Delete /></Button></Tooltip>;
                                            break;
                                    default:
                                        action1 = null;
                                }
                                return action1;
                            });
                            // if (record.deactivated === true) {
                            //   tabelRecords.push(<React.Fragment />);
                           // } else tabelRecords.push(<React.Fragment>{actionIcons.map(action => action)}</React.Fragment>)
                            tabelRecords.push(<React.Fragment>{actionIcons.map(action => action)}</React.Fragment>)
                    
                        }
                        data.push(tabelRecords);

                    });
                    const processDataCallback = (this.props.options || {}).processData;
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        data: processDataCallback ? processDataCallback(data) : data,
                        count: response.data.total,
                        page: start / limit,

                    });
                })
                .catch((err) => {
                    this.setState({ isLoading: false, count: 1, data: [[t("Error while getting data")]] });
                })
        });
    }

    componentDidMount() {
        const { entity, filters, fields, actions, resolve } = this.props;
        const options = {
            entity,
            filters,
            fields,
            start: 0,
            actions,
            resolve
        };

        this.loadData.call(this, options);
    }
    componentDidUpdate(prevProps) {
        if(prevProps.i18n.language!==this.state.language){
         this.setState({ ...this.state, language: prevProps.i18n.language });
         window.location.reload();
        }
    }
    handleClose = () => {
        this.setState({ ...this.state, open: false });
    };
    setSearchText = () => {
        this.setState({ ...this.state, searchText: "" });
        const { entity, filters, fields, actions, resolve } = this.props;
        const options = {
            entity,
            filters,
            fields,
            start: 0,
            actions,
            resolve
        };

        this.loadData.call(this, options);
    }
    render() {
  
        const { t,title } = this.props;
        let timeout;
        const propOptions = this.props.options || {};
        const options1 = {
            filterType: 'checkbox',
            textLabels: {
                body: {
                    noMatch: t('Sorry, no matching records found'),
                    toolTip: t('Sort'),
                },
                pagination: {
                    displayRows: t('of')
                },
                toolbar: {
                    search: t('Search'),
                }
            },
            download: false,
            print: false,
            filter: false,
            viewColumns: false,
            selectableRows: false,
            serverSide: false,
          };
          
        const options = {
            textLabels: {
                body: {
                    noMatch: t('Sorry, no matching records found'),
                },
                pagination: {
                    displayRows: t('of')
                },
                toolbar: {
                    search: t('Search'),
                }
            },
            filter: false,
            print: false,
            sort: false,
            download: false,
            searchOpen: true,
            serverSide: false,
            viewColumns: false,
            count: this.state.count,
            rowsPerPageOptions: false,
            page: this.state.page,
            responsive: "scroll",
            selectableRows: false,
            onTableChange: (action, tableState) => {

                let options;
                let { entity, filters, fields, actions, resolve } = this.props;
                switch (action) {
                    case 'changePage':
                        options = {
                            entity,
                            filters,
                            fields,
                            resolve,
                            start: tableState.page * 10,
                            actions
                        };
                        this.loadData.call(this, options);
                        break;
                    case 'search':

                        const searchText = this.state.searchText === "" ? "" : tableState.searchText;

                        if(searchText === ""){
                            this.setState({ ...this.state, isClear: false });
                        }
                        if(searchText && searchText.length > 0){
                            this.setState({ ...this.state, isClear: true });
                        }

                        if (timeout) {clearTimeout(timeout);}
                        timeout = setTimeout(() => {
                            if (!Array.isArray(filters)) {filters = [];};
                            let field = "first_name";
                            if (entity === "patients") {field = "user_name";}

                            if (searchText) {
                                filters.push({
                                    field,
                                    value: searchText,
                                    operator: "regex"
                                });
                             }
                             
                            options = {
                                entity,
                                filters,
                                fields,
                                actions,
                                resolve,
                                isSearch: searchText ? 1 : 0
                            };
                            this.loadData.call(this, options);
                        }, 1500);
                        break;
                    default:
                    // do nothing
                }
            },
            ...propOptions
        };
        
        const myTheme = createMuiTheme({
            overrides: {
                MUIDataTable: {
                    paper: {
                        height: 'inherit'
                    },
                    responsiveScroll: {
                        maxHeight: 'none',
                        height: 'calc(100%-10%)'
                    }
                },
            }
        });
        if (this.state.isLoading) {
            return (<div className="loader" />);
        }
        if (this.props.addShow || this.props.clearShow) options["customToolbar"] = () => {
            return (
                <CustomToolbar addLink={this.props.addLink} t={this.props.t} add={this.props.addShow} isClear={this.state.isClear} clearSearch={this.setSearchText}/>
            );
        };
       
        //new table
   
        if (true) options1["customToolbar"] = () => {
            return (
                <CustomToolbar addLink={this.props.addLink} t={this.props.t} add={this.props.addShow} isClear={this.state.isClear} clearSearch={this.setSearchText}/>
            );
        };

        return (
            <React.Fragment>
                {this.state.downloading ? <LinearProgress /> : <div />}
                <MuiThemeProvider>
                  <MUIDataTable
                  title={<div style={{ width : "300px"}}>{t(title)}</div>}
                  data={this.state.data}
                  columns={this.state.columns}
                  options={options1}
                />
                {this.state.message && <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={this.state.open}
                        autoHideDuration={2000}
                        onClose={this.handleClose}
                    >
                        <SnackbarContentWrapper
                            onClose={this.handleClose}
                            variant={this.state.variant}
                            message={this.state.message}
                        />
                    </Snackbar>
}
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}

Table.propTypes = {
    entity: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired
};

class CustomToolbar extends React.Component {

    render() {
        const classes = {};
        const t=this.props.t;
       

        return (
            <React.Fragment>                 
              { this.props.add && <Tooltip title={t("Add")}>                
                <Button variant="outlined" component={Link} color="primary" className={classes.button}
                to={this.props.addLink}>
                {t("Add")}
                </Button>
          
                </Tooltip>
            }
           
            </React.Fragment>
        );
    }

}

const useStyles = (theme) => ({
    MuiButton: {
        root: {
            padding: "none"
        }
    },
    toolbarhead:{
        marginRight:"5px"
    }
   
});

export default withStyles(useStyles)(withTranslation('common')(Table));
