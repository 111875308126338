export const HepadomEntity = {
    add: async function (options = {}) {
        return this.call("add", options);
    },
    delete: async function (options = {}) {
        return this.call("delete", options);
    },
    edit: async function (options = {}) {
        return this.call("edit", options);
    },
    get: async function (options = {}) {
        return this.call("get", options);
    },
    list: async function (options = {}) {
        return this.call("list", options);
    },
    export: async function (options = {}) {
        return this.call('export', options);
    },
    call: function (command, options = {}) {
        return new Promise((resolve, reject) => {
            if (!options.entity) return reject({ status: 400, error: "Missing Entity Name" });

            let query = options.query;
            if (!query) query = {};

            switch (command) {
                case 'add':

                    if (!options.data) return reject({ status: 400, error: "Missing Data" });
                    if (typeof options.data !== 'object') return reject({ status: 400, error: "Invalid Data object" });

                    const addUrl = `/api/1/hepadom/entity/${options.entity}`;

                    fetch(addUrl, {
                        method: "post",
                        body: JSON.stringify(options.data)
                    })
                        .then(response => response.json())
                        .then((response) => {
                            if (response.status === 200) return resolve(response);
                            else return reject(response);
                        })
                        .catch(err => reject({ status: 500, error: err }));

                    break;
                case 'delete':

                    if (!options.entity_id) return reject({ status: 400, error: "Missing Entity Id" });

                    const deleteUrl = `/api/1/hepadom/entity/${options.entity}/${options.entity_id}/${options.user_name}`;
                    fetch(deleteUrl, {
                        method: "delete"
                    })
                        .then(response => response.json())
                        .then((response) => {
                            if (response.status === 200) return resolve(response);
                            else return reject(response);
                        })
                        .catch(err => reject({ status: 500, error: err }));

                    break;
                case 'edit':

                    if (!options.entity_id) return reject({ status: 400, error: "Missing Entity Id" });

                    if (!options.data) return reject({ status: 400, error: "Missing Data" });
                    if (typeof options.data !== 'object') return reject({ status: 400, error: "Invalid Data object" });

                    const editUrl = `/api/1/hepadom/entity/${options.entity}/${options.entity_id}`;
                    fetch(editUrl, {
                        method: "put",
                        body: JSON.stringify(options.data)
                    })
                        .then(response => response.json())
                        .then((response) => {
                            if (response.status === 200) return resolve(response);
                            else return reject(response);
                        })
                        .catch(err => reject({ status: 500, error: err }));

                    break;
                case 'get':

                    if (!options.entity_id) return reject({ status: 400, error: "Missing Entity Id" });

                    if (!query.fields) query.fields = {};
                    if (typeof query.fields !== 'object') query.fields = {};

                    if (query.fields && typeof query.fields === 'object') {
                        try {
                            query.fields = JSON.stringify(query.fields);
                        } catch (e) {
                            query.fields = {};
                        }
                    }

                    const getUrl = `/api/1/hepadom/entity/${options.entity}/${options.entity_id}?fields=${encodeURIComponent(query.fields)}`;
                    fetch(getUrl)
                        .then(response => response.json())
                        .then((response) => {
                            if (response.status === 200) return resolve(response);
                            else return reject(response);
                        })
                        .catch(err => reject({ status: 500, error: err }));

                    break;
                case 'list':

                    if (!options.query) options.query = {};

                    if (!query.filters) query.filters = [];
                    if (!query.start) query.start = 0;
                    if (!query.limit) query.limit = 50;
                    if (!query.fields) query.fields = {};
                    if (!query.resolve) query.resolve = [];
                    if (!query.isSearch) query.isSearch = 0;


                    if (!Array.isArray(query.filters)) query.filters = [];
                    if (typeof query.fields !== 'object') query.fields = {};

                    if (query.filters && typeof query.filters === 'object') {
                        try {
                            query.filters = JSON.stringify(query.filters);
                        } catch (e) {
                            query.filters = JSON.stringify([]);
                        }
                    }

                    if (query.fields && typeof query.fields === 'object') {
                        try {
                            query.fields = JSON.stringify(query.fields);
                        } catch (e) {
                            query.fields = {};
                        }
                    }

                    if (query.resolve && typeof query.resolve === "object") {
                        try {
                            query.resolve = JSON.stringify(query.resolve);
                        } catch (e) {
                            query.resolve = JSON.stringify([]);
                        }
                    }

                    if (query.start && isNaN(query.start)) query.start = 0;

                    if (query.limit && isNaN(query.limit)) query.limit = 50;

                    const url = `/api/1/hepadom/entity/${options.entity}?filters=${encodeURIComponent(query.filters)}&start=${encodeURIComponent(query.start)}&limit=${encodeURIComponent(query.limit)}&fields=${encodeURIComponent(query.fields)}&resolve=${encodeURIComponent(query.resolve)}&isSearch=${encodeURIComponent(query.isSearch)}`;
                    fetch(url)
                        .then(response => response.json())
                        .then((response) => {
                            if (response.status === 200) return resolve(response);
                            else return reject(response);
                        })
                        .catch(err => reject({ status: 500, error: err }));

                    break;
                case "export":
                    let payload = {};

                    if (options.payload && typeof options.payload !== 'object') return reject({ status: 400, error: "Invalid Data Object" });
                    else if (options.payload) payload = options.payload;

                    const exportURL = `/api/1/hepadom/export/${options.entity}/_/${options.command}`;
                    fetch(exportURL, {
                        method: "POST",
                        responseType: 'arraybuffer',
                        body: JSON.stringify(payload)
                    })
                        .then(response => response.json())
                        .then((response) => {
                            if (response.status === 200) return resolve(response);
                            else return reject(response);
                        })
                        .catch(err => reject({ status: 500, error: err }));
                    break;
                default:
                    let method = 'POST';
                    let data = {};
                    let queryParams = {};

                    if (options.method) method = options.method;

                    if (options.data && typeof options.data !== 'object') return reject({ status: 400, error: "Invalid Data Object" });
                    else if (options.data) data = options.data;

                    if (options.query && typeof options.query !== 'object') return reject({ status: 400, error: "Invalid Query Object" });
                    else if (options.query) queryParams = options.query;

                    let queryString = "";
                    let index = 0;
                    for (let key in queryParams) {
                        if (index) queryString += "&";
                        queryString += `${key}=${queryParams[key]}`;
                        index++;
                    }

                    if(options.entity==="users" && command ==="login"){
                        const customUrl =  `/api/1/common/entity/${options.entity}/_/${command}/`;
                    
                        fetch(customUrl, {
                            method,
                            body: JSON.stringify(data)
                        }).then(response => response.json())
                            .then((response) => {
                                if (response.status === 200) {                                
                                    return resolve(response);
                                }
                                else return reject(response);
                            })
                            .catch(err => reject({ status: 500, error: err }));
                    }else{
                        const customUrl =  `/api/1/hepadom/entity/${options.entity}/_/${command}?${queryString}`;
                        fetch(customUrl, {
                            method,
                            body: JSON.stringify(data)
                        }).then(response => response.json())
                            .then((response) => {
                                if (response.status === 200) {                                
                                    return resolve(response);
                                }
                                else return reject(response);
                            })
                            .catch(err => reject({ status: 500, error: err }));

                    }

                    break;
            }
        });
    }
};
