import React from "react";

import { Chart } from "react-google-charts";
import { Entity } from 'utils/services/entity';
import { HepadomEntity } from 'utils/services/hepadom_entity';
import { getSeries, graphColor, graphXYValues } from '../../utils/common'
import { withTranslation } from 'react-i18next';
import configData from "../../common.json";

//getting weight data by calling entity grpah and passing
//graph name,patient inclusion id and graph date
function getData(props,SelectedEntity) {
    return new Promise((resolve, reject) => {
        const options = {
            entity: "sensors",
            query: {
                name: "weight",
                inclusion_id: props.inclusion_id,
                graphDate: props.graphDate
            }
        };
        SelectedEntity.call("graph", options)
            .then(res => {
                if (res.status !== 200) return reject();
                resolve(res.data.records);
            })
            .catch(err => {
                reject();
            })
    })
}
//calculating average weight for the patient with weight records
function getAverageWeight(records) {
    let weights = graphXYValues(records, 1);
    weights = weights.filter(weight => weight !== null);
    let averageWt = weights.reduce((a, b) => a + b) / weights.length;
    for (let i = 0; i < records.length; i++) {
        records[i].splice(2, records.length, averageWt)
    }
    return records;
}
class Weight extends React.Component {
    state = { 
             data: [],
             options: {},
             SelectedEntity:window.location.pathname.includes(configData.clinical_study.HEPADOM_CLINICAL_STUDY) ? HepadomEntity : Entity,
             language:this.props.i18n.language
             };
             constructor(props) {
                super(props);
                const {t}=this.props;
                getData(props,this.state.SelectedEntity)
                    .then(records => {

                const options = {
                    title: t('Weight'),
                    vAxis: {
                        title: 'Kg',
                    },
                    hAxis: {
                        type: "category"
                    },
                    series: {
                        0: { color: graphColor.weight },
                        1: { lineDashStyle: [4, 4], pointSize: 0 }
                    },
                    pointSize: 5,
                    legend: { position: 'top', alignment: 'end' },
                    colors: [graphColor.weight, graphColor.weight],
                    interpolateNulls: true
                };

                records = getSeries(records);
                const avgWeight = graphXYValues(records, 2);
                //plot the graph with average weight if reference weight not found.
                if (avgWeight.includes(0)) {
                    records = getAverageWeight(records);
                    records.unshift(["X", t('Weight'), t("Average weight")]);
                } else {
                    records.unshift(["X", t('Weight'), t("Reference Weight")]);
                }
                this.setState({ ...this.state, data: records, options });
            })
            .catch(err => {

                const records = [
                    [{
                        f: 'Date',
                        type: 'date'
                    }, {
                        f: 'Line',
                        type: 'number'
                    },]
                ];
                const options = {
                    title: t('Weight (No data available)'),
                    series: {
                        0: {
                            color: 'transparent'
                        }
                    }
                };
                this.setState({ ...this.state, data: records, options });
                // do nothing
            })
    }

    componentDidUpdate(prevProps) {
        const {t}=this.props;
        if ((prevProps.graphDate !== this.props.graphDate)||(prevProps.i18n.language!==this.state.language)) {
            this.setState({ ...this.state, language: prevProps.i18n.language });
            getData(this.props,this.state.SelectedEntity)
            .then(records => {

                const options = {
                        title: t('Weight'),
                        vAxis: {
                            title: 'Kg',
                        },
                        hAxis: {
                            type: "category"
                        },
                        series: {
                            0: { color: graphColor.weight },
                            1: { lineDashStyle: [4, 4], pointSize: 0 }
                        },
                        pointSize: 5,
                        legend: { position: 'top', alignment: 'end' },
                        colors: [graphColor.weight, graphColor.weight],
                        interpolateNulls: true
                    };

                    records = getSeries(records);
                    const avgWeight = graphXYValues(records, 2);
                    if (avgWeight.includes(0)) {
                        records = getAverageWeight(records);
                        records.unshift(["X", t('Weight'), t("Average weight")]);
                    } else {
                        records.unshift(["X", t('Weight'), t("Reference Weight")]);
                    }
                    this.setState({ ...this.state, data: records, options });
                })
                .catch(err => {

                    const records = [
                        [{
                            f: 'Date',
                            type: 'date'
                        }, {
                            f: 'Line',
                            type: 'number'
                        },]
                    ];
                    const options = {
                        title: t('Weight (No data available)'),
                        series: {
                            0: {
                                color: 'transparent'
                            }
                        }
                    };
                    this.setState({ ...this.state, data: records, options });
                    // do nothing
                })
        }

    }



    render() {
        const {t}=this.props;
        if (!this.state.data.length) return <div className="loader" />;
        return (
            this.state.SelectedEntity === configData.clinical_study.HEPADOM_CLINICAL_STUDY ? ( <Chart
                width={'1280px'}
                chartType="LineChart"
                loader={<div>{t("Weight")}</div>}
                data={this.state.data}
                options={this.state.options}
                rootProps={{ 'data-testid': '3' }}
            />) :<Chart
           
            chartType="LineChart"
            loader={<div>{t("Weight")}</div>}
            data={this.state.data}
            options={this.state.options}
            rootProps={{ 'data-testid': '3' }}
        />
        )
    }

}


export default (withTranslation('common')(Weight));
