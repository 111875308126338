import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { Entity } from 'utils/services/entity';
import {
    Divider,
    Button
} from '@material-ui/core';
import Form from 'components/Form';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "components/SnackbarContentWrapper";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThumbUpAltOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {refreshPage} from '../../../../../utils/common'

var tempanswers = [] ;
var count=0;
var quecount=0;
class SUTAQ extends React.Component {
    state = { answers: [], variant: "success", disabled: false };
    onChange(options) {
        const { data } = options;
        let index=data.name.split("^")[1];
        data.name=data.name.replace("^"+index,"");
        const answers = JSON.parse(JSON.stringify(this.state.answers));
        if(!tempanswers[index]&&parseInt(index)<=(parseInt(quecount)+1))
        count=count+1;
        if(parseInt(index)<=(parseInt(quecount)+1))
        tempanswers[index]= data.name+":"+data.value;
        
       if(!(count<quecount))
       {
           count=0;
           quecount=0;
        for (let i = 0; i < tempanswers.length; i++) 
        answers.push({ _id: tempanswers[i].split(":")[0], value: tempanswers[i].split(":")[1] });
        this.setState({ ...this.state, answers });
       }
    }

    save() {
        const { id } = this.props;
        this.setState({ ...this.state, loading: true });
        const options = {
            entity: "questionnaire",
            entity_id: id,
            data: {
                answers: this.state.answers
            }
        };

        Entity.edit(options)
            .then((response) => {
                this.setState({ ...this.state, open: true, variant: 'success', message: response.message, disabled: true, loading: false });
                refreshPage()
            })
            .catch((err) => {
                this.setState({ ...this.state, open: true, variant: 'error', message: err.error, loading: false });
            })
    }

    handleClose = () => {

        this.setState({ ...this.state, open: false });
 
    };

    render() {
        const { questionnaires, classes, activated } = this.props;
        if(count>=quecount)
        {
        count=0;
        tempanswers=[];
        }
        quecount=0;
        let form = [];
        questionnaires.forEach((questionnaire, index) => {
            if (index === 0) return;
            let f = {
                addDivider: true,
                onChange: this.onChange.bind(this),
                fields: []
            };
            questionnaire.questionnaires.forEach((questionnaire) => {
                f.fields.push({
                    name: questionnaire._id+"^"+quecount,
                    label: <div dangerouslySetInnerHTML={{ __html: questionnaire.value }} />,
                    type: "radio",
                    disabled: (this.state.disabled || activated === false),
                    options: questionnaire.options.map((value) => {
                        return { key: value, value }
                    })
                });
                quecount++;
            });
            form.push(f);
        });
        if (activated === false) return (<Grid container spacing={3}>
            <Grid item xs>
                <ThumbUpAltOutlined variant="subtitle2" color="primary" /><Typography variant="subtitle2" color="primary">Déjà répondu</Typography>
            </Grid>
        </Grid>);
        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs style={{ textAlign: "left" }}>
                        <h4>{questionnaires[0]}</h4>
                    </Grid>
                </Grid>
                <br />
                {questionnaires.map((questionnaire, index) => {
                    if (index === 0) return <React.Fragment key={index} />;
                    return <React.Fragment key={index}>
                        <div className={classes.description} dangerouslySetInnerHTML={{ __html: questionnaire.description }} />
                        <Form className={classes.formControl} options={form[index - 1]} />
                        <Divider className={classes.divider} />
                    </React.Fragment>
                })}

                <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    style={{ position: 'absolute', right: '100px' }}
                    key={`top,left`}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                >
                    <SnackbarContentWrapper onClose={this.handleClose}
                        variant={this.state.variant}
                        message={this.state.message}
                    />
                </Snackbar>
                <Grid item xs style={{ textAlign: "right" }}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.save.bind(this)} disabled={(this.state.disabled || activated === false)}>
                        {this.state.loading ? <CircularProgress
                            size={40}
                            className={classes.buttonProgress} /> : "Enregistrer"}
                    </Button>
                </Grid>
                <br />
            </div>
        )
    }
}

const useStyles = (theme) => ({
    root: {
        position: 'relative'
    },
    description: {
        textAlign: "left",
    },
    formControl: {
        textAlign: "left"
    },
    button: {
        margin: theme.spacing(1),
    },
    divider: {
        marginBottom: "3%"
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default withStyles(useStyles)(SUTAQ);
