import React from "react";
import Form from "components/Form";
import {Grid, Box} from "@material-ui/core";
import {Entity} from "utils/services/entity";
import { withTranslation } from 'react-i18next';
let timeout;

const getThresholdSettings = (t) => ({
    tempHighThreshold: {
        name: `${t('High Skin Temperature')} (°C)`,
        min: 0,
        max: 5,
        step: .1
    },
    tempLowThreshold: {
        name: `${t('Low Skin Temperature')} (°C)`,
        min: 0,
        max: 5,
        step: .1
    },
    weight_variation: {
        name: `${t('Weight Variation')} (%)`,
        min: 0,
        max: 20,
        step: 1
    },
    mdasi_alert: {
        name: 'MDASI',
        min: 0,
        max: 10,
        step: 1
    },
    IO: {
        name: `${t('IO Alert')} (%)`,
        min: 80,
        max: 100,
        step: 1
    },
});

class AlertThreshold extends React.Component {
    state = {
        error: ""
    };

    getTemperatureThresholdPromise = (inclusion_id) => {
        const options = {
            entity: 'patients',
            data: {inclusion_id}
        };
        const lowTemperaturePromise = Entity.call('threshold_low_temperature', options);
        const highTemperaturePromise = Entity.call('threshold_high_temperature', options);
        return [lowTemperaturePromise, highTemperaturePromise];
    }

    getThresholdPromise = (patient_id) => {
        const options = {
            entity: 'alert_threshold',
            query: {
                filters: [
                    { field: 'patient_id', value: patient_id, operator: 'equal' }
                ],
                fields: { name: 1, value: 1 }
            }
        };
        return Entity.list(options);
    }

    getThreshold(patient_id, inclusion_id) {
        return new Promise((resolve, reject)=> {
            const thresholdPromise = this.getThresholdPromise(patient_id);
            const temperatureThresholdPromise = this.getTemperatureThresholdPromise(inclusion_id);
            Promise.all([thresholdPromise, ...temperatureThresholdPromise])
                .then((response)=> {
                    const records = response[0].data.records;
                    const tempLowThreshold = response[1].data.tempLowThreshold;
                    const tempHighThreshold = response[2].data.tempHighThreshold;
                    records.push({_id: 'tempLowThreshold', name: 'tempLowThreshold', value: tempLowThreshold})
                    records.push({_id: 'tempHighThreshold', name: 'tempHighThreshold', value: tempHighThreshold})
                    resolve(records);
                })
                .catch((err)=> {
                    reject(err);
                })
        })
    }

    onChange(options) {
        const { data } = options;
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(()=> {
            const InclusionId = this.props.patient_details.inclusion_id;
            let promise = null;
            if (data.name === 'tempLowThreshold' || data.name === 'tempHighThreshold') {
                const options = {
                    entity: 'patients',
                    data: {
                        InclusionId,
                        [data.name]: data.value
                    }
                };
                const urlHandle = data.name === 'tempLowThreshold' ? 'threshold_low_temperature_update' : 'threshold_high_temperature_update';
                promise = Entity.call(urlHandle, options);
            } else {
                const options = {
                    entity: 'alert_threshold',
                    entity_id: data.name,
                    data: {value: data.value}
                };
                promise = Entity.edit(options)
            }
            promise
                .then(()=> {}) // do nothing
                .catch((err)=> {
                    this.setState({ ...this.state, error: err.error });
                })
        }, 500)
    }

    constructor(props) {
        super(props);
        this.thresholdSettings = getThresholdSettings(props.t);
        this.getThreshold(props.patient_details._id, props.patient_details.inclusion_id)
            .then((records)=> {
                const threshold = {
                    fields: [],
                    onChange: this.onChange.bind(this),
                };
                records.forEach((record)=> {
                    const settings = this.thresholdSettings[record.name];
                    if (!settings) {
                        return;
                    }
                    const min = settings.min;
                    const max = settings.max;
                    const step = settings.step;
                    record.name = settings.name;
                    
                    threshold.fields.push({
                        name: record._id,
                        label:record.name,
                        value: String(record.value),
                        required: true,
                        type: "slider",
                        display_type:"on",
                        min: min,
                        max: max,
                        step: step,
                        marks: true
                    })
                });
                this.setState({ ...this.state, formThreshold: threshold });
            })
            .catch((err)=> {
                this.setState({ ...this.state, error: err.error });
            });
    }

    render() {
        const { t } = this.props;
        if (!this.state.formThreshold)
            return (
                <div className="loader"/>
            );
        if (this.state.error.length) {
            return (
                <Box bgcolor="error.main" color="error.contrastText" p={2} m={1}>
                    {this.state.error}
                </Box>
            )
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs>
                    <Form options={this.state.formThreshold} title={t('Alert Threshold')}/>
                </Grid>
            </Grid>
        )
    }
}

export default withTranslation('common')(AlertThreshold);
