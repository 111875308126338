import React from "react";
import Dashboard from "components/Dashboard";
import Table from "../../../components/Table/index.web";
import { withTranslation } from 'react-i18next';
class Doctors extends React.Component {

    render() {
        const {t } = this.props;
        return (
            <Dashboard title= {t('Doctor')} disablePaper={ true }>
                <Table entity="doctors"
                    fields={[
                        { key: "first_name", value: "First Name"},
                        { key: "last_name", value: "Last Name"},
                        { key: "user_name", value: "User Name"},
                        { key: "specialization", value: "Specialization"}
                    ]}
                    addShow={true}
                    //actions={["delete"]}
                    addLink={"/doctors/_add"}
                />
            </Dashboard>
        )
    }
}

export default withTranslation('common')(Doctors);
